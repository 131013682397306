import React from "react";
import AssignedCases from "../assignedCases/AssignedCases";
import ScheduledCases from "../scheduledCases/ScheduledCases";
import CompletedCases from "../completedCases/CompletedCases";
import { ReactComponent as SortIcon } from "../../../../../assets/icons/sort-icon.svg";
import { ReactComponent as FilterIcon } from "../../../../../assets/icons/filter-icon.svg";
import { ReactComponent as SearchWhiteIcon } from "../../../../../assets/icons/search-white.svg";
import ClearIcon from "@material-ui/icons/Clear";
import ToggleButton from "../../../../../components/toggleButton/ToggleButton";
import SearchBar from "../searchBar/SearchBar";
import { connect } from "react-redux";

export const categoryColor = {
     "New Assignation" : "#11C41726",
     "Assigned for Rework" : "#E6B01A26",
     "Transferred Case" : "#2FA5DF26",
     "Reschedule Later" : "#2FA5DF26",
     "Pending Completion" : "#E6B01A26",
     "Transferred - Rescheduled by Customer" : "#11C41726",
     "Rescheduled By Customer" : "#11C41726",
     "Transferred - Auto-Scheduled Live Case" : "#11C41726",
     "Pending_Audit" : "#2FA5DF26",
     "Completed_Audit" : "#11C41726",
     "Expired Case" : "#C4112F26",
}

class CasesContainer extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      showSearchBar: false,
    };
  }

  handleSearchBar = () => {
    const { showSearchBar } = this.state;
    this.setState({ showSearchBar: !showSearchBar });
  };

  getSearchObject = (searchObject) => {
    this.props.handleSearchKeyChanged(searchObject);
    this.setState({ showSearchBar: false });
  };

  render() {
    const {
      caseDataResponseArray,
      assignedCases,
      scheduledCases,
      completedCases,
      activeTab,
      activeUpperTab,
      userInfo,
      showFilter,
      documentChoices,
      processChoices,
      handleFilter,
      hideAgentTabs,
      applyFilter,
      updateCaseData,
      clientInfo,
    } = this.props;
    const { showSearchBar } = this.state;
    const currentActiveTab =
      userInfo && userInfo.isAuditor && !userInfo.isAgent ? 2 : activeTab;
    return (
      <div className="container-fluid" style={{ padding: 0 }}>
        <div className="row">
          <div className="col-md-12 d-flex justify-content-between">
            <ul
              className="nav nav-tabs nav-tabs-plain border-bottom brdr-db"
              id="myTabNested"
              role="tablist"
            >
              {userInfo && userInfo.isAgent && !hideAgentTabs && (
                <li
                  className="nav-item"
                  onClick={() => this.props.changeActiveTab(0)}
                >
                  <span
                    className={
                      currentActiveTab === 0
                        ? "nav-link active text-uppercase ltr-spc-1"
                        : "nav-link text-uppercase ltr-spc-1"
                    }
                    id="assigned-tab"
                    href="#assigned"
                    // data-toggle="tab"
                  >
                    Assigned
                  </span>
                </li>
              )}
              {userInfo && userInfo.isAgent && !hideAgentTabs && (
                <li
                  className="nav-item"
                  onClick={() => this.props.changeActiveTab(1)}
                >
                  <span
                    className={
                      currentActiveTab === 1
                        ? "nav-link active text-uppercase ltr-spc-1"
                        : "nav-link text-uppercase ltr-spc-1"
                    }
                    id="scheduled-tab"
                    href="#scheduled"
                    // data-toggle="tab"
                  >
                    Scheduled
                  </span>
                </li>
              )}
              <li
                className="nav-item"
                onClick={() => this.props.changeActiveTab(2)}
              >
                <span
                  className={
                    currentActiveTab === 2
                      ? "nav-link active text-uppercase ltr-spc-1"
                      : "nav-link text-uppercase ltr-spc-1"
                  }
                  id="completed-tab"
                  href="#completed"
                  // data-toggle="tab"
                >
                  Completed
                </span>
              </li>
            </ul>
            {userInfo.isAgent && !userInfo.isAuditor && clientInfo.live_case && (
              <div className="d-flex align-items-center">
                <ToggleButton
                  onToggleChange={(value) =>
                    this.props.handleLiveCaseToggleChange(value)
                  }
                  isToggleOn={this.props.isLiveCaseToggleOn}
                />
                <span className="ms-2 text-info">Live Cases</span>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-end gray-circle-btns d-flex justify-content-end">
            <div className="d-flex justify-content-center mr-30 align-items-center">
              <SearchWhiteIcon />
              {/* <input
                type="text"
                className="form-control search-light-blue "
                placeholder="Search by Customer Name"
                style={{ width: "330px" }}
                onChange={(e) => this.props.handleSearchKeyChanged(e)}
                value={this.props.caseNameSearchKey}
              /> */}
              <div style={{ marginLeft: "-30px" }}>
                {Object.keys(this.props.searchKeyObject).length !== 0 && (
                  <span>
                    Clear search results
                    <ClearIcon
                      onClick={() => {
                        console.log("clicked");
                        this.props.handleSearchKeyChanged({});
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </span>
                )}
              </div>
            </div>
            <span>
              <SortIcon onClick={this.props.handleSort} />
            </span>
            {/* <span class="position-relative"> 
              <span class="position-relative filter-box-cntr">
                <FilterIcon onClick={this.props.handleShowFilter} />
              </span>
              <div
                class="schedular-popup-box filter-box rounded p-3"
                style={{ display: showFilter ? "" : "none" }}
                id="filter-box"
              >
                <div class="row">
                  <div class="col-md-12 text-start">
                    <h6 class="txt-light-blue2 ltr-spc-1">Filter</h6>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <hr class="brdr-ec mt-1 mb-1 dashed-brdr" />
                  </div>
                </div>
                <div class="row mt-2 text-start">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label
                        for="exampleFormControlSelect1"
                        class="txt-light-blue2 ltr-spc-1 fnt-sml"
                      >
                        Select Process
                      </label>
                      <select
                        onChange={(e) => handleFilter("processFilter", e)}
                        class="form-control form-control-sm fnt-sml"
                        id="exampleFormControlSelect1"
                      >
                        <option value={""}>Select </option>
                        {processChoices.map((process) => (
                          <option key={process.id} value={process.id}>
                            {process.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label
                        for="exampleFormControlSelect2"
                        class="txt-light-blue2 ltr-spc-1 fnt-sml"
                      >
                        Select Document List
                      </label>
                      <select
                        onChange={(e) => handleFilter("documentFilter", e)}
                        class="form-control form-control-sm fnt-sml"
                        id="exampleFormControlSelect2"
                      >
                        <option value={""}>Select </option>
                        {Object.keys(documentChoices).map((keys) => (
                          <option key={keys} value={keys}>
                            {documentChoices[keys]}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <hr class="brdr-ec mt-1 mb-1 dashed-brdr" />
                  </div>
                </div>
                <div class="row fnt-sml mt-3">
                  <div class="col-md-12 text-end">
                    <button
                      class="btn btn-primary btn-sm fnt-sml me-2"
                      onClick={() => applyFilter(true)}
                      style={{
                        marginTop: "-6px",
                        backgroundColor: "#27ABEC",
                        borderColor: "transparent",
                      }}
                    >
                      Apply
                    </button>
                    <button
                      onClick={() => applyFilter(false)}
                      class="btn btn-outline-primary btn-sm fnt-sml"
                      style={{ marginTop: "-6px" }}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </span> */}
            <span className="bg-blue global-search-btn">
              <SearchWhiteIcon onClick={this.handleSearchBar} />
            </span>
          </div>
        </div>
        {showSearchBar ? (
          <SearchBar
            getSearchObject={this.getSearchObject}
            currentActiveTab={currentActiveTab}
            isAuditor={userInfo.isAuditor}
          />
        ) : (
          <div className="tab-content mt-1">
            {currentActiveTab === 0 &&
              userInfo &&
              userInfo.isAgent &&
              !hideAgentTabs && (
                <AssignedCases
                  activeUpperTab={activeUpperTab}
                  assignedCases={assignedCases}
                  categoryColor={categoryColor}
                  handleViewDocuments={this.props.handleViewDocuments}
                  handleSchedulerOpen={this.props.handleSchedulerOpen}
                  onScheduleComplete={this.props.onScheduleComplete}
                  userInfo={userInfo}
                  updateCaseData={updateCaseData}
                  setSchedulerLoader={this.props.setSchedulerLoader}
                  searchKeyObject={this.props.searchKeyObject}
                />
              )}
            {currentActiveTab === 1 &&
              userInfo &&
              userInfo.isAgent &&
              !hideAgentTabs && (
                <ScheduledCases
                  scheduledCases={scheduledCases}
                  activeUpperTab={activeUpperTab}
                  categoryColor={categoryColor}
                  isLiveCaseToggleOn={this.props.isLiveCaseToggleOn}
                  createAndStartSession={this.props.createAndStartSession}
                  onScheduleComplete={this.props.onScheduleComplete}
                  updateCaseData={updateCaseData}
                  fetchUpdatedCases={this.props.fetchUpdatedCases}
                  setSchedulerLoader={this.props.setSchedulerLoader}
                  searchKeyObject={this.props.searchKeyObject}
                />
              )}
            {currentActiveTab === 2 && (
              <CompletedCases
                completedCases={completedCases}
                handleViewDocuments={this.props.handleViewDocuments}
                activeUpperTab={activeUpperTab}
                categoryColor={categoryColor}
                userInfo={userInfo}
                handleWatchVideo={this.props.handleWatchVideo}
                fetchUpdatedCases={this.props.fetchUpdatedCases}
                searchKeyObject={this.props.searchKeyObject}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  clientInfo:state.login.clientInfo
});

export default connect(mapStateToProps)(CasesContainer);
