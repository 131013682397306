import React, { Component } from "react";
import { connect } from "react-redux";
import { login, update, logout, getClientConfig } from "./state/login.actions";
import { LOGIN, FORGOT_PASSWORD } from "../apiurl";
import keyIcon from "../assets/icons/key.svg";
import instance from "../apiBaseConfig";
import "./Login.scss";
import { Toast } from "../utils/toastutils";
import api from "../apiBaseConfig";
import LoginHome from "./loginHome/loginHome";
import cogoToast from "cogo-toast";
import Loader from "../components/loader/Loader";

const USER_TOKEN = "1f695496-2403-11eb-9a4f-5ce0c559d689";
// @Todo convert to Functional component
class Login extends Component {
  state = {
    isAuthenticated: false,
    forgotPassword: false,
    usermail: "",
    touched: { username: false, password: false, usermail: false },
    loading: false,
    invalidEmail: false,
    emailSent: false,
    otpSent:false
  };
  componentDidMount = () => {
    this.props.logout();
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.userInfo && this.props.userInfo.userId) {
      console.log("userInfo", this.props.userInfo);
      if(this.props.clientInfo.client) {
        if (!this.props.userInfo.hasUserChanagedPasswordBefore) {
          this.props.history.push("/changePassword");
          return;
        }
        if (this.props.userInfo.isClientSuperuser) {
          this.props.history.push("/admin/dashboard");
          return;
        }
        if (this.props.userInfo.isAgent || this.props.userInfo.isAuditor || this.props.userInfo.isAdminAuditor ) {
          this.props.history.push("/dashboard");
          return;
        }
      }
    }
  };
  handleOtpSubmit = async (e,otp,username) =>{
    e.preventDefault()
    try{
      const response = await instance.post(`/user/mf-authentication/`, {
        email: username,
        otp: otp
      });
      if(response && response.status === 200)
        this.handleLogin(response)
    }
    catch(e){
      cogoToast.error('Something went wrong while validating otp!')
    }
  }
  handleSubmit = async (e,username,password) => {
    e.preventDefault()
    if (username && password) {
      this.setState({ loading: true });
      try {
        const response = await instance.post(`${LOGIN}`, {
          username: username,
          password: password,
        });
        if(response.status === 201){
          cogoToast.success(response.data.detail)
          this.setState({otpSent:true,loading:false})
        }
        else this.handleLogin(response)
       
      } catch (e) {
        if (e.non_field_errors) {
          Toast.error({
            description: e.non_field_errors[0],
            title: "Error",
          });
        }
        this.setState({ loading: false });
      }
    }
  };
  handleLogin =(response)=>{
    this.props.update({ token: response.data.token });
    if(response.data.client_id)
      this.props.getClientConfig(response.data.client_id)
    this.props.login();
  }
  sendEmailForForgotPassword = async () => {
    this.setState({ loading: true });
    const { usermail } = this.state;
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (pattern.test(usermail)) {
      try {
        await api.post(FORGOT_PASSWORD, {
          email: usermail,
        });
        this.setState({ invalidEmail: false, emailSent: true, loading: false });
        this.props.history.push({ pathname: "/login/reset", state: usermail });
      } catch (e) {
        this.setState({ invalidEmail: false, loading: false });
      }
    } else {
      this.setState({ invalidEmail: true, loading: false });
    }
  };
  render() {
    return (
      <React.Fragment>
       {this.state.loading ? <Loader title="Loading please wait.." /> : null}
          {this.state.forgotPassword ? (
            <section className="main-content pr-0">
              <div className="container-fluid p-0">
                <div className="row g-0">
                  <div className="col-md-12">
                    <div className="ml-30 mr-30 mt-4">
                      <div className="container-fluid" style={{ padding: 0 }}>
                        <div className="d-flex justify-content-center mb-3 row">
                          <div className="col-md-4">
                            <div className="change-password-form">
                              <div className="form-group">
                                <label>Email</label>
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text bg-white">
                                      <img src={keyIcon} alt="Key" />
                                    </span>
                                  </div>
                                  <input
                                    onChange={(event) =>
                                      this.setState({
                                        usermail: event.target.value,
                                      })
                                    }
                                    required={true}
                                    onBlur={() =>
                                      this.setState({
                                        touched: {
                                          ...this.state.touched,
                                          usermail: true,
                                        },
                                      })
                                    }
                                    type="text"
                                    className="form-control"
                                  />
                                </div>
                                <small className="txt-gray roboto-italic">
                                  Please type the email you want to receive an
                                  OTP on
                                  {this.state.invalidEmail && (
                                    <p style={{ color: "red" }}>
                                      Please enter a valid Email address.
                                    </p>
                                  )}
                                  {this.state.emailSent && (
                                    <p style={{ color: "green" }}>
                                      Email sent to the provided Email address.
                                    </p>
                                  )}
                                </small>
                              </div>
                              <div className="justify-content-between d-flex">
                                <button
                                  disabled={this.state.loading}
                                  onClick={() => this.setState({loading: false , usermail: null, forgotPassword: false})}
                                  className="btn btn-sm btn-dark w-25"
                                >
                                  Back
                                </button>

                                <button
                                  disabled={!this.state.usermail}
                                  onClick={this.sendEmailForForgotPassword}
                                  className="btn btn-block btn-sm primary-btn w-50"
                                >
                                  {this.state.loading && (
                                    <span className="spinner-border spinner-border-sm me-1"></span>
                                  )}
                                  Send
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
              <LoginHome 
                forgotPassword = {(e) => this.setState({ forgotPassword: true })}
                otpSent = {this.state.otpSent}
                submitOtp ={this.handleOtpSubmit}
                handleSubmit = {this.handleSubmit}
                goBack={e=>this.setState({otpSent:false})}
              />
           )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.login.userInfo,
  clientInfo:state.login.clientInfo
});

const mapDispatchToProps = (dispatch) => ({
  login: (payload) => dispatch(login()),
  update: (payload) => dispatch(update(payload)),
  logout: () => dispatch(logout()),
  getClientConfig: (payload) =>dispatch(getClientConfig(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);