import React, { useEffect, useState } from "react";
import api from "../../apiBaseConfig";
import { FACEMATCH } from "../../apiurl";
import _get from "lodash/get";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import { CASE_HISTORY, UPLOAD } from "../../apiurl";
import { useSelector } from "react-redux";
import { FaceMatchTypes } from '../../constants'
import cogoToast from 'cogo-toast'

function PhotoLiveness(props) {
  const [score, setScore] = useState(null);
  const [allScores, setAllScores] = useState(null)
  const [reTry, setReTry] = useState(null);
  const [reTryCount, setReTryCount] = useState(1);
  const [screenShot, setScreenShot] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [enlarge, setEnlarge] = useState(false);
  let clientConfig = useSelector(state => state.login.clientInfo)
  const { facematch_score_type, store_facematch_picture, selfie_match_required } = clientConfig

  useEffect(() => {
    setScore(props.score);
    setScreenShot(props.screenShot);
    setReTryCount(props.reTryCount);
    !props.score && setReTry(true);
  }, [props]);

  useEffect(() => {
    setScore(props.score);
    setScreenShot(props.screenShot);
    setReTryCount(props.reTryCount);
    props.score && setReTry(props.score < clientConfig.facematch_threshold);
  }, []);

  const getLiveness = async screenShot => {
    if ((screenShot && props.doc_id) || (screenShot && props.isCPV && props.selfieDocId) || (screenShot && selfie_match_required && props.selfieDocId)) {
      let params = {
        image_data: screenShot
      }
      if (selfie_match_required) {
        params.document_ids = [props.selfieDocId]
      }
      else if (screenShot && props.doc_id) {
        params.document_ids = props.doc_id
      }

      if (store_facematch_picture) {
        let res = await uploadFaceImage(screenShot)
        if (res) {
          params = {
            document_ids: props.doc_id,
            facematch_picture: res
          }
        }
      }
      let score = null
      let scoreData = []
      setErrorMsg(null)
      api.post(FACEMATCH, params)
        .then(response => {
          if (response) {
            score = response.data.maximum_score || 0
            if (response.data) {
              setAllScores(response.data)
              facematch_score_type.map(item => {
                scoreData.push(FaceMatchTypes[item])
              })

            }
            if (score === 0) {
              score = null;
              setErrorMsg("Face could not match")
            }
            setScore(score);

            setReTryCount(reTryCount + 1);
            props.updateRetry({
              score: score,
              screenShot: screenShot,
              reTryCount: reTryCount + 1,
              checked: null
            });

            submitComment(`Photo Liveness Details - Liveness Score - ${score}`);
            handleRetry(scoreData, response.data)

          }
        })
        .catch(err => {
          if (reTryCount < 4)
            cogoToast.error('Face is not getting matched, Please try again')
          else cogoToast.error('Face Match is not matched')
          setReTryCount(reTryCount + 1);
          props.updateRetry({
            score: score,
            screenShot: screenShot,
            reTryCount: reTryCount + 1,
            checked: null
          });

        });


    }
  };
  const uploadFaceImage = async (screenShot) => {
    if (screenShot && props.caseId) {
      let docId = null
      let docItem = props.processDocs.filter(item => item.doc_key === 'facematch_picture')
      if (docItem && docItem.length > 0) {
        docId = docItem[0].id
      }
      let response = await api.put(UPLOAD, {
        connection: props.connectionId,
        case: props.caseId,
        front_upload: screenShot,
        document: docId, //payload.document,
        corrected_ocr: {},
        extracted_ocr: {},
        document_upload_event: "on_call"
      });
      if (response && response.status === 200) {
        return response.data.id
      }
      else {
        return null
      }
    }
  };
  function handleRetry(scoreData, data) {
    let flag = false
    scoreData.map(item => {
      if (data[item] < clientConfig.facematch_threshold) flag = true
    })
    setReTry(flag)
    // score ? setReTry(score < clientConfig.facematch_threshold) : setReTry(true);
  }

  const takeScreenShot = () => {
    let screenShot = props.takeScreenShot();
    setScreenShot(screenShot);
    getLiveness(screenShot);
    setReTry(false);
  };

  const submitComment = async comment => {
    const caseId = props.caseId;
    const userId = props.userId;
    const body = {
      case: caseId,
      user: userId,
      type: "Action",
      re_text: comment
    };
    try {
      const res = await api.post(CASE_HISTORY, body);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="border rounded p-4 bg-f9 mt-3">
      <h6 className="txt-light-blue dashed-title mb-3 ltr-spc-1">
        Photo Match Result
      </h6>
      <div className="mt-3 border dashed-brdr rounded p-3 bg-white">
        {screenShot ? (
          <div className="d-flex align-items-center">
            <img
              src={screenShot}
              className="rounded-circle border me-1"
              onMouseEnter={() => setEnlarge(true)}
              onMouseLeave={() => setEnlarge(false)}
              width="50"
              height="50"
              alt=""
            />
            {
              enlarge &&
              <img
                src={screenShot}
                className="rounded-circle border enlarge"
                alt=""
              />
            }
            <div className="flex-wrapper">
              <div className="single-chart w-auto">
                {score && score >= clientConfig.facematch_threshold ? (
                  <Box position="relative" display="inline-flex">
                    <CircularProgress
                      variant="static"
                      value={score}
                    />
                    <Box
                      top={0}
                      left={0}
                      bottom={0}
                      right={0}
                      position="absolute"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <span className="percentage">{score}%</span>
                    </Box>
                  </Box>
                ) :
                  <React.Fragment>
                    {score &&
                      <Box position="relative" display="inline-flex">
                        <CircularProgress
                          variant="static"
                          value={score}
                        />
                        <Box
                          top={0}
                          left={0}
                          bottom={0}
                          right={0}
                          position="absolute"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <span className="percentage">{score}%</span>
                        </Box>
                      </Box>}
                    {reTry ? (

                      reTryCount < 5 &&
                      <button
                        className="btn ms-5 btn-custom btn-primary"
                        onClick={() => {
                          takeScreenShot();
                        }}
                      >
                        Retry
                      </button>

                    ) : (
                      <CircularProgress />
                    )}
                    {errorMsg && <p className="fnt-8rem mismatchError m-auto ps-2">{errorMsg} </p>}
                  </React.Fragment>
                }
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex align-items-center">
            <button
              className="btn btn-custom btn-primary"
              onClick={takeScreenShot}
            >
              Take Screenshot
            </button>
          </div>
        )}
      </div>
      <div className="row mt-2">
        <div className="col-8">

          {reTryCount == 4 && !(score && score >= clientConfig.facematch_threshold) && <p className="fnt-8rem mismatchError">Disclaimer-You have one attempt remaining for face match verification</p>}
        </div>

        {allScores && <div className="col-8">
          {Object.keys(allScores).map(key => {
            return <p className="txt-70 fnt-sml"><span style={{ textTransform: 'uppercase' }}>{key.replaceAll('_', ' ')}</span> : <span>{allScores[key]}</span></p>
          })}
        </div>
        }
      </div>
    </div>
  );
}

export default PhotoLiveness;
