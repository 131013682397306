import React from "react";
import SearchIcon from "../../../../../assets/icons/search-light-blue-icon.png";
import SortIcon from "../../../../../assets/icons/sort-gray-icon.png";
import FilterIcon from "../../../../../assets/icons/filter-dark-blue-icon.png";
import ClearIcon from "@material-ui/icons/Clear";

class SearchAndSort extends React.Component {
  render() {
    const { searchKeyObject } = this.props;
    return (
      <div className="row">
        <div className="col-md-12 text-end gray-circle-btns no-span search-sec">
          {Object.keys(searchKeyObject).length !== 0 && (
            <span>
              Clear search results
              <ClearIcon
                onClick={() => {
                  this.props.getSearchObject({});
                }}
                style={{ cursor: "pointer" }}
              />
            </span>
          )}
          <span onClick={this.props.handleSort}>
            <img
              src={SortIcon}
              alt=""
              style={{ height: "auto" }}
              data-toggle="tooltip"
              data-placement="top"
              title="SORT BY"
            />
          </span>
          {/* <span className="bg-blue">
            <img
              src={FilterIcon}
              alt=""
              style={{ height: "auto" }}
              data-toggle="tooltip"
              data-placement="top"
              title="FILTER"
            />
          </span> */}
          <span onClick={this.props.toggleSearchClick}>
            <img
              src={SearchIcon}
              alt=""
              style={{ height: "auto" }}
              data-toggle="tooltip"
              data-placement="top"
              title="SEARCH"
            />
          </span>
        </div>
      </div>
    );
  }
}

export default SearchAndSort;
