import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Toast } from "../../../utils/toastutils";
import "date-fns";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useHistory } from "react-router";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { RESCHEDULE, RESCHEDULE_NORMAL } from "../../../apiurl";
import api, { instance2 } from "../../../apiBaseConfig";
import Loader from "../../../components/loader/Loader";
// import Loader from "../../../../../components/loader/Loader";
import _get from "lodash/get";
import { ReactComponent as VideoIcon } from "../../../assets/icons/video-icon.svg";

const materialTheme = createMuiTheme({
  overrides: {
    MuiFormLabel: {
      root: {
        color: "white",
      },
    },
    MuiInputBase: {
      root: {
        color: "white",
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: "white",
      },
    },
    MuiIconButton: {
      root: {
        color: "white",
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#27abec",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#27abec",
      },
    },
  },
});

function RescheduleTime(props) {
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [dateSelected, setDateSelected] = useState(false);
  const [timeSelected, setTimeSelected] = useState(false);
  const [hasError, setHasError] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const setDateString = (e, type) => {
    let selectedDate = moment(e);
    if (type === "date") {
      setDateSelected(true);
      setDate(moment(e).format());
    } else {
      setTimeSelected(true);
      setTime(moment(e).format());
    }
  };

  useEffect(() => {
    if (dateSelected && timeSelected) {
      let hasErrorVal =
        moment(new Date(`${date.split("T")[0]}T${time.split("T")[1]}`)).diff(
          new Date()
        ) < 0;
      setHasError(hasErrorVal);
    }
  }, [date, time]);

  const handleSchedule = async () => {
    try {
      const dateTime = `${date.split("T")[0]}T${time.split("T")[1]}`;
      setLoading(true);
      const token = new URLSearchParams(location.search).get("token");
      const url =
        location.state && location.state.livecase
          ? RESCHEDULE
          : RESCHEDULE_NORMAL;
      if (token) {
        let config = {
          headers: {
            AuthorizationX: `Token ${token}`,
          },
        };

        const { data } = await instance2.post(
          url,
          { schedule_time: dateTime },
          config
        );
      } else {
        const { data } = await api.post(url, { schedule_time: dateTime });
      }
      setLoading(false);
      history.push("/thankyou");
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.response && error.response.data && error.response.data.detail) {
        Toast.error({
          description: error.response.data.detail,
          title: "Error",
        });
      }
    }
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <ThemeProvider theme={materialTheme}>
      <div className="wrapper light-blue-customer-template bg-dark-blue h-100">
        <section className="main-body m-auto">
          <div className="container-fluid position-relative">
            <div className="row">
              <div className="col-md-12">
                <VideoIcon className="mt-4 mb-4" width="50" height="50" fill="#fff"/>
                <h4 className="text-white ltr-spc-1">
                  You choose to Schedule the call for later
                </h4>
                <h6 className="text-white ltr-spc-1 mt-4 mb-4">
                  Please Specify the date & time
                </h6>
              </div>
            </div>
            <div className="row">
              <div className="col-7">
                <div className="position-relative">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disablePast
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      label="Select Date"
                      color="secondary"
                      value={date}
                      onChange={(e) => setDateString(e, "date")}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className="col-5">
                <div className="position-relative">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      clearable
                      ampm={false}
                      inputVariant="outlined"
                      disablePast
                      color="secondary"
                      label="Time"
                      value={time}
                      onChange={(e) => setDateString(e, "time")}
                    />
                  </MuiPickersUtilsProvider>

                  {hasError && (
                    <p
                      style={{ color: "red", float: "right", marginTop: "5px" }}
                    >
                      Please select a valid time.
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 text-end">
                <button
                  disabled={!dateSelected || !timeSelected || hasError}
                  onClick={handleSchedule}
                  className="btn btn-purple fnt-sml"
                  style={{
                    height: "45px",
                    minWidth: "160px",
                    background:
                      !dateSelected || !timeSelected || hasError
                        ? "#a9a9a9"
                        : "#60c1f1",
                    border:
                      !dateSelected || !timeSelected || hasError
                        ? "1px solid #a9a9a9"
                        : "1px solid #60c1f1",
                  }}
                >
                  Schedule
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </ThemeProvider>
  );
}

export default RescheduleTime;
