import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Loader from "../../../../../components/loader/Loader";

class ApprovedRejectedCases extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      options: {},
      selectedVal: "",
      selectedCase: "all",
    };
  }
  componentDidMount() {
    this.updateCasesChart("all", "today");
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.adminData !== this.props.adminData) {
      this.setState({ selectedVal: "today" });
      this.updateCasesChart("all", "today");
    }
  }

  updateCasesChart = (cases, key) => {
    this.setState({ isLoading: true, selectedVal: key, selectedCase: cases });
    const { adminData } = this.props;
    const keyCountDataApproved = adminData[key]["assigned"].count;
    const keyCountDataRejected = adminData[key]["scheduled"].count;
    const seriesData = this.getSeriesData(keyCountDataApproved);
    const seriesDataTwo = this.getSeriesData(keyCountDataRejected);
    const categoriesData = this.getCategoriesData(keyCountDataApproved);
    const finalSeriesData = this.getFinalSeriesData(
      cases,
      seriesData,
      seriesDataTwo
    );
    this.setOptions(finalSeriesData, categoriesData);
  };

  getFinalSeriesData = (cases, assignedData, scheduledData) => {
    let seriesArr = [];
    switch (cases) {
      case "all":
        seriesArr = [
          {
            name: "Assigned Cases",
            data: assignedData,
            color: "green",
          },
          {
            name: "Scheduled Cases",
            data: scheduledData,
            color: "blue",
          },
        ];
        break;
      case "assigned":
        seriesArr = [
          {
            name: "Assigned Cases",
            data: assignedData,
            color: "green",
          }
        ];
        break;
      case "scheduled":
        seriesArr = [
          {
            name: "Scheduled Cases",
            data: scheduledData,
            color: "blue",
          },
        ];
        break;
      default:
        seriesArr = [
          {
            name: "Assigned Cases",
            data: assignedData,
            color: "green",
          },
          {
            name: "Scheduled Cases",
            data: scheduledData,
            color: "blue",
          },
        ];
    }
    return seriesArr;
  };

  getSeriesData = (keyCountData) => {
    const seriesData = [];
    for (var i in keyCountData) {
      seriesData.push(keyCountData[i]);
    }
    return seriesData;
  };

  getCategoriesData = (keyCountData) => {
    const categoriesData = [];
    for (var i in keyCountData) {
      categoriesData.push(i);
    }
    return categoriesData;
  };

  setOptions = (finalSeriesData, categoriesData) => {
    const options = {
      chart: {
        type: "line",
        height: 120,
        width: 500,
        spacingTop: 5,
        spacingBottom: 5,
        spacingLeft: 0,
        spacingRight: 5,
      },
      title: {
        style: { display: "none" },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        line: {
          dashStyle: "ShortDash",
        },
      },
      legend: {
        enabled: false,
      },
      series: finalSeriesData,
      tooltip: {
        formatter: function () {
          console.log(this);
          return `${this.series.name} : ${this.y}`;
        },
      },
      yAxis: {
        title: {
          text: "Cases",
        },
      },
      xAxis: {
        categories: categoriesData,
      },
    };
    this.setState({ options: options, isLoading: false });
  };
  render() {
    const { options, isLoading, selectedVal, selectedCase } = this.state;
    return (
        <div
          className="card ps-3"
        >
          <div
            className="card-header d-flex justify-content-between align-items-center pt-2 pb-2 ps-3"
            style={{ backgroundColor: "#fbfbfb" }}
          >
            <span class="text-uppercase txt-green-dull fnt-sml">Cases</span>
            <select
              name=""
              id=""
              class="form-select form-control-sm fnt-sml"
              style={{ width: "120px" }}
              onChange={(e) => this.updateCasesChart(e.target.value, "today")}
            >
              <option
                value="all"
              >
                All Cases
              </option>
              <option value="assigned">Assigned Cases</option>
              <option value="scheduled">Scheduled Cases</option>
            </select>
            <select
              name=""
              id=""
              class="form-select form-control-sm fnt-sml"
              style={{ width: "120px" }}
              onChange={(e) =>
                this.updateCasesChart(selectedCase, e.target.value)
              }
            >
              <option
                value="today"
                selected={selectedVal === "today" ? true : false}
              >
                Today
              </option>
              <option value="seven_days">Last 7 days</option>
              <option value="thirty_days">Last 30 days</option>
              <option value="ninety_days">Last 90 days</option>
              <option value="greater_than_ninety">
                Greater than last 90 days
              </option>
            </select>
          </div>
          <div
            class="card-body text-center fnt-sml"
            style={{
              height: "160px",
              backgroundColor: "fbfbfb",
            }}
          >
            {isLoading ? (
              <Loader />
            ) : (
              <HighchartsReact highcharts={Highcharts} options={options} />
            )}
          </div>
        </div>
    );
  }
}

export default ApprovedRejectedCases;
