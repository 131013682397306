import React, { useState } from 'react';
import Loader from '../../../components/loader/Loader';
import UserHeader from '../../userHeader/userHeader';

function VoterId(props) {


    const [loading, setLoading] = useState(false)
    return (
        <React.Fragment>
            {loading && <Loader title={"Loading"} />}
            <div className="wrapper light-blue-customer-template">
                <UserHeader />
                <section className="main-body m-auto">
                    <div className="container-fluid mt-4 mb-4 position-relative">
                        <div className="row">
                            <div className="col-md-12">
                                <h4 className="txt-light-blue ltr-spc-1">Voter ID Details</h4>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 aadhaar-no-box-2 mt-3">
                                <div className="form-group">
                                    <label className="fnt-sml txt-light-black ltr-spc-1">EPIC Number</label>
                                    <input type="text" className="form-control brdr-c5-opacity ltr-spc-12 fnt-bld" style={{ 'height': '45px' }} />
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3 mb-3">
                            <div className="col-md-6 col-sm-6 col-6">
                                <button className="btn btn-custom btn-dark btn-light-black w-100-p">Back</button>
                            </div>
                            <div className="col-md-6 col-sm-6 col-6 text-end">
                                <button className="btn btn-custom btn-light-blue w-100-p">Next</button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </React.Fragment>
    );
}

export default VoterId;