import React, { useEffect, useState, useRef } from 'react'
import api, { instance2 } from "../../../apiBaseConfig";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { getClientConfig, updateClientName, updateClientConfig } from "../../../login/state/login.actions";
import _get from "lodash/get";
import SessionDetails from "../sessionDetails/SessionDetails";
import Loader from "../../../components/loader/Loader";
import kycInstruction from '../kycInstruction';
import {
  SAVE_LOCATION,
  IP_SPOOFING,
} from "../../../apiurl";
import { Toast } from "../../../utils/toastutils";
import {
  storeAnonymousUserConnectionInfo,
  resetUserDocs,
  presetUpload,
  isLives,
} from "../../state/user.actions";

import {
  GET_CASE_CONNECTIONS_BLOCK,
} from "../../../apiurl";
import cogoToast from 'cogo-toast';

const STEPS = {
  "video_kyc": [
    {
      name: 'Instruction',
      isActive: true,
      Component: kycInstruction,
      defaultProps: { IsCPV: true }
    }
  ]
}

export default function CPV() {
  let userInfo = useSelector(state => state.userDocs.anonymousUserConnectionInfo)
  const [showLoader, setLoader] = useState(true);
  const [distanceLoader, setDistanceLoader] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [caseType, setCaseType] = useState('video_kyc')
  const [canStartCall, setCanStartCall] = useState(false)
  const [errorMsg, setErrorMsg] = useState({
    title: "Error",
    description: "Please try again!",
  });
  const [callBackUrl, setCallBackUrl] = useState('')
  
  const dispatch = useDispatch()
  const { userToken } = useParams();

  useEffect(() => {
    if (userToken) validateUserToken()
  }, [])
  useEffect(() => {
    if (userInfo.isTokenValid && canStartCall) {
      getCaseDetails()
    }
  }, [userInfo,canStartCall])

 async function getCaseDetails() {
  try {
    let config = {
      headers: {
        AuthorizationX: `Token ${userToken}`,
      },
    };
    const response = await instance2.get(`/cpv/cases/${userToken}/`, config);
    if(response.status === 200) {
      console.log('callback_url', response.data.cpvcase.callback_url)
      setCallBackUrl(response.data.cpvcase.callback_url)
    }
  } catch (error) {
    console.log("error", error);
 }
}
  async function validateUserToken() {
    try {
      let config = {
        headers: {
          AuthorizationX: `Token ${userToken}`,
        },
      };
      const response = await instance2.get(
        `${GET_CASE_CONNECTIONS_BLOCK}${userToken}/`,
        config
      );

      let newUserInfo = response.data;
      dispatch(resetUserDocs());

      newUserInfo.isTokenValid = true;

      newUserInfo.multiUser =
        newUserInfo.case_details.case_type === "video_kyc"
          ? false
          : true;
      setCaseType(newUserInfo.case_details.case_type)
      dispatch(storeAnonymousUserConnectionInfo(newUserInfo));
      getClientDetails(newUserInfo.id)
      dispatch(isLives(true))
    
      setLoader(false);
    } catch (error) {
      console.log("error", error);

      setErrorMsg({
        title: _get(error, "response.data.title", "Error"),
        description: _get(
          error,
          "response.data.description",
          "Please try again."
        ),
      });
      userInfo.isTokenValid = false;
      dispatch(storeAnonymousUserConnectionInfo(userInfo)); //

      setLoader(false);
    }
  };

  async function getClientDetails(user_id) {
    let url = `/cpv/clients/`;
    url = '/client/clientconfig/'
    const response = await instance2.get(
      url,
      {
        headers: {
          AuthorizationX: `Token ${userToken}`,
        },
      }
    );
    let payload = {}
    // payload.name = response.data[0].name
    // let client_configuration = response.data[0].client_configuration

    payload.name = response.data[0].client_details.name
    let client_configuration = response.data[0]
    dispatch(updateClientName(payload))
    dispatch(updateClientConfig(client_configuration))
    setDistanceLoader(true)
    handleLocation(user_id, client_configuration)


  }

  const getDistance = async (id) => {
    setDistanceLoader(true)
    const response = await api.post(`/cpv/location-distance/${id}/`, {});
    setDistanceLoader(false)
    if (response.status === 200) {
      if (!response.data.within_range) {
        cogoToast.error("You are not within the allowed range")
        setCanStartCall(false)
      }
      else setCanStartCall(true)
    }
    else cogoToast.error("Error while getting location distance")

  }

  const tagGeolocation = async (latitude, longitude) => {
    const response = await api.post(SAVE_LOCATION, {
      latitude: latitude,
      longitude: longitude,
    });
  };
  function handleLocation(id, config) {
    const location = {};
    navigator.geolocation.getCurrentPosition(
      async function (position) {
        location["latitude"] = position.coords.latitude;
        location["longitude"] = position.coords.longitude;

        try {
          await tagGeolocation(
            position.coords.latitude,
            position.coords.longitude
          );
          setLoader(false)
          if (config?.calculate_location_distance)
            getDistance(id)
          else {
            setCanStartCall(true)
            setLoader(false)
            setDistanceLoader(false)
          } 
          const res = await api.post(IP_SPOOFING, {});
          if (!res || res.status !== 200) {
            setDistanceLoader(false)
            Toast.error({
              description: "Connection is not secure.",
              title: "Error",
            });
            return;
          }

        } catch (e) {
          setLoader(false)
          console.log("location failed",e);
        }

      },
      (e) => {
        setLoader(false)
        Toast.error({
          description:
            "Please provide your location access to continue further",
          title: "Error",
        });
      },
      { timeout: 10000 }
    );
  };

  return (
    <>
      {showLoader || distanceLoader ? (
        <Loader />
      ) : userInfo.isTokenValid ? <div className="wrapper light-blue-customer-template">
        {(caseType && canStartCall) ? <WithComponent callBackUrl={callBackUrl} Component={STEPS[caseType][activeStep].Component} {...STEPS[caseType][activeStep].defaultProps} handleNext={(e) => { }} /> : null}
      </div> : <SessionDetails errorMsg={errorMsg} />}
    </>
  )
}

const WithComponent = ({ Component, ...props }) => {
  return <Component {...props} />
}
