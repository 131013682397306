import axios from "axios";
import _get from "lodash/get";
import { logout } from "./login/state/login.actions";
import { store } from "./index";
import { Toast } from "./utils/toastutils";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

//@ Todo to check if instance2 is really required
export const instance2 = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    const state = store.getState();
    const accessToken = _get(state, "login.userInfo.accessToken");
    const anonymousUserToken = _get(
      state,
      "userDocs.anonymousUserConnectionInfo.connection",
      null
    );
    const caseType =  _get(
      state,
      "userDocs.anonymousUserConnectionInfo.case_type",
      null
    )
    if (anonymousUserToken) {
      if(caseType && caseType === 'pivc'){
        config.headers.AuthorizationY = `Token ${anonymousUserToken}`;
      }
      else
       config.headers.AuthorizationX = `Token ${anonymousUserToken}`;
    } else if (accessToken) {
      config.headers.Authorization = `Token ${accessToken}`;
    }
    // console.log("api request config", config);
    return config;
  },
  function (error) {
    // console.log("error in api request", error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // console.log("request successful , response ", response);
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response && error.response.status === 401) {
      Toast.error({
        description: "Account logged- in from another device",
        title: "Access Denied",
      });
      store.dispatch(logout());
      return Promise.reject(error);
    }
    // console.log("error in api response ", error.response);
    const url = error.response.config.url;
    const method = error.response.config.method;
    if(url.includes('facematch')){
      return Promise.reject(error);
    }
    let message =
      url.includes("/case/cases/") && (method === "post" || method === "get")
        ? ""
        : "Something went wrong. Please try again.";

    if (error.response && error.response.data && error.response.data.details) {
      message = error.response.data.details;
    }
    else if(error.response && error.response.status === 500){
      message = "Internal server error"
    }
    else if(error.response && error.response.data && !error.response.data.details){
      if(Object.keys(error.response.data).length > 0 ){
        let errorData = error.response.data
        message = errorData[Object.keys(error.response.data)[0]]
      }
      
    }
    if(error.response.status === 400 && url.includes("/case/cases/") && (method === "post") && error.response.data[0].error){
      message = error.response.data[0].error
    }

    if (
      error.response &&
      error.response.status &&
      error.response.status >= 400 &&
      error.response.status <= 499
    ) {
      message.length > 0 &&
        Toast.warning({
          description: message,
          title: "Warning",
        });
    } else {
      message.length > 0 &&
        Toast.warning({
          description: message,
          title: "Error",
        });
    }
  }
);

export default instance;

// headers: {
//   Authorization:
//     "Basic " + btoa("OPENVIDUAPP:" + this.OPENVIDU_SERVER_SECRET),
//   "Content-Type": "application/json",
// },
