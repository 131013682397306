import React from "react";
import SESSION_EXPIRED from "../../../../src/assets/images/session-expired.png";

export default class SessionDetails extends React.Component {
  render() {
      const {errorMsg} = this.props;
    return(
      <div className="wrapper light-blue-customer-template">
      <header className="bg-blue-version2">
        <nav className="navbar navbar-expand-lg h-100 navbar-light bh-white ps-3">          
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <a href="#" className="nav-link"></a>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      <section className="main-body m-auto" style={{maxWidth: '100%'}}>
        <div className="container-fluid mt-4 mb-4">
          <div className="row">
            <div className="col-md-12 text-center">
              <img src={SESSION_EXPIRED} className="img-fluid mx-auto my-5 d-block" alt=""/>
        <h4 className="text-dark font-weight-bold ltr-spc-0">{errorMsg.title}</h4>
        <h5 className="txt-light-black mt-3 mb-3 ltr-spc-0">{errorMsg.description}</h5>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
  }
}
