import React, { useEffect, useRef } from 'react';
import style from './style.module.css'
const ImagePreviewModal = ({ isOpen, imageSrc, onClose }) => {
    const modalRef = useRef(null);
    useEffect(() => {
        const handleOutsideClick = (e) => {
            // Check if the click target is outside of the modal content
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                onClose();
            }
        };
        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        }
        // Cleanup the event listener when the modal is closed or component is unmounted
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isOpen, onClose]);
    if (!isOpen) return null;

    return (
        <div  className={style["modal-overlay"]}>
            <div ref={modalRef} className={style["modal-content"]}>
                <span className={style["close-btn"]} onClick={onClose}>
                    &times;
                </span>
                <img src={imageSrc} alt="Preview" className={style["image-preview"]} />
            </div>
        </div>
    );
};

export default ImagePreviewModal;
// rounded-circle border enlarge