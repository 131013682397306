import React, { Component } from "react";
import ADD_CRICLE_ICON from "../../../../../assets/icons/add-circle-icon.png";
import SEARCH_ICON from "../../../../../assets/icons/search-light-blue-icon.png";
import SORT_ICON from "../../../../../assets/icons/sort-gray-icon.png";
import FILTER_ICON from "../../../../../assets/icons/filter-dark-blue-icon.png";
import CANCEL_ICON from "../../../../../assets/icons/cancel.svg";
import api from "../../../../../apiBaseConfig";
import ClearIcon from "@material-ui/icons/Clear";
import {
  DASBOARD_AGENT_LIST,
  DASBOARD_AUDITOR_LIST,
  DASBOARD_AGENTAUDITOR_LIST,
  ACTIVATE_USER,
  DEACTIVATE_USER,
  AGENT_AUDITOR,
} from "../../../../../apiurl";
import _get from "lodash/get";
import "./AgentList.scss";
import AddExecutive from "../addExecutive/AddExecutive";
import { Switch } from "@material-ui/core";
export default class AgentList extends Component {
  state = {
    agentList: [],
    auditorList: [],
    agentAuditorList: [],
    allList: [],
    tabName: "Agent",
    openAddExecutive: false,
    applySearch: false,
    showSearchBar: false,
    searchObject: { id: null, name: null, auditorName: null },
  };

  async componentDidMount() {
    this.getUserData([
      api.get(DASBOARD_AGENT_LIST),
      api.get(DASBOARD_AUDITOR_LIST),
      api.get(DASBOARD_AGENTAUDITOR_LIST),
    ]);
  }

  async getUserData(promiseArr) {
    try {
      const [agentList, auditorList, agentAuditorList] = await Promise.all(
        promiseArr
      );
      this.setState({
        ...this.state,
        agentList: agentList.data,
        auditorList: auditorList.data,
        agentAuditorList: agentAuditorList.data,
      });
    } catch (error) {
      console.log("error in fetching agent list", error);
    }
  }

  async patchAuditor(index, event) {
    const auditorId = event.value;
    const auditorName = event.selectedOptions?.[0].innerText;
    let agent_details = this.state.agentList;
    if (
      !window.confirm(`Do you want to change ${agent_details[index].agent_detail.first_name
        }'s auditor
    from ${agent_details[index].auditor_of_agent?.first_name} ${agent_details[index].auditor_of_agent?.last_name || ""
        }
    to ${auditorName}`)
    ) {
      return;
    }
    try {
      await api.post(AGENT_AUDITOR, {
        auditor: auditorId,
        agent: agent_details[index].agent_detail.id,
      });
      agent_details[index].auditor_of_agent = {
        id: +auditorId,
        first_name: auditorName,
      };
      this.setState({ ...this.state, agentList: agent_details });
    } catch (error) {
      console.log("error in select", error);
      this.getUserData([
        api.get(DASBOARD_AGENT_LIST),
        api.get(DASBOARD_AUDITOR_LIST),
        api.get(DASBOARD_AGENTAUDITOR_LIST),
      ]);
    }
  }

  async handleToggle(user, callBack) {
    const url = user.is_active ? DEACTIVATE_USER : ACTIVATE_USER;
    if (
      !window.confirm(
        `Do you wish to ${user.is_active ? "deactivate" : "activate"} the ${user.first_name
        } ${user.last_name}`
      )
    ) {
      return;
    }
    try {
      await api.patch(url + user.id + "/");
      callBack();
    } catch (error) {
      console.log("error in toggle", error);
    }
  }

  render() {
    const {
      agentList,
      auditorList,
      agentAuditorList,
      tabName,
      openAddExecutive,
      applySearch,
      showSearchBar,
      searchObject,
    } = this.state;
    return (
      <div className="container-fluid p-0">
        {/*<div className="col-md-12 d-flex h-60 align-items-center">
         <div>Search</div>
          <Paper component="form" className="root1">
            <InputBase
              className="input1"
              value={applySearch}
              onChange={(e) => this.setState({ ...this.state, applySearch: e.target.value })}
              inputProps={{ 'aria-label': 'search google maps' }}
            />
            <IconButton className="icon1" aria-label="menu" onClick={() => this.setState({ ...this.state, applySearch: "" })}>
              <img src={applySearch ? CANCEL_ICON : SEARCH_ICON} alt="search" />
            </IconButton>
          </Paper>
        </div> */}
        <div
          className="row g-0 bg-light pt-2 pb-2"
          style={{ backgroundColor: "#FBFBFB" }}
        >
          <div className="col-md-6">
            <ul
              className="nav nav-tabs nav-tabs-plain transparent border-bottom brdr-db"
              id="myTabNested"
              role="tablist"
            >
              {/* <li className="nav-item">
                <span
                  className="nav-link active text-uppercase ltr-spc-1"
                  id="all-tab"
                  href="#all"
                  data-toggle="tab"
                >
                  All
                </span>
              </li> */}
              <li className="nav-item">
                <button
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      tabName: "Agent",
                      searchObject: { id: null, name: null, auditorName: null },
                    })
                  }
                  className="nav-link active text-uppercase"
                  id="agent-tab"
                  href="#agent"
                  data-bs-toggle="tab"
                >
                  Agent
                </button>
              </li>
              <li className="nav-item">
                <button
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      tabName: "Auditor",
                      searchObject: { id: null, name: null, auditorName: null },
                    })
                  }
                  className="nav-link text-uppercase"
                  data-bs-toggle="tab"
                >
                  Auditor
                </button>
              </li>
              <li className="nav-item">
                <button
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      tabName: "Agent/Auditor",
                      searchObject: { id: null, name: null, auditorName: null },
                    })
                  }
                  className="nav-link text-uppercase"
                  data-bs-toggle="tab"
                >
                  Agent cum Auditor
                </button>
              </li>
            </ul>
          </div>
          <div className="col-md-6 text-end">
            <button
              className="btn btn-dark-blue-secondary poppins-rglr me-3 d-inline-flex align-items-center add-executive"
              onClick={() =>
                this.setState({
                  ...this.state,
                  openAddExecutive: !openAddExecutive,
                })
              }
            >
              <img src={ADD_CRICLE_ICON} className="me-1" alt="" />
              Add Executive
            </button>
          </div>
        </div>
        <div className="row g-0">
          <div className={openAddExecutive ? "col-md-7" : "col-md-12"}>
            <div className="ml-30 mr-30 mt-3">
              <div className="row">
                <div className="col-md-12 text-end gray-circle-btns no-span search-sec">
                  {applySearch && (
                    <span>
                      Clear search results
                      <ClearIcon
                        onClick={() => {
                          this.setState({
                            ...this.state,
                            applySearch: false,
                            searchObject: {
                              id: null,
                              name: null,
                              auditorName: null,
                            },
                          });
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </span>
                  )}
                  <span
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        showSearchBar: !showSearchBar,
                      })
                    }
                  >
                    <img
                      src={SEARCH_ICON}
                      alt=""
                      style={{ height: "auto" }}
                      data-toggle="tooltip"
                      data-placement="top"
                      title="SEARCH"
                    />
                  </span>
                  {/* <span>
                    <img
                      src={SORT_ICON}
                      alt=""
                      style={{ height: "auto" }}
                      data-toggle="tooltip"
                      data-placement="top"
                      title="SORT BY"
                    />
                  </span>
                  <span className="bg-blue">
                    <img
                      src={FILTER_ICON}
                      alt=""
                      style={{ height: "auto" }}
                      data-toggle="tooltip"
                      data-placement="top"
                      title="FILTER"
                    />
                  </span> */}
                </div>
              </div>
              {showSearchBar ? (
                <div
                  className="global-search-box-cntr"
                  style={{ position: "relative", right: "0px" }}
                >
                  <div className="global-search-box">
                    <div>
                      <div className="row my-3">
                        <div className="col-2">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder={tabName + " ID"}
                            value={
                              searchObject && searchObject.id
                                ? searchObject.id
                                : ""
                            }
                            onChange={(e) =>
                              this.setState({
                                ...this.state,
                                searchObject: {
                                  ...searchObject,
                                  id: e.target.value || null,
                                },
                              })
                            }
                          />
                        </div>
                        <div className="col-2">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder={tabName + " Name"}
                            value={
                              searchObject && searchObject.name
                                ? searchObject.name
                                : ""
                            }
                            onChange={(e) =>
                              this.setState({
                                ...this.state,
                                searchObject: {
                                  ...searchObject,
                                  name: e.target.value || null,
                                },
                              })
                            }
                          />
                        </div>
                        {tabName === "Agent" && (
                          <div className="col-2">
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder="Auditor Name"
                              value={
                                searchObject && searchObject.auditorName
                                  ? searchObject.auditorName
                                  : ""
                              }
                              onChange={(e) =>
                                this.setState({
                                  ...this.state,
                                  searchObject: {
                                    ...searchObject,
                                    auditorName: e.target.value || null,
                                  },
                                })
                              }
                            />
                          </div>
                        )}
                      </div>

                      <hr />
                      <div className="form-row">
                        <div className="col text-end">
                          <button
                            className="btn btn-secondary btn-sm"
                            style={{ marginRight: "10px" }}
                            onClick={(e) =>
                              this.setState({
                                ...this.state,
                                applySearch: false,
                                searchObject: {
                                  id: null,
                                  name: null,
                                  auditorName: null,
                                },
                              })
                            }
                          >
                            Reset
                          </button>
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={(e) =>
                              this.setState({
                                ...this.state,
                                applySearch: true,
                                showSearchBar: false,
                              })
                            }
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="tab-content mt-1">
                  <React.Fragment>
                    {tabName === "Agent" && (
                      <div
                        className="tab-pane show active"
                        role="tabpanel"
                        aria-labelledby="agent-tab"
                      >
                        <div className="row">
                          <div
                            style={{ height: showSearchBar ? "45vh" : "70vh" }}
                            className="col-md-12 dynamic-col-left overflow-auto"
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <table className="table sml-blue-bg-header mb-1">
                                  <thead>
                                    <tr>
                                      <th width="15%">Agent ID</th>
                                      <th width="19%">Name</th>
                                      <th width="20%" className="text-center">
                                        Active Cases
                                      </th>
                                      <th width="16%" className="text-center">
                                        Completed Cases
                                      </th>
                                      <th width="15%" className="text-center">
                                        Auditor
                                      </th>
                                      <th
                                        width="15%"
                                        className="text-center"
                                        align="center"
                                      >
                                        Access Control
                                      </th>
                                    </tr>
                                  </thead>
                                </table>
                              </div>
                            </div>

                            {agentList
                              .map((details, index) => {
                                details.index = index;
                                return details;
                              })
                              .filter((details) => {
                                if (
                                  (!searchObject.name &&
                                    !searchObject.id &&
                                    !searchObject.auditorName) ||
                                  !applySearch
                                ) {
                                  return true;
                                }
                                return (
                                  (searchObject.name
                                    ? details.agent_detail.first_name
                                      .toLowerCase()
                                      .includes(
                                        searchObject.name.toLowerCase()
                                      ) ||
                                    details.agent_detail.last_name
                                      .toLowerCase()
                                      .includes(
                                        searchObject.name.toLowerCase()
                                      )
                                    : true) &&
                                  (searchObject.auditorName
                                    ? details.auditor_of_agent?.first_name
                                      ?.toLowerCase()
                                      .includes(
                                        searchObject.auditorName.toLowerCase()
                                      ) ||
                                    details.auditor_of_agent?.last_name
                                      ?.toLowerCase()
                                      .includes(
                                        searchObject.auditorName.toLowerCase()
                                      )
                                    : true) &&
                                  (searchObject.id
                                    ? details.agent_detail.id == searchObject.id
                                    : true)
                                );
                              })
                              .map((details, index) => (
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="tbl-row">
                                      <table className="table mb-0 sml-blue-bg-header table-striped odd-even-tbl">
                                        <tbody>
                                          <tr>
                                            <td width="15%">
                                              {details.agent_detail.id}
                                            </td>
                                            <td width="19%">
                                              {" "}
                                              {
                                                details.agent_detail.first_name
                                              }{" "}
                                              {details.agent_detail.last_name}
                                            </td>
                                            <td width="20%" align="center">
                                              {details.active_cases}
                                            </td>
                                            <td width="16%" align="center">
                                              {details.completed_cases}
                                            </td>
                                            <td width="15%" align="center">
                                              <select
                                                className="form-select form-control roboto-medium h-auto"
                                                aria-label="Default select example"
                                                value={
                                                  details.auditor_of_agent
                                                    ?.id || ""
                                                }
                                                onChange={(e) => {
                                                  this.patchAuditor(
                                                    details.index,
                                                    e.target
                                                  );
                                                }}
                                              >
                                                <option value="">
                                                  Select Auditor
                                                </option>
                                                {auditorList.map((auditor) => (
                                                  <option
                                                    value={
                                                      auditor.auditor_detail.id
                                                    }
                                                  >
                                                    {" "}
                                                    {
                                                      auditor.auditor_detail
                                                        .first_name
                                                    }{" "}
                                                    {
                                                      auditor.auditor_detail
                                                        .last_name
                                                    }
                                                  </option>
                                                ))}
                                                {agentAuditorList.map(
                                                  (auditor) => (
                                                    <option
                                                      value={
                                                        auditor
                                                          .agent_auditor_detail
                                                          .id
                                                      }
                                                    >
                                                      {" "}
                                                      {
                                                        auditor
                                                          .agent_auditor_detail
                                                          .first_name
                                                      }{" "}
                                                      {
                                                        auditor
                                                          .agent_auditor_detail
                                                          .last_name
                                                      }
                                                    </option>
                                                  )
                                                )}
                                              </select>
                                              {/* <span
                                            className="type none d-inline-flex align-items-center dropdown dropdown-toggle dropleft"
                                            data-toggle="dropdown"
                                          >
                                            <span className="default-text">Select</span>
                                            <div className="dropdown-menu border-0 transfer-box fnt-sml">
                                              <a
                                                className="dropdown-item"
                                                data-val="Agent"
                                                href="#"
                                              >
                                                <span>Agent</span>
                                              </a>
                                              <a
                                                className="dropdown-item"
                                                data-val="Auditor"
                                                href="#"
                                              >
                                                <span>Auditor</span>
                                              </a>
                                              <a
                                                className="dropdown-item"
                                                data-val="Both"
                                                href="#"
                                              >
                                                <span>Both</span>
                                              </a>
                                            </div>
                                          </span> */}
                                            </td>
                                            <td width="15%" align="center">
                                              Inactive
                                              <Switch
                                                checked={
                                                  details.agent_detail.is_active
                                                }
                                                onChange={(e) => {
                                                  let agent_details = [
                                                    ...agentList,
                                                  ];
                                                  this.handleToggle(
                                                    details.agent_detail,
                                                    () => {
                                                      agent_details[
                                                        details.index
                                                      ].agent_detail.is_active =
                                                        !agent_details[
                                                          details.index
                                                        ].agent_detail
                                                          .is_active;
                                                      this.setState({
                                                        ...this.state,
                                                        agentList:
                                                          agent_details,
                                                      });
                                                    }
                                                  );
                                                }}
                                                name="Access Control"
                                                inputProps={{
                                                  "aria-label":
                                                    "primary checkbox",
                                                }}
                                              />
                                              Active
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    )}
                    {tabName === "Auditor" && (
                      <div
                        className="tab-pane show active"
                        role="tabpanel"
                        aria-labelledby="auditor-tab"
                      >
                        <div className="row">
                          <div
                            style={{ height: "70vh" }}
                            className="col-md-12 dynamic-col-left overflow-auto"
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <table className="table sml-blue-bg-header mb-1">
                                  <thead>
                                    <tr>
                                      <th width="15%">Auditor ID</th>
                                      <th width="19%">Auditor Name</th>
                                      <th width="17%" className="text-center">
                                        Pending Cases
                                      </th>
                                      <th width="17%" className="text-center">
                                        Approved Cases
                                      </th>
                                      <th width="17%" className="text-center">
                                        Rejected Cases
                                      </th>
                                      <th
                                        width="15%"
                                        className="text-center"
                                        align="center"
                                      >
                                        Access Control
                                      </th>
                                    </tr>
                                  </thead>
                                </table>
                              </div>
                            </div>

                            {auditorList
                              .map((details, index) => {
                                details.index = index;
                                return details;
                              })
                              .filter((details) => {
                                if (
                                  (!searchObject.name && !searchObject.id) ||
                                  !applySearch
                                ) {
                                  return true;
                                }
                                return (
                                  (searchObject.name
                                    ? details.auditor_detail.first_name
                                      .toLowerCase()
                                      .includes(
                                        searchObject.name.toLowerCase()
                                      ) ||
                                    details.auditor_detail.last_name
                                      .toLowerCase()
                                      .includes(
                                        searchObject.name.toLowerCase()
                                      )
                                    : true) &&
                                  (searchObject.id
                                    ? details.auditor_detail.id ==
                                    searchObject.id
                                    : true)
                                );
                              })
                              .map((auditor, index) => (
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="tbl-row">
                                      <table className="table mb-0 sml-blue-bg-header table-striped odd-even-tbl">
                                        <tbody>
                                          <tr>
                                            <td width="15%">
                                              {auditor.auditor_detail.id}
                                            </td>
                                            <td width="19%">
                                              {
                                                auditor.auditor_detail
                                                  .first_name
                                              }{" "}
                                              {auditor.auditor_detail.last_name}
                                            </td>
                                            <td width="17%" align="center">
                                              {auditor.pending_cases}
                                            </td>
                                            <td width="17%" align="center">
                                              {auditor.approved_cases}
                                            </td>
                                            <td width="17%" align="center">
                                              {auditor.rejected_cases}
                                            </td>
                                            <td width="15%" align="center">
                                              Inactive
                                              <Switch
                                                checked={
                                                  auditor.auditor_detail
                                                    .is_active
                                                }
                                                onChange={(e) => {
                                                  let auditor_details = [
                                                    ...auditorList,
                                                  ];
                                                  this.handleToggle(
                                                    auditor.auditor_detail,
                                                    () => {
                                                      auditor_details[
                                                        auditor.index
                                                      ].auditor_detail.is_active =
                                                        !auditor_details[index]
                                                          .auditor_detail
                                                          .is_active;
                                                      this.setState({
                                                        ...this.state,
                                                        auditorList:
                                                          auditor_details,
                                                      });
                                                    }
                                                  );
                                                }}
                                                name="Access Control"
                                                inputProps={{
                                                  "aria-label":
                                                    "primary checkbox",
                                                }}
                                              />
                                              Active
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    )}
                    {tabName === "Agent/Auditor" && (
                      <div
                        className="tab-pane show active"
                        role="tabpanel"
                        aria-labelledby="both-tab"
                      >
                        <div className="row">
                          <div
                            style={{ height: "70vh" }}
                            className="col-md-12 dynamic-col-left overflow-auto"
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <table className="table sml-blue-bg-header mb-1">
                                  <thead>
                                    <tr>
                                      <th width="10%">Agent/Auditor ID</th>
                                      <th width="12%">Name</th>
                                      <th width="10%" className="text-center">
                                        Active Cases
                                      </th>
                                      <th width="10%" className="text-center">
                                        Completed Cases
                                      </th>
                                      <th width="10%" className="text-center">
                                        Approved Cases
                                      </th>
                                      <th width="10%" className="text-center">
                                        Rejected Cases
                                      </th>
                                      <th width="10%" className="text-center">
                                        Pending Cases
                                      </th>
                                      <th
                                        width="15%"
                                        className="text-center"
                                        align="center"
                                      >
                                        Access Control
                                      </th>
                                    </tr>
                                  </thead>
                                </table>
                              </div>
                            </div>

                            {agentAuditorList
                              .map((details, index) => {
                                details.index = index;
                                return details;
                              })
                              .filter((details) => {
                                if (
                                  (!searchObject.name && !searchObject.id) ||
                                  !applySearch
                                ) {
                                  return true;
                                }
                                return (
                                  (searchObject.name
                                    ? details.agent_auditor_detail.first_name
                                      .toLowerCase()
                                      .includes(
                                        searchObject.name.toLowerCase()
                                      ) ||
                                    details.agent_auditor_detail.last_name
                                      .toLowerCase()
                                      .includes(
                                        searchObject.name.toLowerCase()
                                      )
                                    : true) &&
                                  (searchObject.id
                                    ? details.agent_auditor_detail.id ==
                                    searchObject.id
                                    : true)
                                );
                              })
                              .map((auditor, index) => (
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="tbl-row">
                                      <table className="table mb-0 sml-blue-bg-header table-striped odd-even-tbl">
                                        <tbody>
                                          <tr>
                                            <td width="10%">
                                              {auditor.agent_auditor_detail.id}
                                            </td>
                                            <td width="12%">
                                              {
                                                auditor.agent_auditor_detail
                                                  .first_name
                                              }{" "}
                                              {
                                                auditor.agent_auditor_detail
                                                  .last_name
                                              }
                                            </td>
                                            <td width="10%" align="center">
                                              {auditor.active_cases}
                                            </td>
                                            <td width="10%" align="center">
                                              {auditor.completed_cases}
                                            </td>
                                            <td width="10%" align="center">
                                              {auditor.approved_cases}
                                            </td>
                                            <td width="10%" align="center">
                                              {auditor.rejected_cases}
                                            </td>
                                            <td width="10%" align="center">
                                              {auditor.pending_cases}
                                            </td>
                                            <td width="15%" align="center">
                                              Inactive
                                              <Switch
                                                checked={
                                                  auditor.agent_auditor_detail
                                                    .is_active
                                                }
                                                onChange={(e) => {
                                                  let agentAuditor_details = [
                                                    ...agentAuditorList,
                                                  ];
                                                  this.handleToggle(
                                                    auditor.agent_auditor_detail,
                                                    () => {
                                                      agentAuditor_details[
                                                        auditor.index
                                                      ].agent_auditor_detail.is_active =
                                                        !agentAuditor_details[
                                                          auditor.index
                                                        ].agent_auditor_detail
                                                          .is_active;
                                                      this.setState({
                                                        ...this.state,
                                                        agentAuditorList:
                                                          agentAuditor_details,
                                                      });
                                                    }
                                                  );
                                                }}
                                                name="Access Control"
                                                inputProps={{
                                                  "aria-label":
                                                    "primary checkbox",
                                                }}
                                              />
                                              Active
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                </div>
              )}
            </div>
          </div>
          {openAddExecutive && (
            <AddExecutive
              auditorList={auditorList}
              closeExecutive={() =>
                this.setState({ ...this.state, openAddExecutive: false })
              }
            />
          )}
        </div>
      </div>
    );
  }
}
