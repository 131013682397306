import React from "react";
import { IconButton } from "@material-ui/core";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import UserHeader from "../../userHeader/userHeader";
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent } from "@material-ui/core";
import { ReactComponent as CameraIcon } from "../../../../src/assets/icons/camera-icon.svg";
import { ReactComponent as UploadIcon } from "../../../../src/assets/icons/upload-icon.svg";
import { ReactComponent as DocsIcon } from "../../../../src/assets/icons/google-docs.svg";
import { connect } from 'react-redux'
import cogoToast from "cogo-toast";
import { PAN_CHECK, NAME_CHECK } from '../../../constants'
class PanUploadOptions extends React.Component {
  state = {
    uploadTypes: { ocr_upload: false, digilocker_upload: false },
    pan_name: '',
    pan_number: '',
    PanModal: false
  };
  componentDidMount() {
    this.setUploadTypes()
  }
  setUploadTypes() {
    let documents = this.props?.userConnectionInfo?.case_details?.process_details?.documents
    if (documents) {
      let clientTypes = documents.filter(item => item.doc_key === "pan")
      this.setState({ uploadTypes: clientTypes[0] })
    }
  }
  setDigilockerAPI = () => {
    let { pan_name, pan_number } = this.state
    let isError = false
    let data = {
      document_name: 'pan',
      pan_name,
      pan_number
    }
    if (!NAME_CHECK(pan_name)) {
      isError = true
      cogoToast.error('Enter Valid name')
    }
    if (!PAN_CHECK(pan_number)) {
      isError = true
      cogoToast.error('Enter Valid PAN number')
    }
    if (isError) return
    this.setState({PanModal:false})
    this.props.callDigilockerAPI("PAN Verification Record", data)
  }
  render() {
    const {
      uploadFileType,
      isAadhaar,
      documentName,
      takePhoto,
      takeAadhaarPhoto,
    } = this.props;
    const { uploadTypes, PanModal } = this.state

    return (
      <>
        <div className="wrapper light-blue-customer-template">
          <UserHeader />
          <section className="main-body m-auto customer-flow-section">
            <div className="container-fluid mt-4">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="txt-light-blue ltr-spc-1">
                    {documentName} Upload
                  </h4>
                  <h5 className="txt-light-black ltr-spc-1 mt-3 mb-3">
                    Use this section to upload your {documentName}
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {uploadTypes.ocr_upload ? <div className="white-box p-5-15 mt-3 pointer">
                    <label htmlFor="file-upload" style={{ cursor: "pointer",width:'100%' }}>
                      <div className="left float-left d-flex align-items-center">
                        <span className="fingerprint-icon icon-light-blue">
                          <UploadIcon width="20" />
                        </span>

                        <span className="txt-light-black fnt-sml ms-3 ltr-spc-0-5">
                          Browse to upload
                        </span>
                      </div>
                    </label>
                    <input
                      id="file-upload"
                      type="file"
                      accept={uploadFileType}
                      onChange={(e) =>
                        this.props.uploadFile(e, isAadhaar, "front", 2)
                      }
                    ></input>
                  </div> : null}
                  {uploadTypes.digilocker_upload ? <div
                    className="white-box p-5-15 mt-3 pointer"
                    onClick={(e) => {
                      this.setState({ PanModal: true, pan_name:'', pan_number:'' });
                    }}
                  >
                    <div className="left float-left d-flex align-items-center">
                      <span className="fingerprint-icon icon-light-blue">
                        <DocsIcon width="20" />
                      </span>
                      <span className="txt-light-black fnt-sml ms-3 ltr-spc-0-5">
                        Upload via Digilocker
                      </span>
                    </div>
                  </div> : null}
                  {uploadTypes.ocr_upload ? <div
                    onClick={this.props.takePhotoBrowseUploadPan}
                    className="cursor-pointer white-box p-5-15 mt-3 pointer"
                  >
                    <div className="left float-left d-flex align-items-center">
                      <span className="fingerprint-icon icon-light-blue">
                        <CameraIcon width="20" />
                      </span>
                      <span className="txt-light-black fnt-sml ms-3 ltr-spc-0-5">
                        Click a picture
                      </span>
                    </div>
                  </div> : null}
                </div>
                <br />
                <br />

                <div>
                  <h5 className="txt-light-blue ltr-spc-1 mt-3 mb-3">
                    Disclaimer:
                  </h5>
                  <ol type="a">
                    <li className="txt-light-black ltr-spc-1 mt-3 mb-3">
                      {" "}
                      For better result it is recommended to place your mobile
                      right above the document
                    </li>
                    <li className="txt-light-black ltr-spc-1 mt-3 mb-3">
                      Ensure good lighting and avoid any kind of glare or
                      reflection on the document while clicking the image
                    </li>
                    <li className="txt-light-black ltr-spc-1 mt-3 mb-3">
                      Place the document on a white background for better
                      results
                    </li>
                    <li className="txt-light-black ltr-spc-1 mt-3 mb-3">
                      Try to take a stable picture to avoid blurriness
                    </li>
                    <li className="txt-light-black ltr-spc-1 mt-3 mb-3">
                      Your PAN card should not be rotated
                    </li>
                    <li className="txt-light-black ltr-spc-1 mt-3 mb-3">
                      Acceptable file format - JPG, PNG
                    </li>
                    <li className="txt-light-black ltr-spc-1 mt-3 mb-3">
                      Maximum file size - 5 mb
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Dialog
          aria-labelledby="customized-dialog-title"
          maxWidth="md"
          open={takePhoto || takeAadhaarPhoto}
        >
          <div className="align-self-end px-4">
            <IconButton
              aria-label="close"
              onClick={this.props.handleIconButton}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <DialogContent>
            {takePhoto && (
              <Camera
                idealFacingMode={FACING_MODES.ENVIRONMENT}
                idealResolution={{ width: 640, height: 480 }}
                isImageMirror={false}
                isFullscreen={false}
                onTakePhoto={(dataUri) => {
                  this.props.handleTakePhoto(dataUri, "normal_upload");
                }}
              />
            )}

            {takeAadhaarPhoto && (
              <Camera
                idealFacingMode={FACING_MODES.ENVIRONMENT}
                idealResolution={{ width: 640, height: 480 }}
                isImageMirror={false}
                isFullscreen={false}
                onTakePhoto={(dataUri) => {
                  this.handleAadhaar(
                    dataUri,
                    this.props.photoSide,
                    this.props.documentId,
                    "normal_upload"
                  );
                }}
              />
            )}
          </DialogContent>
        </Dialog>
        <Dialog
          aria-labelledby="customized-dialog-title"
          maxWidth="md"
          open={PanModal}
        >
          <div className="align-self-end px-4">
            <IconButton
              aria-label="close"
              onClick={()=>this.setState({PanModal:false})}
            >
              <CloseIcon color="error" />
            </IconButton>
          </div>
          <DialogContent>
            <div className="row">
            <div className="col-md-12">
            Enter Your PAN Details
            </div>  
            <div className="col-md-12">
              <label className="txt-light-black fnt-sml">Name As Per PAN</label>
              <input className="form-control" placeholder="Enter your name" onChange={e => this.setState({ pan_name: e.target.value })} value={this.state.pan_name} />
            </div>
            <div className="col-md-12">
              <label className="txt-light-black fnt-sml">Pan Number</label>
              <input className="form-control" placeholder="Enter Pan" onChange={e => this.setState({ pan_number: e.target.value })} value={this.state.pan_number} />
            </div>
            <div className="col-md-12 pt-2 pb-4">
              <button className="btn btn-primary" onClick={e=>this.setDigilockerAPI()}>Submit</button>
            </div>
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  userConnectionInfo: state.userDocs.anonymousUserConnectionInfo,
});
export default connect(mapStateToProps)(PanUploadOptions)
