import React from "react";
import UpperTab from "../upperTab/UpperTab";
import ActiveCasesContainer from "../activeCasesContainer/ActiveCasesContainer";
import CompletedCasesContainer from "../completedCasesContainer/CompletedCasesContainer";
import TransferredCasesContainer from "../transferredCasesContainer/TransferredCasesContainer";
import CancelledCasesContainer from "../cancelledCasesContainer/CancelledCasesContainer";
import {
  GET_CASES,
  GET_DOCUMENTS,
  GET_AGENTS,
  GET_AUDITORS,
  GET_AGENT_AUDITORS,
} from "../../../../../apiurl";
import api from "../../../../../apiBaseConfig";
import Loader from "../../../../../components/loader/Loader";
import TablePagination from "@material-ui/core/TablePagination";
import { withStyles } from "@material-ui/core/styles";
import LiveCasesContainer from "../liveCasesContainer/LiveCasesConainer";

const styles = (theme) => ({
  table: {
    minWidth: 400,
  },
  root: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
});

class CaseManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeUpperTab: 0,
      activeCasesLowerTab: 0,
      completedCasesLowerTab: 0,
      casesDataArray: [],
      isLoading: true,
      totalCount: 1,
      page: 0,
      rowsPerPage: 25,
      agentList: [],
      auditorList: [],
      agentAuditorList: [],
      loaderTitle: "Loading Cases. Please wait",
      searchKeyObject: {},
      sortingOrder: "-created_at",
      ordering: "created_at_order",
      consolidatedAgentList: []
    };
  }
  componentDidMount() {
    this.getCaseData();
    this.getAgentsAndAuditorsList();
  }

  getAgentsAndAuditorsList = async () => {
    try {
      const [agentList, agentAuditorList] = await Promise.all([
        api.get(GET_AGENTS),
        api.get(GET_AGENT_AUDITORS),
      ]);
      this.setState({
        agentList: agentList.data,
        agentAuditorList: agentAuditorList.data,
      });
      this.setConsolidatedAgentList(agentList.data);
    } catch (error) {
      console.log("error in fetching agent list", error);
    }
  };

  setConsolidatedAgentList = (agentList) => {
    let consolidatedAgentList = [];
    for (var i in agentList) {
      if (agentList[i].agent_details) {
        let obj = {
          name:
            agentList[i].agent_details.first_name +
            " " +
            agentList[i].agent_details.last_name,
          id: agentList[i].agent_details.id,
          first_name: agentList[i].agent_details.first_name,
          last_name: agentList[i].agent_details.last_name,
        };
        consolidatedAgentList.push(obj);
      }
    }
    this.setState({ consolidatedAgentList: consolidatedAgentList });
  };

  getCasesUrl = (activeLowerTab, activeUpperTab) => {
    let casesUrl = "";
    switch (true) {
      case activeUpperTab === 0 && activeLowerTab === 0:
        return (casesUrl = `${GET_CASES}?status=assigned`);
      case activeUpperTab === 0 && activeLowerTab === 1:
        return (casesUrl = `${GET_CASES}?status=scheduled`);
      case activeUpperTab === 0 && activeLowerTab === 2:
        return (casesUrl = `${GET_CASES}?case_tag=assigned_rework`);
      case activeUpperTab === 1 && activeLowerTab === 0:
        return (casesUrl = `${GET_CASES}?status=audited`);
      case activeUpperTab === 1 && activeLowerTab === 1:
        return (casesUrl = `${GET_CASES}?status=rejected`);
      case activeUpperTab === 1 && activeLowerTab === 2:
        return (casesUrl = `${GET_CASES}?case_tag=pending_audit`);
      case activeUpperTab === 2 && activeLowerTab === 0:
        return (casesUrl = `${GET_CASES}?case_tag=assigned_transferred&case_tag=transfer_rescheduled_by_customer`);
      case activeUpperTab === 3 && activeLowerTab === 0:
        return (casesUrl = `${GET_CASES}?status=live`);
      default:
        return (casesUrl = "");
    }
  };

  getDateParams = (activeLowerTab, activeUpperTab) => {
    let dateParams = {};
    switch (true) {
      case activeUpperTab === 0 && activeLowerTab === 0:
        return (dateParams = {
          gteParam: "assigned_at__gte",
          lteParam: "assigned_at__lte",
        });
      case activeUpperTab === 0 && activeLowerTab === 1:
        return (dateParams = {
          gteParam: "session_scheduled_time__gte",
          lteParam: "session_scheduled_time__lte",
        });
      case activeUpperTab === 0 && activeLowerTab === 2:
        return (dateParams = {
          gteParam: "assigned_at__gte",
          lteParam: "assigned_at__lte",
        });
      case activeUpperTab === 1 && activeLowerTab === 0:
        return (dateParams = {
          gteParam: "completed_at__gte",
          lteParam: "completed_at__lte",
        });
      case activeUpperTab === 1 && activeLowerTab === 1:
        return (dateParams = {
          gteParam: "completed_at__gte",
          lteParam: "completed_at__lte",
        });
      case activeUpperTab === 1 && activeLowerTab === 2:
        return (dateParams = {
          gteParam: "completed_at__gte",
          lteParam: "completed_at__lte",
        });
      case activeUpperTab === 2 && activeLowerTab === 0:
        return (dateParams = {
          gteParam: "assigned_at__gte",
          lteParam: "assigned_at__lte",
        });
      case activeUpperTab === 3 && activeLowerTab === 0:
        return (dateParams = {
          gteParam: "assigned_at__gte",
          lteParam: "assigned_at__lte",
        });
      default:
        return (dateParams = {});
    }
  };
  getCaseData = async (
    activeCasesLowerTab = 0,
    completedCasesLowerTab = 0,
    activeUpperTab = 0
  ) => {
    const { searchKeyObject, ordering, sortingOrder } = this.state;
    let activeLowerTab =
      activeUpperTab === 0
        ? activeCasesLowerTab
        : activeUpperTab === 1
          ? completedCasesLowerTab
          : 0;
    let casesUrl = this.getCasesUrl(activeLowerTab, activeUpperTab);
    let dateParams = this.getDateParams(activeLowerTab, activeUpperTab);
    try {
      let response = await api.get(casesUrl, {
        params: {
          page: this.state.page + 1,
          name__icontains: searchKeyObject.case_name
            ? searchKeyObject.case_name
            : "",
          id: searchKeyObject.meeting_id ? searchKeyObject.meeting_id : "",
          agent_fname: searchKeyObject.agent_fname
            ? searchKeyObject.agent_fname
            : "",
          agent_lname: searchKeyObject.agent_lname
            ? searchKeyObject.agent_lname
            : "",
          [dateParams.gteParam]: searchKeyObject.date_from
            ? searchKeyObject.date_from + "T00:00:00.0"
            : "",
          [dateParams.lteParam]: searchKeyObject.date_to
            ? searchKeyObject.date_to + "T23:59:59.0"
            : "",
          [ordering]: sortingOrder,
        },
      });
      this.setState({
        casesDataArray: response.data.results,
        totalCount: response.data.count,
        isLoading: false,
        activeUpperTab: activeUpperTab,
        activeCasesLowerTab: activeCasesLowerTab,
        completedCasesLowerTab: completedCasesLowerTab,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
        activeUpperTab: activeUpperTab,
        activeCasesLowerTab: activeCasesLowerTab,
        completedCasesLowerTab: completedCasesLowerTab,
      });
      console.log(error);
    }
  };
  changeUpperTab = (activeUpperTab) => {
    this.setState({ isLoading: true, page: 0, searchKeyObject: {} }, () => {
      this.getCaseData(0, 0, activeUpperTab);
    });
  };
  updateActiveCasesData = (activeLowerTab) => {
    this.setState({ isLoading: true, page: 0, searchKeyObject: {} }, () => {
      this.getCaseData(activeLowerTab, 0, 0);
    });
  };
  updateCompletedCasesData = (activeLowerTab) => {
    this.setState({ isLoading: true, page: 0, searchKeyObject: {} }, () => {
      this.getCaseData(0, activeLowerTab, 1);
    });
  };
  handlePageChange = (event, value) => {
    const { activeCasesLowerTab, completedCasesLowerTab, activeUpperTab } =
      this.state;
    this.setState(
      {
        ...this.state,
        page: value,
        isLoading: true,
        casesDataArray: [],
      },
      () =>
        this.getCaseData(
          activeCasesLowerTab,
          completedCasesLowerTab,
          activeUpperTab
        )
    );
  };

  handleViewDocuments = async (selectedCase) => {
    const response = await api.get(`${GET_DOCUMENTS}?case=${selectedCase.id}`);
    const documents = response.data.data.map((document) => {
      return {
        frontUpload: document.front_upload,
        backUpload: document.back_upload,
      };
    });

    documents.forEach((document) => {
      if (document.frontUpload) {
        window.open(document.frontUpload);
      }
      if (document.backUpload) {
        window.open(document.backUpload);
      }
    });
  };

  transferCases = async (selectedAgent, selectedCase) => {
    if (
      !window.confirm(
        `Do you want to transfer case ${selectedCase.name} to ${selectedAgent.first_name} ${selectedAgent.last_name}?`
      )
    ) {
      return;
    }
    try {
      this.setState({ isLoading: true, loaderTitle: "Transferring case" });
      await api.patch(`${GET_CASES}${selectedCase.id}/`, {
        agent: selectedAgent.id,
      });
      const { activeCasesLowerTab, completedCasesLowerTab, activeUpperTab } =
        this.state;
      this.getCaseData(
        activeCasesLowerTab,
        completedCasesLowerTab,
        activeUpperTab
      );
    } catch (e) {
      this.setState({
        isLoading: false,
        loaderTitle: "Loading Cases. Please wait",
      });
    }
  };

  handleDeleteCase = async (selectedCase) => {
    if (!window.confirm(`Do you want to delete case ${selectedCase.name}?`)) {
      return;
    }
    try {
      this.setState({ isLoading: true, loaderTitle: "Deleting case" });
      await api.delete(`${GET_CASES}${selectedCase.id}/`);
      this.setState({ isLoading: false, loaderTitle: "" });
      const { activeCasesLowerTab, completedCasesLowerTab, activeUpperTab } =
        this.state;
      this.getCaseData(
        activeCasesLowerTab,
        completedCasesLowerTab,
        activeUpperTab
      );
    } catch (e) {
      this.setState({ isLoading: false, loaderTitle: "" });
    }
  };
  getSearchObject = (searchKeyObject) => {
    const { activeCasesLowerTab, completedCasesLowerTab, activeUpperTab } =
      this.state;
    this.setState({ searchKeyObject: searchKeyObject, isLoading: true }, () =>
      this.getCaseData(
        activeCasesLowerTab,
        completedCasesLowerTab,
        activeUpperTab
      )
    );
  };

  updateSortingOrder = (
    activeCasesLowerTab = 0,
    completedCasesLowerTab = 0,
    activeUpperTab = 0
  ) => {
    const { sortingOrder } = this.state;
    const activeLowerTab =
      activeUpperTab === 0
        ? activeCasesLowerTab
        : activeUpperTab === 1
          ? completedCasesLowerTab
          : 0;
    let newSortingOrder;
    let ordering;
    switch (true) {
      case activeUpperTab === 0 && activeLowerTab === 0:
        ordering = "created_at_order";
        newSortingOrder =
          sortingOrder === "created_at" ? "-created_at" : "created_at";
        break;
      case activeUpperTab === 0 && activeLowerTab === 1:
        ordering = "session_scheduled_time_order";
        newSortingOrder =
          sortingOrder === "session_scheduled_time"
            ? "-session_scheduled_time"
            : "session_scheduled_time";
        break;
      case activeUpperTab === 0 && activeLowerTab === 2:
        ordering = "created_at_order";
        newSortingOrder =
          sortingOrder === "created_at" ? "-created_at" : "created_at";
        break;
      case activeUpperTab === 1 && activeLowerTab === 0:
        ordering = "completed_at_order";
        newSortingOrder =
          sortingOrder === "completed_at" ? "-completed_at" : "completed_at";
        break;
      case activeUpperTab === 1 && activeLowerTab === 1:
        ordering = "completed_at_order";
        newSortingOrder =
          sortingOrder === "completed_at" ? "-completed_at" : "completed_at";
        break;
      case activeUpperTab === 1 && activeLowerTab === 2:
        ordering = "completed_at_order";
        newSortingOrder =
          sortingOrder === "completed_at" ? "-completed_at" : "completed_at";
        break;
      case activeUpperTab === 2 && activeLowerTab === 0:
        ordering = "created_at_order";
        newSortingOrder =
          sortingOrder === "created_at" ? "-created_at" : "created_at";
        break;
    }
    this.setState(
      { sortingOrder: newSortingOrder, isLoading: true, ordering: ordering },
      () => {
        const { activeCasesLowerTab, completedCasesLowerTab, activeUpperTab } =
          this.state;
        this.getCaseData(
          activeCasesLowerTab,
          completedCasesLowerTab,
          activeUpperTab
        );
      }
    );
  };
  handleSort = () => {
    const { activeUpperTab, activeCasesLowerTab, completedCasesLowerTab } =
      this.state;
    this.updateSortingOrder(
      activeCasesLowerTab,
      completedCasesLowerTab,
      activeUpperTab
    );
  };

  render() {
    const {
      activeUpperTab,
      casesDataArray,
      isLoading,
      activeCasesLowerTab,
      completedCasesLowerTab,
      agentAuditorList,
      loaderTitle,
      searchKeyObject,
      agentList,
      consolidatedAgentList
    } = this.state;

    return (
      <React.Fragment>
        <div className="container-fluid p-0">
          <div className="row g-0 bg-white pt-2 header-2">
            <UpperTab
              activeUpperTab={activeUpperTab}
              changeUpperTab={this.changeUpperTab}
            />
          </div>
          {isLoading ? (
            <Loader title={loaderTitle} />
          ) : (
            <div className="tab-content">
              {activeUpperTab === 0 && (
                <ActiveCasesContainer
                  activeCasesData={casesDataArray}
                  updateActiveCasesData={this.updateActiveCasesData}
                  activeCasesLowerTab={activeCasesLowerTab}
                  handleViewDocuments={this.handleViewDocuments}
                  getSearchObject={this.getSearchObject}
                  searchKeyObject={searchKeyObject}
                  handleSort={this.handleSort}
                  agentAuditorList={agentAuditorList}
                  agentList={agentList}
                  transferCases={this.transferCases}
                  handleDeleteCase={this.handleDeleteCase}
                  consolidatedAgentList={consolidatedAgentList}
                />
              )}
              {activeUpperTab === 1 && (
                <CompletedCasesContainer
                  completedCasesData={casesDataArray}
                  updateCompletedCasesData={this.updateCompletedCasesData}
                  completedCasesLowerTab={completedCasesLowerTab}
                  handleViewDocuments={this.handleViewDocuments}
                  getSearchObject={this.getSearchObject}
                  searchKeyObject={searchKeyObject}
                  handleSort={this.handleSort}
                  agentAuditorList={agentAuditorList}
                  agentList={agentList}
                  consolidatedAgentList={consolidatedAgentList}
                />
              )}
              {activeUpperTab === 2 && (
                <TransferredCasesContainer
                  transferredCasesData={casesDataArray}
                  handleViewDocuments={this.handleViewDocuments}
                  agentAuditorList={agentAuditorList}
                  agentList={agentList}
                  transferCases={this.transferCases}
                  getSearchObject={this.getSearchObject}
                  searchKeyObject={searchKeyObject}
                  handleSort={this.handleSort}
                  handleDeleteCase={this.handleDeleteCase}
                  consolidatedAgentList={consolidatedAgentList}
                />
              )}
              {activeUpperTab === 3 && (
                <LiveCasesContainer
                  dataArray={casesDataArray}
                  getSearchObject={this.getSearchObject}
                  searchKeyObject={searchKeyObject}
                  handleSort={this.handleSort}
                />
              )}
            </div>
          )}
        </div>
        <div style={{ marginBottom: "40px" }}>
          <TablePagination
            count={this.state.totalCount}
            page={this.state.page}
            classes={{ root: "pagination-border" }}
            rowsPerPageOptions={[this.state.rowsPerPage]}
            rowsPerPage={this.state.rowsPerPage}
            onChangePage={this.handlePageChange}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(CaseManagement);
