import React, { Component } from "react";
import "./AddExecutive.scss";
import ADD from "../../../../../assets/icons/add.svg";
import CLOSE from "../../../../../assets/icons/close-icon.png";
import { Toast } from "../../../../../utils/toastutils";
import api from "../../../../../apiBaseConfig";
import { ADD_EXECUTIVE } from "../../../../../apiurl";
import { GET_AGENT_AUDITORS } from "../../../../../apiurl";
import { connect } from "react-redux";
class AddExecutive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      executiveDetails: {},
      executiveDetailsError: {},
      agentAuditorList: [],
    };
  }

  componentDidMount() {
    this.setInitialExecutiveDetails();
  }
  handleChange =() =>{
    let {executiveDetails, executiveDetailsError} = this.state

  }
  setInitialExecutiveDetails = () => {
    const executiveDetails = {
      role: "",
      first_name: "",
      last_name: "",
      email: "",
      auditor: [],
      mobile_number: null,
    };
    const executiveDetailsError = {
      role: false,
      first_name: false,
      last_name: false,
      email: false,
      auditor: false,
      mobile_number: false,
    };
    this.setState({
      executiveDetails: executiveDetails,
      executiveDetailsError: executiveDetailsError,
    });
  };

  setExecutiveDetails = async (key, val) => {
    let executiveDetails = this.state.executiveDetails;
    executiveDetails[key] = val;
    this.setState({ executiveDetails: executiveDetails });
    try {
      const [agentAuditorList] = await Promise.all([
        api.get(GET_AGENT_AUDITORS),
      ]);
      this.setState({
        agentAuditorList: agentAuditorList.data,
      });
    } catch (error) {
      console.log("error in fetching agent list", error);
    }
  };

  setError = (key, val) => {
    let executiveDetailsError = this.state.executiveDetailsError;
    executiveDetailsError[key] = val;
    this.setState({ executiveDetailsError: executiveDetailsError });
  };

  checkForAlphabetsOnly = (key, val) => {
    if (!new RegExp(/^[ A-Za-z]{2,60}[A-Za-z\s]{0,60}$/i).test(val)) {
      this.setError(key, true);
    } else {
      this.setError(key, false);
    }
  };

  checkForEmail = (key, val) => {
    if (
      !new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      ).test(val)
    ) {
      this.setError(key, true);
    } else {
      this.setError(key, false);
    }
  };

  checkForNumeric = (key, val) => {
    let error = true;
    if (!new RegExp(/^[1-9]+[0-9]*$/i).test(val)) {
      error = true;
    } else {
      if (key === "mobile_number") {
        error = val.length === 10 ? false : true;
      } else {
        error = false;
      }
    }
    this.setError(key, error);
  };

  checkForErrors = () => {
    const { executiveDetails, executiveDetailsError } = this.state;
    let hasError = false;
    if (
      executiveDetails["auditor"].length === 0 &&
      executiveDetails["role"] === "agent"
    ) {
      hasError = true;
    }
    for (var i in executiveDetails) {
      if (executiveDetails[i] === "") {
        hasError = true;
      }
    }
    for (var i in executiveDetailsError) {
      if (executiveDetailsError[i]) {
        hasError = true;
      }
    }
    return hasError;
  };
  addExecutive = async () => {
    let hasError = this.checkForErrors();
    let { client } = this.props.clientInfo
    if (hasError) {
      Toast.error({
        description: "Please provide valid inputs.",
        title: "Invalid inputs",
      });
    } else {
      const { executiveDetails } = this.state;
      try {
        let response = await api.post(ADD_EXECUTIVE, {
          role: executiveDetails.role,
          first_name: executiveDetails.first_name,
          last_name: executiveDetails.last_name,
          email: executiveDetails.email,
          client: client,
          mobile_number: executiveDetails.mobile_number,
          auditor_id: executiveDetails.auditor.length
            ? +executiveDetails.auditor
            : null,
        });
        this.props.closeExecutive();
        if (response && response.status == 201) {
          Toast.success({
            description: "New user successfully created.",
            title: "User created",
          });
        }
      } catch (error) {
        console.log("hereeeeeeee", error);
      }
    }
  };

  render() {
    const { executiveDetails, executiveDetailsError, agentAuditorList } =
      this.state;
    const { auditorList } = this.props;

    return (
      <div className="col-sm-5 add-executive-cntr mt-5 pt-2">
        <div className="row border border-primary mb-3">
          <div className="col-sm-12 text-end mb-2">
            <a href="javascript:void(0)';">
              <img
                onClick={this.props.closeExecutive}
                src={CLOSE}
                width="20px"
                height="20px"
              />
            </a>
          </div>
          <div className="col-sm-6 d-flex align-items-end">
            <h6 className="text-uppercase text-primary roboto-medium">
              Add an Executive
            </h6>
          </div>
          <div className="col-sm-12 py-3">
            <div>
              <div className="form-row">
                <div className="form-group col-md-4 mb-2">
                  <select
                    className="form-select form-control roboto-medium "
                    aria-label="Default select example"
                    onChange={(e) =>
                      this.setExecutiveDetails("role", e.target.value)
                    }
                  >
                    <option value="">Executive Type</option>
                    <option value="agent">Agent</option>
                    <option value="auditor">Auditor</option>
                    <option value="agent_auditor">Both</option>
                  </select>
                  {executiveDetailsError.role && (
                    <div className="error txt-blue-dull">Error</div>
                  )}
                </div>
                {executiveDetails.role === "agent" && (
                  <div className="form-group col-md-4 mb-2 ms-2">
                    <select
                      className="form-select form-control roboto-medium "
                      aria-label="Default select example"
                      onChange={(e) =>
                        this.setExecutiveDetails("auditor", e.target.value)
                      }
                    >
                      <option value="">Select Auditor</option>
                      {auditorList.map((auditor) => (
                        <option value={auditor.auditor_detail.id}>
                          {" "}
                          {auditor.auditor_detail.first_name}{" "}
                          {auditor.auditor_detail.last_name}
                        </option>
                      ))}
                      {agentAuditorList.map((agentauditor) => (
                        <option value={agentauditor.id}>
                          {agentauditor.first_name} {agentauditor.last_name}
                        </option>
                      ))}
                    </select>
                    {executiveDetailsError.auditor && (
                      <div className="error txt-blue-dull">Error</div>
                    )}
                  </div>
                )}
              </div>
              <div className="form-row">
                <div className="form-group col-md-12 mb-0">
                  <label className="mb-0 text-primary">
                    <small>Full Name</small>
                  </label>
                </div>
                <div className="form-group col-md-6 mb-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    value={executiveDetails.first_name}
                    onChange={(e) => {
                      this.setExecutiveDetails("first_name", e.target.value);
                      this.checkForAlphabetsOnly("first_name", e.target.value);
                    }}
                  />
                  {executiveDetailsError.first_name && (
                    <div className="error txt-blue-dull">Invalid name</div>
                  )}
                </div>
                <div className="form-group col-md-6 mb-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    value={executiveDetails.last_name}
                    onChange={(e) => {
                      this.setExecutiveDetails("last_name", e.target.value);
                      this.checkForAlphabetsOnly("last_name", e.target.value);
                    }}
                  />
                  {executiveDetailsError.last_name && (
                    <div className="error txt-blue-dull">Invalid name</div>
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6 mb-2">
                  <label className="mb-0 text-primary">
                    <small>Email Address</small>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="example@abc.com"
                    value={executiveDetails.email}
                    onChange={(e) => {
                      this.setExecutiveDetails("email", e.target.value);
                      this.checkForEmail("email", e.target.value);
                    }}
                  />
                  {executiveDetailsError.email && (
                    <div className="error txt-blue-dull">Invalid email</div>
                  )}
                </div>

                <div className="form-group col-md-6 mb-2">
                  <label className="mb-0 text-primary">
                    <small>Mobile Number</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="000-0000-000"
                    value={executiveDetails.mobile_number}
                    maxLength={10}
                    onChange={(e) => {
                      this.setExecutiveDetails("mobile_number", e.target.value);
                      this.checkForNumeric("mobile_number", e.target.value);
                    }}
                  />
                  {executiveDetailsError.mobile_number && (
                    <div className="error txt-blue-dull">
                      Invalid Mobile Number
                    </div>
                  )}
                </div>
              </div>

              <div className="dashed-border-bottom mb-3">&nbsp;</div>
              <button
                type="submit"
                className="btn btn-primary text-uppercase btn-block"
                onClick={this.addExecutive}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  clientInfo: state.login.clientInfo
});
export default connect(mapStateToProps)(AddExecutive)
