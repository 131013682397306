import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PhotoLiveness from "../photoLiveness/PhotoLiveness";
import Questionnaire from "../questionnaire/Questionnaire";
import ScreenShot from "../screenShot/ScreenShot";

import API from "../../apiBaseConfigNew";
import cogoToast from "cogo-toast";
import { _ToNormal } from "../../constants";

const VerificationCardCPV = (props) => {
  const [questionnaire, setQuestionnaire] = useState([]);
  const [photoLive, setPhotoLive] = useState({
    score: null,
    screenShot: null,
    reTryCount: 0,
    checked: null,
  });
  const [faceMatched, setFaceMatched] = useState(true);
  const [screenShot, setScreenShot] = useState(props?.processDoc || []); 

  const handlePhotoLivenessChecked = (photoLiveness) => {
    if (photoLiveness) {
      setPhotoLive({ ...photoLiveness });
    }
  };

 

  function handleScreenshot (screenshot) {
    let temp = [...screenshot];
    setScreenShot([...temp]);
  };


  const docData = props?.docData;
 // this doc will be compareed with face match picture
 const screenshot = props.processDoc
 console.log("screenshots", screenshot);
  const faceMatchDoc = docData && docData.filter((doc) => doc.document_details.doc_key == "facematch_doc")?.[0];
  console.log("faceMatchDoc", faceMatchDoc);
  const selfieDocId = props.userDocsInfo && props.userDocsInfo.filter((doc) => doc.doc_key =="facematch_picture")[0]?.id;
  return (
    <>
      
      <hr className="dashed-brdr brdr-blue" />
       <>
        <div>
          <p>Face Match Document</p>
          <img src={faceMatchDoc?.front_upload} alt="faceMatchDoc" style={{ width: "100%" }} />
        </div>
     
        <PhotoLiveness
          takeScreenShot={props.takeScreenShot}
          selfieDocId={selfieDocId}
          score={photoLive.score}
          reTryCount={photoLive.reTryCount}
          checked={photoLive.checked}
          screenShot={photoLive.screenShot}
          updateRetry={setPhotoLive}
          caseId={props.caseId}
          userId={props.userId}
          connectionId={props.connectionId}
          photoLivenessChecked={handlePhotoLivenessChecked}
          processDocs={props.processDoc}
          isCPV={true}
          doc_id={[faceMatchDoc?.id]}
        />
        </>
        <ScreenShot
            takeScreenShot={props.takeScreenShot}
            connectionId={props.connectionId}
            screenShot={screenshot}
            caseId={props.caseId}
            userId={props.userId}
            handleScreenshot={handleScreenshot}
          />
      {faceMatched  && (
        <Questionnaire
          questionnaire={questionnaire}
          connectionId={props.connectionId}
          processId={props.processId}
          setQuestions={(ques) => {
            setQuestionnaire(ques);
          }}
          userId={props.userId}
          caseId={props.caseId}
        />
      )}
     
    </>
  );
};

const mapStateToProps = (state) => ({
  clientConfig: state.login.clientInfo,
  userDocsInfo: state?.userDocs?.anonymousUserConnectionInfo?.case_details?.process_details?.documents || [],
});

export default connect(mapStateToProps)(VerificationCardCPV);
