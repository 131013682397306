import React from 'react'
import { useSelector } from "react-redux";
import SURFACE_ICON from "../../../../../../assets/icons/surface.svg";
import { UPLOAD_CASES } from "../../../../../../apiurl";
import api from "../../../../../../apiBaseConfig";
import cogoToast from 'cogo-toast';

export default function ExcelUpload({ setLoading }) {
    let clientConfig = useSelector(state => state.login.clientInfo)

    function handleFileInput(e) {
        uploadCases(e.target.files[0])
    };
    function downloadSampleDocument() {
        window.open(clientConfig.sample_case_excel_path);
    };
    async function uploadCases(file) {
        try {
            setLoading(true)
            var formData = new FormData();

            formData.append("file", file);
            const { data } = await api.post(UPLOAD_CASES, formData);
            download("response.csv", data);
            cogoToast.success('Cases uploaded successfully')
            setLoading(false)

        } catch (error) {
            console.log("error in uploading cases");
            setLoading(false)
            cogoToast.error('error in uploading cases')
        }
    };
    function download(filename, text) {
        var pom = document.createElement("a");
        pom.setAttribute(
            "href",
            "data:text/csv;charset=utf-8," + encodeURIComponent(text)
        );
        pom.setAttribute("download", filename);
        pom.click();
    }
    return (
        <div className="row g-0">
            <div className="col-md-10 my-3">
                <h5 className='pageUpload-title'>Module Upload</h5>
            </div>
            <div className="col-md-2 my-3">
                <div className="custom-upload-btn">
                    <label className="custom-file-upload">
                        <input
                            type="file"
                            onChange={handleFileInput}
                            accept={
                                "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            }
                        />
                        <span>
                            <i className="fa fa-cloud-upload-alt me-1"></i> Upload Excel
                        </span>
                    </label>
                </div>
                <div onClick={downloadSampleDocument}>
                    <img src={SURFACE_ICON} className="ms-3" width="10" alt=""></img>
                    <span className="txt-light-blue2 font-italic fnt-sml-x">
                        Template Download
                    </span>
                </div>
            </div>
        </div>
    )
}
