import React from "react";
import Moment from "react-moment";
import { ReactComponent as TransferIcon } from "../../../../../assets/icons/transfer.svg";
import DeleteIcon from "@material-ui/icons/Delete";
import { connect } from "react-redux";

class ReassignedCases extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transferClickedIndex: null,
    };
  }

  handleTransferClick = (index) => {
    let transferClickedIndex = this.state.transferClickedIndex;
    if (index === transferClickedIndex) {
      transferClickedIndex = null;
    } else {
      transferClickedIndex = index;
    }
    this.setState({
      transferClickedIndex: transferClickedIndex,
    });
  };
  render() {
    const { reassignedCasesData, consolidatedAgentList, clientInfo } = this.props;
    const { has_partners } = clientInfo

    const { transferClickedIndex } = this.state;
    return (
      <div
        className="tab-pane show active"
        id="reassigned"
        role="tabpanel"
        aria-labelledby="reassigned-tab"
      >
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <table className="table sml-blue-bg-header bg-sky-blue mb-1">
                  <thead className="all-head-center">
                    <tr>
                      <th width="20%">Case name</th>
                      <th width="20%">Case ID</th>
                      {has_partners ? <th width="10%">Partner</th> : null}
                      <th width="20%" className="text-center">
                        Reassigned Date
                      </th>
                      <th width="20%" className="text-center">
                        Reassigned by
                      </th>
                      <th width="20%" className="text-center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
            {reassignedCasesData.map((reassignedCase, index) => {
              return (
                <div className="row">
                  <div className="col-md-12">
                    <div className="tbl-row">
                      <table className="table mb-0 sml-blue-bg-header table-striped odd-even-tbl roboto-regular">
                        <tbody className="all-body-center pt-7-pb-7-td">
                          <tr>
                            <td width="20%">{reassignedCase.name}</td>
                            <td width="20%">{reassignedCase.id}</td>
                            {has_partners ? <td width="10%">{reassignedCase.partner_name}</td> : null}
                            <td width="20%" align="center">
                              <Moment format="DD MMM YYYY LT">
                                {reassignedCase.assigned_at}
                              </Moment>
                            </td>
                            <td width="20%" align="center">
                              {reassignedCase.reassigned_by_name
                                ? reassignedCase.reassigned_by_name
                                : ""}
                            </td>
                            <td width="20%" align="center">
                              <span
                                className={
                                  transferClickedIndex === index
                                    ? "transfer dropdown dropleft show"
                                    : "transfer dropdown dropleft"
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.handleTransferClick(index);
                                }}
                              >
                                <TransferIcon
                                  className="dropdown-toggle"
                                  id="dropdownMenuButton"
                                  title="Click here to transfer case"
                                  // data-toggle="dropdown"
                                />
                                <div
                                  className={
                                    transferClickedIndex === index
                                      ? "dropdown-menu border-0 transfer-box fnt-sml show"
                                      : "dropdown-menu border-0 transfer-box fnt-sml"
                                  }
                                >
                                  <a
                                    className="dropdown-item ps-2"
                                    href="#"
                                    style={{ textAlign: "left" }}
                                  >
                                    Transfer to:
                                  </a>
                                  <div className="dropdown-divider"></div>
                                  {consolidatedAgentList.length > 0 &&
                                    consolidatedAgentList.map(
                                      (selectedAgent, agentIndex) => {
                                        return (
                                          <a
                                            className="dropdown-item"
                                            href="#"
                                            key={selectedAgent.id}
                                          >
                                            <span
                                              className="light-blue-sml-circle rounded-circle bg-purple text-white"
                                              style={{
                                                width: "10px",
                                                height: "10px",
                                              }}
                                            >
                                              {selectedAgent
                                                ? selectedAgent.first_name.charAt(
                                                    0
                                                  ) +
                                                  selectedAgent.last_name.charAt(
                                                    0
                                                  )
                                                : ""}
                                            </span>
                                            <span
                                              onClick={() =>
                                                this.props.transferCases(
                                                  selectedAgent,
                                                  reassignedCase
                                                )
                                              }
                                            >
                                              {selectedAgent
                                                ? selectedAgent.first_name +
                                                  " " +
                                                  selectedAgent.last_name
                                                : ""}
                                            </span>
                                          </a>
                                        );
                                      }
                                    )}
                                </div>
                              </span>
                              <span style={{ marginLeft: "10px" }}>
                                <DeleteIcon
                                  color="error"
                                  title="Click here to cancel case"
                                  fontSize="small"
                                  onClick={() =>
                                    this.props.handleDeleteCase(reassignedCase)
                                  }
                                />
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  clientInfo: state.login.clientInfo
});
export default connect(mapStateToProps)(ReassignedCases);
