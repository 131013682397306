import React, { useRef, useState } from 'react'
import VideoRecorder from './VideoRecorder'
import './Policy.scss'
import { CHECK_DOC_TYPE } from '../../constants'
import Screenshots from './Screenshots'
import cn from 'classnames'
import { Button } from "@material-ui/core";
import API from '../../apiBaseConfigNew'
import cogoToast from 'cogo-toast'
import Loader from '../../components/loader/Loader'


export default function PIVCPolicy({ user, headers }) {
    const videoRef = useRef()
    const screenShotRef = useRef()
    const [isRecording, setIsRecording] = useState(false);
    const [recordedData, setRecordedData] = useState(null)
    const [isRecorded, setIsRecorded] = useState(false)
    const [loading, setLoading] = useState(false)
    const [videoUpload, setVideoUpload] = useState(false)
    const [screenShot, setScreenshot] = useState(false)
    const [consent, setConsent] = useState(false)
    const [isCameraAccess, setIsCameraAccess] = useState(false)


    function handleCameraBtn() {
        if (videoRef.current) {
            videoRef.current.handleCameraBtn()
        }
    }
    function startCamera() {
        if (videoRef.current) {
            videoRef.current.startCamera()
            setIsRecorded(false)
            setRecordedData(null)
            setVideoUpload(false)
        }

    }
    function onRecordStart() {
        setIsRecording(true)
        setIsRecorded(false)
        cogoToast.info('Please look into the camera a photo will be captured!')
        setTimeout(() => {
            if (screenShotRef.current)
                screenShotRef.current.captureScreenshot()
        }, 3000)
    }
    function onRecordStop(data) {
        setRecordedData(data)
        setIsRecording(false)
        setIsRecorded(true)
    }
    function uploadVideo() {
        let params = {
            pivc_case: user.id,
            ...recordedData
        }
        setLoading(true)
        API.post('/case/pivcmedia-upload/', params)
            .then(res => {
                setLoading(false)
                if (res.status === 200) {
                    cogoToast.success('Video uploaded successfully!')
                    setVideoUpload(true)
                }
            })
            .catch(err => {
                cogoToast.error('Something went wrong while uploading video!')
                setLoading(false)
            })
    }
    function submitCase() {
        setLoading(true)
        API.delete('/case/pivc/session/')
            .then(res => {
                if (res.status === 200) {
                    cogoToast.success('Case Completed!')
                    setTimeout(() => window.location.reload(), 500)
                }
            })
            .catch(err => {
                setLoading(false)
                cogoToast.error('Error while submitting case!')
            })
    }
    return (
        <div className='h-100 p-4'>
            {loading ? <Loader full /> : null}
            <div className='row mt-2 border border-1 brdr-light-blue rounded mb-5 pb-4'>
                <div className='col-md-8'>
                    <div className='policy-left-section'>
                        <div >Customer Name : {user?.customer_name}</div>
                        <div >Application No : {user?.application_number}</div>
                        <div className='row mt-4 ps-3'>
                            Policy Document
                            <div className='col-md-12 mt-2 p-0'>
                                {user?.policy_document ? <>{CHECK_DOC_TYPE(user.policy_document) === 'image' ? <img src={user?.policy_document} /> :
                                    <iframe src={user.policy_document} />}
                                </> : null}
                            </div>
                            <div className='col-md-12 mt-4'>
                                <b className='p-0'>Consent Text</b>
                                <p className=''>{user?.consent_text}</p>
                            </div>
                            {isCameraAccess ? <div className='col-md-12'>
                                <div>
                                    {videoUpload && screenShot ?
                                        <div >
                                            <input
                                                checked={consent}
                                                onChange={e => setConsent(e.target.checked)}
                                                type="checkbox"
                                                className="checkbox-round"
                                                id="term"
                                            />
                                            <label htmlFor='term' className="ms-2 txt-light-black font-weight-bold">
                                                I have checked the details and agree to submit.
                                            </label>
                                        </div> :
                                        <div>
                                            {isRecorded ? <>
                                                {!videoUpload ?
                                                    <Button
                                                        variant="contained"
                                                        onClick={uploadVideo}
                                                        color="secondary" >
                                                        Upload Video
                                                    </Button> : null}
                                                <Button
                                                    variant="contained"
                                                    className='ms-2'
                                                    onClick={startCamera}
                                                    color="secondary" >
                                                    Record Again
                                                </Button>
                                            </> :
                                                <button className='camera-btn' onClick={handleCameraBtn} >  {isRecording ? <span >Stop Rec <div className='record'></div> </span> : <span>Start Rec </span>}</button>
                                            }

                                        </div>

                                    }
                                </div>
                            </div> : null}
                            {!consent && videoUpload && screenShot ? <Button
                                variant="contained"
                                className='ms-2'
                                onClick={startCamera}
                                color="secondary" >
                                Record Again
                            </Button> : consent && videoUpload && screenShot ?
                                <Button
                                    variant="contained"
                                    className='ms-2'
                                    onClick={submitCase}
                                    color="secondary" >
                                    Submit
                                </Button>
                                : null
                            }
                        </div>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='policy-right-section'>
                        <VideoRecorder onCameraAccess={e => setIsCameraAccess(true)} onRecordStop={onRecordStop} onRecordStart={onRecordStart} ref={videoRef} className={cn('camera-item', { 'isrecording': isRecording })} />
                        {isCameraAccess ? <Screenshots ref={screenShotRef} onUpload={e => setScreenshot(true)} /> : null}
                    </div>
                </div>
            </div>
        </div>

    )
}
