import React from "react";

export default class ModuleUploadSuccess extends React.Component {
  render() {
    return (
      <div class="row g-0">
        <div class="col-md-10 my-3">
          <h5 class="ml-30">Module Upload</h5>
        </div>
        <div
          class="col-md-12"
          style={{ textAlign: "center", marginTop: "100px" }}
        >
          <h3>Case successfully uploaded.</h3>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => this.props.setModuleSuccess(false)}
          >
            Click here to add more cases.
          </div>
        </div>
      </div>
    );
  }
}
