
import React, { useEffect, useState } from 'react'
import API from '../../apiBaseConfigNew'
import Each from '../../Each'
import DeleteIcon from "@material-ui/icons/Delete";
import { ReactComponent as TransferIcon } from "../..//assets/icons/transfer.svg";
import GetAppIcon from "@material-ui/icons/GetApp";
import Moment from "react-moment";
import TablePagination from "@material-ui/core/TablePagination";
import NavTabs from './NavTabs';
import cogoToast from 'cogo-toast';
import cn from 'classnames'
import Dropdown from '../../components/Dropdown';
import Loader from '../../components/loader/Loader';
import { Link } from "react-router-dom";
import DocumentIcon from "../../assets/icons/document-icon.png";
import RecordingIcon from "../../assets/icons/recording-icon.png";


const lType = {
    1: "assigned",
    2: "completed",
    3: "audited"
}
const Tabs = [{ name: "Assigned", id: 1 }, { name: "Completed", id: 2 }, { name: "Audited", id: 3 }]

export default function PIVCCases() {
    const [caseList, setCaseList] = useState([])
    const [page, setPage] = useState(0)
    const [total_count, setTotalCount] = useState(0)
    const [rowsPerPage, setRowPerPage] = useState(25)
    const [activeTab, setActiveTab] = useState(1)
    const [transferIndex, setTrasferIndex] = useState(null)
    const [auditorList, setAuditorList] = useState([])
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        getCaseList(lType[1])
        getAuditorList()
    }, [])
    function getCaseList(type) {
        setLoading(true)
        API.get(`/case/pivc-case/?case_type=pivc&status=${type}&page=1`)
            .then(res => {
                let { results, count } = res.data
                setCaseList(results)
                setTotalCount(count)
                setLoading(false)

            })
            .catch(err => {
                console.log(err)
                setLoading(false)

            })
    }
    function getAuditorList() {
        API.get('/user/admin-auditors-list/')
            .then(res => {
                if (res.data) {
                    setAuditorList(res.data)
                }
            })
            .catch(err => console.log(err))
    }
    function handleTabClick(active) {
        setActiveTab(active)
        setTrasferIndex(null)
        getCaseList(lType[active])
    }
    function deletCase(id) {
        setLoading(true)
        API.delete(`/case/pivc-case/${id}/`)
            .then(res => {
                if (res) {
                    setLoading(false)

                    cogoToast.success('Case deleted successfully!')
                    getCaseList(lType[activeTab])
                }
            })
            .catch(err => console.log(err))
    }
    function downLoadMedia(id) {
        setLoading(true)
        API.get(`/case/pivcmedia-upload/?pivc_case=${id}`)
            .then(res => {
                if (res && res.status === 200 && res.data) {
                    setLoading(false)
                    window.open(res.data[0].pivc_video, "_blank")

                }
            })
            .catch(err => console.log(err))
    }
    function downLoadDocuments(id) {
        setLoading(true)
        API.get(`/case/documents-upload/?pivc_case=${id}`)
            .then(res => {
                if (res && res.status === 200 && res.data) {
                    setLoading(false)
                    const documents = res.data.data.map((document) => {
                        return {
                            frontUpload: document.front_upload,
                            backUpload: document.back_upload,
                        };
                    });

                    documents.forEach((document) => {
                        if (document.frontUpload) {
                            window.open(document.frontUpload);
                        }
                        if (document.backUpload) {
                            window.open(document.backUpload);
                        }
                    });

                }
            })
            .catch(err => console.log(err))
    }
    function transferCaseIndex(id) {
        if (transferIndex === id) setTrasferIndex(null)
        else setTrasferIndex(id)
    }
    function transferCase(auditor, caseItem) {
        setLoading(true)
        API.patch(`/case/pivc-case/${caseItem.id}/`, { 'admin_auditor': auditor.id })
            .then(res => {
                if (res.status === 200) {
                    setLoading(false)
                    cogoToast.success('Case Transfered successfully!')
                    getCaseList(lType[activeTab])
                }
            })
            .catch(err => console.log(err))
    }
    function caseStatus(id, status) {
        setLoading(true)
        API.patch(`/case/pivc-case/${id}/`, { 'status': status })
            .then(res => {
                setLoading(false)
                if (res.status === 200) {
                    cogoToast.success('Case successfully ' + status)
                    getCaseList(lType[activeTab])
                }
                debugger
            })
            .catch(err => {
                setLoading(false)
            })

    }
    return (
        <>
            <div className="container-fluid" >
                {loading ? <Loader /> : null}
                <div className='row mt-1'>
                    <NavTabs tabs={Tabs} active={activeTab} onTabClick={handleTabClick} />
                </div>
                <div className="row mt-3">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <table className="table sml-blue-bg-header bg-sky-blue mb-1">
                                    <thead className="all-head-center">
                                        <tr>
                                            <th width="20%">Case name</th>
                                            <th width="10%">Application No</th>

                                            <th width="10%">Case ID</th>
                                            <th width="10%" className="text-center">
                                                Assigned date
                                            </th>
                                            <th width="20%" className="text-center">
                                                Assigned to
                                            </th>
                                            <th width="10%" className="text-center">
                                                Case Tag
                                            </th>
                                            <th width="30%" className="text-center">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
                <Each of={caseList} render={(item, index) => {
                    return <div className="row">
                        <div className="col-md-12">
                            <div className="tbl-row">
                                <table className="table mb-0 sml-blue-bg-header table-striped odd-even-tbl roboto-regular">
                                    <tbody className="all-body-center pt-7-pb-7-td">
                                        <tr>
                                            <td width="20%">{item.customer_name}</td>
                                            <td width="10%">{item.application_number}</td>
                                            <td width="10%">{item.id}</td>
                                            <td width="10%" align="center">
                                                <Moment format="DD MMM YYYY LT">
                                                    {item.created_at}
                                                </Moment>
                                            </td>
                                            <td width="20%" align="center">
                                                {item.admin_auditor_name}
                                            </td>
                                            <td width="10%" align="center">{item.case_tag}</td>
                                            <td width="30%" align="center">
                                                {activeTab === 1 ? <>
                                                    <span style={{ display: 'inline-block' }}>
                                                        <TransferIcon
                                                            color="info"
                                                            fontSize="small"
                                                            className="cursor-pointer"
                                                            title="Click here to transfer case"
                                                            onClick={() => transferCaseIndex(index)}
                                                        />
                                                        <Dropdown isToggle isActive={transferIndex === index}>
                                                            <li className='option-disabled'>Transfer To :</li>
                                                            {transferIndex === index && auditorList.length > 0 ?
                                                                auditorList.map((auditor) =>
                                                                    auditor.id == item.admin_auditor ? null : (
                                                                        <li onClick={e => transferCase(auditor, item)} className="option"> {auditor
                                                                            ? auditor.first_name +
                                                                            " " +
                                                                            auditor.last_name
                                                                            : ""}</li>

                                                                    )
                                                                ) : null}

                                                        </Dropdown>

                                                    </span>
                                                    <span style={{ marginLeft: "10px" }}>
                                                        <DeleteIcon
                                                            color="error"
                                                            title="Click here to cancel case"
                                                            fontSize="small"
                                                            onClick={() => deletCase(item.id)}
                                                            className='cursor-pointer'
                                                        />
                                                    </span>
                                                </> : activeTab === 2 || activeTab === 3 ? <>
                                                    <span className='cursor-pointer'>
                                                        <img
                                                            src={RecordingIcon}
                                                            onClick={() => downLoadMedia(item.id)}
                                                        />
                                                    </span>
                                                    <span className='ms-2 cursor-pointer' >
                                                        <img
                                                            src={DocumentIcon}
                                                            onClick={() => downLoadDocuments(item.id)
                                                            }
                                                        />
                                                    </span>
                                                   {item.audio_text_similarity_score ? <span className='ms-2 cursor-pointer'>
                                                        <Link
                                                            style={{ textDecoration: "none" }}
                                                            to={`/pivc/report/` + item.id}
                                                            target="_blank"
                                                        >
                                                            <GetAppIcon src={DocumentIcon} />
                                                        </Link>
                                                    </span> : null }
                                                    {activeTab === 2 ? <span className='ms-2'>
                                                        <Dropdown className='d-inline-block'>
                                                            <li onClick={e => caseStatus(item.id, 'audited')} className='option'>Approve</li>
                                                            <li onClick={e => caseStatus(item.id, 'rejected')} className='option'>Reject</li>
                                                            <li onClick={e => caseStatus(item.id, 'assigned')} className='option'>Rework</li>
                                                        </Dropdown>
                                                    </span> : null}
                                                </> : null}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }} />
                <TablePagination
                    count={total_count || 0}
                    page={page || 0}
                    classes={{ root: "pagination-border" }}
                    rowsPerPageOptions={[rowsPerPage]}
                    rowsPerPage={rowsPerPage}
                    onChangePage={e => { }}
                />
            </div>
        </>
    )
}
