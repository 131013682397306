import React from "react";
import VerificationInput from "../verificationInput/VerificationInput";
import { CASE_HISTORY } from "../../apiurl";
import api from "../../apiBaseConfig";

class AadhaarCardDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aadhaarDetailsArray: [],
      enlarge: false,
      showAadhaarFront: true,
    };
  }

  componentDidMount() {
    const { aadhaarData, defaultValue, defaultValueDigilocker } = this.props;
    const data =
      aadhaarData.document_upload_type === "digilocker_upload"
        ? defaultValueDigilocker
        : defaultValue;
    this.setState({
      ...this.state,
      aadhaarDetailsArray: [
        {
          preInput: "Name",
          defaultValue: data ? data.name : "",
        },
        {
          preInput: "Gender",
          defaultValue: data ? data.gender : "",
        },
        {
          preInput: "Address",
          defaultValue: data ? data.address : "",
        },
        {
          preInput: data.yob ? "YOB" : "DOB",
          defaultValue: data.yob ? data.yob : data.dob,
        },
      ],
    });
  }

  handleIsCheckedForAadhaar = (value, index) => {
    let aadhaarCardCheckedArray = [...this.props.aadhaarCardCheckedArray];
    value.type = this.state.aadhaarDetailsArray[index].preInput;
    aadhaarCardCheckedArray[index] = value;
    if (this.props.aadhaarData.document_upload_type === "digilocker_upload") {
      aadhaarCardCheckedArray = [{ ...value, type: "Verify Aadhaar Card" }];
    }
    this.submitComment(
      `Aadhaar Card Details - ${
        aadhaarCardCheckedArray[index].type
      } - ${JSON.stringify(value)}`
    );
    this.props.handleCheckArray(aadhaarCardCheckedArray);
  };

  submitComment = async (comment) => {
    const caseId = this.props.caseId;
    const userId = this.props.userId;
    const body = {
      case: caseId,
      user: userId,
      type: "Action",
      re_text: comment,
    };
    try {
      const res = await api.post(CASE_HISTORY, body);
    } catch (e) {
      console.log(e);
    }
  };

  toggleImage = () => {
    const { showAadhaarFront } = this.state;
    this.setState({ showAadhaarFront: !showAadhaarFront });
  };

  render() {
    const {
      aadhaarImage,
      nextDisabled,
      aadhaarCardCheckedArray,
      aadhaarImageBack,
      CaseDetails,
      clientConfig
    } = this.props;
    const { show_case_details } = clientConfig
    const { aadhaarDetailsArray, enlarge, showAadhaarFront } = this.state;
    return (
      <React.Fragment>
        <div className="border rounded p-3 bg-f9">
          <h6 className="txt-light-blue dashed-title mb-3 ltr-spc-1">
            Aadhaar Verification
          </h6>
          {show_case_details ? <> {CaseDetails}</> : null}
          <div className="ms-5 me-5 p-2 text-center bg-e1 rounded mb-3">
            <img
              src={showAadhaarFront ? aadhaarImage : aadhaarImageBack}
              onMouseEnter={() => this.setState({ enlarge: true })}
              onMouseLeave={() => this.setState({ enlarge: false })}
              className="img-fluid rounded"
              alt=""
            />
            {enlarge && (
              <img
                src={showAadhaarFront ? aadhaarImage : aadhaarImageBack}
                className="rounded border doc-enlarge"
                alt=""
              />
            )}
            <button
              className="aadhaarVerificationCardDetails"
              onClick={this.toggleImage}
            >
              &#8249;
            </button>
            <button
              className="aadhaarVerificationCardDetails"
              onClick={this.toggleImage}
            >
              &#8250;
            </button>
          </div>

          {this.props.aadhaarData.document_upload_type === "ocr_upload" ||
          this.props.aadhaarData.document_upload_type === "normal_upload" ? (
            aadhaarDetailsArray.map((aadhaarCardInput, key) => {
              return (
                <VerificationInput
                  preInput={aadhaarCardInput.preInput}
                  inputValueDefault={aadhaarCardInput.defaultValue}
                  handleIsChecked={this.handleIsCheckedForAadhaar}
                  isTick={
                    aadhaarCardCheckedArray
                      ? aadhaarCardCheckedArray[key].isTick
                      : false
                  }
                  isCancel={
                    aadhaarCardCheckedArray
                      ? aadhaarCardCheckedArray[key].isCancel
                      : false
                  }
                  inputKey={key}
                  readOnly={true}
                />
              );
            })
          ) : this.props.aadhaarData.document_upload_type ===
              "digilocker_upload" &&
            Object.keys(this.props.aadhaarData.corrected_ocr.digilocker_data)
              .length !== 0 ? (
            <>
              {aadhaarDetailsArray.map((aadhaarCardInput, key) => {
                return (
                  <VerificationInput
                    preInput={aadhaarCardInput.preInput}
                    inputValueDefault={aadhaarCardInput.defaultValue}
                    inputKey={key}
                    readOnly={true}
                    digilockerData={true}
                  />
                );
              })}
              <div className="row mt-2">
                <div className="d-flex flex-fill">
                  <p className="txt-70 ms-2">Verified</p>
                  <div className="col d-flex justify-content-end align-items-center">
                    <div className="custom-radio d-flex justify-content-between">
                      <input
                        type="radio"
                        style={{ appearance: "none" }}
                        defaultChecked={
                          aadhaarCardCheckedArray[0].isTick === true
                        }
                        onChange={(e) =>
                          this.handleIsCheckedForAadhaar(
                            { isTick: true, isCancel: false },
                            0
                          )
                        }
                        name="dob"
                        id="dobTrue"
                      />
                      <label for="dobTrue" className="me-2"></label>

                      <input
                        type="radio"
                        name="dob"
                        id="dobFalse"
                        defaultChecked={
                          aadhaarCardCheckedArray[0].isCancel === true
                        }
                        style={{ appearance: "none" }}
                        onChange={(e) =>
                          this.handleIsCheckedForAadhaar(
                            { isTick: false, isCancel: true },
                            0
                          )
                        }
                      />
                      <label for="dobFalse" className="cancel-icon"></label>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="row mt-2">
              <div className="d-flex flex-fill">
                <p className="txt-70 ms-2">Verified</p>
                <div className="col d-flex justify-content-end align-items-center">
                  <div className="custom-radio d-flex justify-content-between">
                    <input
                      type="radio"
                      style={{ appearance: "none" }}
                      defaultChecked={
                        aadhaarCardCheckedArray[0].isTick === true
                      }
                      onChange={(e) =>
                        this.handleIsCheckedForAadhaar(
                          { isTick: true, isCancel: false },
                          0
                        )
                      }
                      name="dob"
                      id="dobTrue"
                    />
                    <label for="dobTrue" className="me-2"></label>

                    <input
                      type="radio"
                      name="dob"
                      id="dobFalse"
                      defaultChecked={
                        aadhaarCardCheckedArray[0].isCancel === true
                      }
                      style={{ appearance: "none" }}
                      onChange={(e) =>
                        this.handleIsCheckedForAadhaar(
                          { isTick: false, isCancel: true },
                          0
                        )
                      }
                    />
                    <label for="dobFalse" className="cancel-icon"></label>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!nextDisabled && (
            <div className="success-msg d-flex align-items-center mt-2">
              <span className="tick-icon-green-sml me-1"></span>
              <span className="txt-green fnt-sml">
                <i>AADHAAR card has been successfully verified</i>
              </span>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default AadhaarCardDetails;
