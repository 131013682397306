import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

class PieChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {},
    };
  }

  componentDidMount() {
    this.setOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.activeCases !== this.props.activeCases ||
      prevProps.completedCases !== this.props.completedCases ||
      prevProps.deletedCases !== this.props.deletedCases ||
      prevProps.approvedCases !== this.props.approvedCases ||
      prevProps.rejectedCases !== this.props.rejectedCases
    ) {
      this.setOptions();
    }
  }
  setOptions = () => {
    const {
      activeCases,
      completedCases,
      deletedCases,
      approvedCases,
      rejectedCases,
    } = this.props;
    const options = {
      chart: {
        type: "pie",
        height: 150,
        width: 330,
      },

      title: {
        style: { display: "none" },
      },

      plotOptions: {
        pie: {
          size: 145,
          innerSize: 50,
          colors: ["#217dc3", "#ff8c00", "#ffc0Ccb", "#008000", "#ffc0cb"],
          dataLabels: {
            distance: "-20%",
          },
          allowPointSelect: false,
          cursor: "pointer",
          enableMouseTracking: false,
        },
      },

      series: [
        {
          data: [
            [`${activeCases}`, activeCases],
            [`${completedCases}`, completedCases],
            [`${deletedCases}`, deletedCases],
            [`${approvedCases}`, approvedCases],
            [`${rejectedCases}`, rejectedCases],
          ],
        },
      ],

      credits: {
        enabled: false,
      },
    };

    this.setState({ options: options });
  };
  render() {
    const { options } = this.state;
    const {
      activeCases,
      completedCases,
      deletedCases,
      approvedCases,
      rejectedCases,
    } = this.props;
    return (
      <div className="row">
        <div className="card" style={{ height: "300px"}}>
          <div className="card-body bg-fc" style={ {width:"330px"}}>
            <div
              className="p-5 d-flex align-items-center justify-content-center"
              style={{ height: "100px",marginTop:"25px",marginBottom:"29px"}}
            >
              <div className="chartsize">
                {Object.keys(options).length !== 0 && (
                  <HighchartsReact highcharts={Highcharts} options={options} />
                )}
              </div>
            </div>
            <div className="p-4" style={{marginTop:"40px",fontSize:"8px"}}>
              <div className="d-flex align-items-center justify-content-end border-bottom-dashed-gray">
                <div className="w-100">
                  <span
                    className="blue-chart-circle me-3"
                    style={{ backgroundColor: "#217dc3" }}
                  ></span>
                  <span className="poppins-medium fnt-sml txt-666 ltr-spc-1">
                    Active Cases
                  </span>
                </div>
                <div className="txt-666 roboto-medium" style={{ fontSize: "10px" }}>
                  {activeCases}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end border-bottom-dashed-gray">
                <div className="w-100">
                  <span
                    className="green-chart-circle me-3"
                    style={{ backgroundColor: "#ff8c00" }}
                  ></span>
                  <span className="poppins-medium fnt-sml txt-666 ltr-spc-1">
                  Completed
                  </span>
                </div>
                <div className="txt-666 roboto-medium" style={{ fontSize: "10px" }}>
                  {completedCases}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end border-bottom-dashed-gray">
                <div className="w-100">
                  <span
                    className="red-chart-circle me-3"
                    style={{ backgroundColor: "#ffc0Ccb" }}
                  ></span>
                  <span className="poppins-medium fnt-sml txt-666 ltr-spc-1">
                    Deleted
                  </span>
                </div>
                <div className="txt-666 roboto-medium" style={{ fontSize: "10px" }}>
                  {deletedCases}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end border-bottom-dashed-gray">
                <div className="w-100">
                  <span
                    className="red-chart-circle me-3"
                    style={{ backgroundColor: "#008000" }}
                  ></span>
                  <span className="poppins-medium fnt-sml txt-666 ltr-spc-1">
                    Approved
                  </span>
                </div>
                <div className="txt-666 roboto-medium" style={{ fontSize: "10px" }}>
                  {approvedCases}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end border-bottom-dashed-gray">
                <div className="w-100">
                  <span
                    className="red-chart-circle me-3"
                    style={{ backgroundColor: "#ffc0cb" }}
                  ></span>
                  <span className="poppins-medium fnt-sml txt-666 ltr-spc-1">
                    Rejected
                  </span>
                </div>
                <div className="txt-666 roboto-medium" style={{ fontSize: "10px" }}>
                  {rejectedCases}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PieChart;
