import React from 'react';
import Logo from '../../assets/images/auth-logo.png'
function PrivacyPolicyFooter(){
    const url = process.env.REACT_APP_BASE_API_URL.slice(0,-3) + "privacy";
    return(
        <div class="footer">
        <div className='ms-2'>
          <a href={url} target="_blank">
            Privacy Notice
          </a>
        </div>
        <div className='footer-power'>
        <span>Powered By : </span>
          <img src={Logo} className='me-5' alt=""></img>
        </div>
        <div>
          </div>
      </div>
    )
}

export default PrivacyPolicyFooter;