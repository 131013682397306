import React from "react";
import UserHeader from "../../userHeader/userHeader";
import DescriptionIcon from "@material-ui/icons/Description";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ReactComponent as TickUnselected } from "../../../assets/icons/tick_unselected.svg";
import { ReactComponent as AadhaarIcon } from "../../../assets/icons/aadhaar.svg";
import "./AadhaarConfirmation.scss";

class AadhaarConfirmation extends React.Component {
  componentDidMount() {
    this.props.IsAadhaarUploaded({ aadhaarUploaded: true });
  }
  render() {
    return (
      <div className="wrapper light-blue-customer-template">
        <UserHeader />
        <section className="main-body m-auto customer-flow-section">
          <div className="container-fluid mt-4 position-relative">
            <div className="row">
              <div className="col-md-12">
                <h4 className="txt-light-blue ltr-spc-1">
                  Aadhaar Card Upload Successful
                </h4>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="white-box mt-4 p-5-15 dashed-brdr brdr-green">
                  <div className="left float-left d-flex align-items-center">
                    <span className="xml-icon icon-light-blue">
                      <AadhaarIcon />
                    </span>
                    <span className="txt-light-blue fnt-sml ms-3 ltr-spc-0-5">
                      Aadhaar photos uploaded
                    </span>
                  </div>
                  <div className="right float-left d-flex align-items-center justify-content-end">
                    <span className="tick-icon icon-green2 mt-2">
                      <TickUnselected />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="success-msg d-flex align-items-center mt-3">
                  <span className="me-1">
                    <CircularProgress />
                  </span>
                  <span className="txt-green fnt-sml">
                    <i>Aadhaar information is being uploaded</i>
                  </span>
                </div>
                <p className="mt-4">
                  {/* <span className="ltr-spc-1 txt-light-black">A unique Customer ID has been generated</span> */}
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default AadhaarConfirmation;
