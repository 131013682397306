import React from "react";
import ModuleDetails from "../moduleDetails/ModuleDetails";
import { Toast } from "../../../../../utils/toastutils";
import api from "../../../../../apiBaseConfig";
import down from "../../../../../assets/icons/down-arrow.svg";
import { GET_CASES } from "../../../../../apiurl";
import { connect } from "react-redux";
import Loader from "../../../../../components/loader/Loader";

class ModuleList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleArray: [],
      errorArray: [],
      loading:false
    };
  }
  componentDidMount() {
    const { moduleListData } = this.props;
    let toggleArray = new Array(moduleListData.length).fill(false);
    let errorArray = new Array(moduleListData.length).fill(false);
    this.setState({ toggleArray: toggleArray, errorArray: errorArray });
  }
  addNewModule = () => {
    this.props.setShowModuleList(false);
  };
  toggleButton = (index) => {
    const toggleArray = this.state.toggleArray;
    toggleArray[index] = !toggleArray[index];
    this.setState({ toggleArray: toggleArray });
  };
  submitModuleList = async () => {
    const { errorArray } = this.state;
    let hasError = false;
    this.setState({loading:true})
    for (var i in errorArray) {
      if (errorArray[i]) {
        hasError = true;
      }
    }
    if (hasError) {
      this.setState({loading:false})
      Toast.error({
        description: "Please provide valid inputs.",
        title: "Invalid inputs",
      });
    } else {
      let bulkCasesData = this.getBulkCasesData();
      try {
        let res = await api.post(GET_CASES, bulkCasesData);
        this.setState({loading:false})
        if (res && res.hasOwnProperty('data')) {
          this.props.setModuleSuccess(true);
        }
      } catch (err) {
        this.setState({loading:false})
        Toast.error({
          description: "Please provide valid inputs.",
          title: "Error in case addition",
        });
        this.props.updateModuleListData(err.response.data);
        this.props.setShowModuleList(true);
      }
    }
  };

  getBulkCasesData = () => {
    const { moduleListData } = this.props;
    let bulkCasesData = [];
    moduleListData.forEach(item=>{
      item.client = this.props.clientInfo.client;
      bulkCasesData.push(item)
    })
    return bulkCasesData;
  };

  deleteCase = (index) => {
    this.props.deleteCase(index);
  };

  updateErrorArray = (val, index) => {
    let errorArray = this.state.errorArray;
    errorArray[index] = val;
    this.setState({ errorArray: errorArray });
  };
  render() {
    const { toggleArray, loading } = this.state;
    const {
      moduleListData,
      agentList,
      agentAuditorList,
      errorData,
      deleteModuleListData,
    } = this.props;

    return (
      <React.Fragment>
        {loading ? <Loader /> : null}
        <div className="upload-excel-form-listing-cntr ml-30 mb-3">
          <div id="accordion">
            {moduleListData &&
              moduleListData.map((value, index) => {
                return (
                  <div className="card">
                    <div
                      className="card-header"
                      // id="headingOne"
                    >
                      <h5 className="mb-0">
                        <div className="candidate-record-box">
                          <div className="record record1">
                            <label>Case Name:</label>
                            <span>{value.name}</span>
                          </div>
                          <div className="record record2">
                            <label>Process:</label>
                            <span>
                              {value.case_type === "video_kyc" ? "VKYC" : "VPD"}
                            </span>
                          </div>
                          <div className="record record3">
                            <label>
                              {value.case_type === "video_kyc"
                                ? "Customer :"
                                : "SPOC :"}
                            </label>
                            {value.case_type === "video_kyc" ? (
                              <span>
                                {value.connection_detail
                                  ? value.connection_detail[0].name
                                  : ""}
                              </span>
                            ) : (
                              <select className="form-control" id="">
                                {value.connection_detail.map(
                                  (connection, connectionKey) => {
                                    return (
                                      <option
                                        key={connectionKey}
                                        selected={
                                          connection.name ===
                                          value.connection_detail[
                                            value.connection_detail.length - 1
                                          ].name
                                            ? true
                                            : false
                                        }
                                      >
                                        {connection.name}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                            )}
                          </div>
                          <div className="record record4">
                            <label>Agent:</label>
                            <select
                              className="form-control"
                              id=""
                              // value={value.agent}
                              onChange={(e) =>
                                this.props.changeAgent(e.target.value, index)
                              }
                            >
                              {value.case_type === "video_kyc"
                                ? agentList.map((agent, key) => {
                                    return (
                                      <option
                                        key={key}
                                        selected={
                                          value.agent === agent.id
                                            ? true
                                            : false
                                        }
                                      >
                                        {agent.name}
                                      </option>
                                    );
                                  })
                                : agentAuditorList.map((agentAuditor, key) => {
                                    return (
                                      <option
                                        key={key}
                                        selected={
                                          value.agent === agentAuditor.id
                                            ? true
                                            : false
                                        }
                                      >
                                        {agentAuditor.name}
                                      </option>
                                    );
                                  })}
                            </select>
                          </div>
                        </div>
                        <button
                          className={
                            toggleArray[index]
                              ? "btn btn-link"
                              : "btn btn-link collapsed"
                          }
                          data-bs-toggle="collapse"
                          aria-expanded={toggleArray[index]}
                          aria-controls="collapseOne"
                          onClick={() => this.toggleButton(index)}
                          style={{
                            fontSize: toggleArray[index] ? "1.2rem" : "0.8rem",
                            fontWeight: "bolder",
                          }}
                        >
                          {toggleArray[index] ? (
                            <img src={down} width="10" alt=""></img>
                          ) : (
                            <img
                              src={down}
                              width="10"
                              alt=""
                              style={{ transform: "rotate(180deg)" }}
                            ></img>
                          )}
                        </button>
                        <button
                          style={{ marginLeft: "5px" }}
                          className="btn"
                          onClick={() => this.deleteCase(index)}
                        >
                          X
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapseOne"
                      className={
                        toggleArray[index] ? "collapse show" : "collapse"
                      }
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordion"
                    >
                      <div className="card-body">
                        <ModuleDetails
                          primaryClassname={"upload-excel-form-cntr"}
                          showButtons={false}
                          moduleDetails={value}
                          listed={true}
                          index={index}
                          updateErrorArray={this.updateErrorArray}
                          updateModuleListData={this.props.updateModuleListData}
                          updateModuleListDataExisting={
                            this.props.updateModuleListDataExisting
                          }
                         

                        />
                        <div className="d-flex justify-content-center">
                          <button
                            type="submit"
                            className="btn btn-primary my-4 btn-sm"
                            onClick={() => {
                              this.toggleButton(index);
                              deleteModuleListData(index);
                            }}
                            style={{ marginRight: "10px" }}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                    {errorData.length > 0 && errorData[index] && (
                      <div className="error txt-blue-dull">
                        {typeof errorData[index] === "string"
                          ? errorData[index]
                          : "Please provide valid inputs"}
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
        <div className="col-md-12 text-center" style={{ marginBottom: "50px" }}>
          <button
            type="submit"
            className="btn btn-primary my-4 btn-sm"
            onClick={this.addNewModule}
            style={{ marginRight: "10px" }}
          >
            Add New
          </button>
          <button
            type="submit"
            className="btn btn-primary my-4 btn-sm"
            onClick={this.submitModuleList}
            disabled={this.state.loading}
          >
            Submit
          </button>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  clientInfo:state.login.clientInfo
});
export default connect(mapStateToProps)(ModuleList)
