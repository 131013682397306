import React, { useState, useEffect } from 'react'
import { ReactComponent as VideoIcon } from "../../assets/icons/video-icon.svg";
import lock from "../../assets/icons/lock.png";
import selfie from "../../assets/icons/selfie.png";
import user from "../../assets/icons/user.png";
import { useDispatch, useSelector } from 'react-redux'
import { ActionTypes } from '../../user/state/user.types'


function LoginHome(props) {
	const [otp, setOtp] = useState('')
	const [username, setUserName] = useState('')
	const [password, setPassword] = useState('')
	const [resendTime, setResendTime] = useState(0)

	let anonymousUserConnectionInfo = useSelector(state => state.userDocs.anonymousUserConnectionInfo)
	const dispatch = useDispatch();
	React.useEffect(() => {
		anonymousUserConnectionInfo.connection = null
		dispatch({ type: ActionTypes.STORE_USER_CONNECTION_INFO, payload: anonymousUserConnectionInfo })
	}, [])
	function handleSubmit(e) {
		props.handleSubmit(e, username, password)
	}
	function handleResend(e) {
		setResendTime(120)
		props.handleSubmit(e, username, password)
	}
	useEffect(() => {
		const timer = setTimeout(() => {
			setResendTime(old => {
				if (old <= 0) return 0
				else return --old
			})
		}
			, 1000)
		return () => {
			clearTimeout(timer)
		}
	}, [resendTime])

	useEffect(() => {
		setResendTime(120)
	}, [props.otpSent])

	return (
		<div className="new-login-design">
			<img src={selfie} className="selfie-img" alt="" />
			<div className="login-wrapper">
				<div className="overlap-login-cntr">
					<div className="row mb-3">
						<div className="col">
							<h4 className="welcome-msg">Welcome User</h4>
						</div>
						<div className="col">
							<VideoIcon className="client-img float-end" fill="#60c1f1" />
						</div>
					</div>
					{props.otpSent ? <>
						<div className="row">
							<div className="col">
								<form>
									<div className="form-group">
										<label>Enter Otp Sent to Email</label>
										<div className="input-group">
												<span className="input-group-text"><img src={lock} /></span>
											<input type="text" maxLength={6} value={otp} onChange={e => setOtp(e.target.value)} className="form-control form-control-lg" placeholder="Enter your 6 digit OTP" aria-describedby="" required />

										</div>
										<button className='btn btn-link p-0 fnt-sml' onClick={props.goBack}><span className='fa fa-arrow-left' /> Go Back</button>
										<button disabled={resendTime > 0} className='btn btn-link p-0 fnt-sml ms-2' onClick={e => handleResend(e)}>Resend {resendTime > 0 ? <span>after {resendTime} seconds</span> : null}</button>

									</div>

									<button type="submit" disabled={otp.length < 6} onClick={e => props.submitOtp(e, otp, username)} className="btn btn-primary text-uppercase login-btn">Submit</button>
								</form>

							</div>
						</div>
					</> : <div className="row">
						<div className="col">
							<form>
								<div className="form-group">
									<label className='mb-2'>Username</label>
									<div className="input-group">
										<span className="input-group-text"><img src={user} /></span>
										<input type="email" value={username} onChange={e => setUserName(e.target.value)} className="form-control form-control-lg" id="" placeholder="Enter your username" aria-describedby="" required />
									</div>
								</div>
								<div className="form-group mt-3">
									<label className='mb-2'>Password</label>
									<div className="input-group">
										<span className="input-group-text"><img src={lock} /></span>
										<input type="password" value={password} onChange={e => setPassword(e.target.value)} className="form-control form-control-lg" id="" placeholder="Enter your password" aria-describedby="" required />
									</div>
								</div>
								<button type="submit" onClick={e => handleSubmit(e)} className="btn btn-primary text-uppercase login-btn">Log In</button>
							</form>
							<div className="text-center my-3">
								<a href="#" onClick={props.forgotPassword} className="forgot-pwd"><small className="font-weight-bold text-dark">Forgot Password ?</small></a>
							</div>
						</div>
					</div>}
				</div>

			</div>

		</div>

	)
}

export default LoginHome