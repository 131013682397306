import React from "react";
import { ReactComponent as TransferIcon } from "../../../../../assets/icons/transfer.svg";
import Moment from "react-moment";
import SearchAndSort from "../searchAndSort/SearchAndSort";
import SearchBar from "../searchBar/SearchBar";
class CancelledCases extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transferClickedIndex: null,
      showSearchBar: false,
    };
  }

  handleTransferClick = (index) => {
    let transferClickedIndex = this.state.transferClickedIndex;
    if (index === transferClickedIndex) {
      transferClickedIndex = null;
    } else {
      transferClickedIndex = index;
    }
    this.setState({
      transferClickedIndex: transferClickedIndex,
    });
  };

  toggleSearchClick = () => {
    const { showSearchBar } = this.state;
    this.setState({ showSearchBar: !showSearchBar });
  };
  render() {
    const { cancelledCasesData, agentAuditorList, searchKeyObject } =
      this.props;
    const { transferClickedIndex, showSearchBar } = this.state;
    return (
      <div className="col-md-12">
        <div className="ml-30 mr-30 mt-3">
          <SearchAndSort
            toggleSearchClick={this.toggleSearchClick}
            handleSort={this.props.handleSort}
            getSearchObject={this.props.getSearchObject}
            searchKeyObject={searchKeyObject}
          />
          {showSearchBar ? (
            <SearchBar
              datePrefix={"Cancelled"}
              getSearchObject={this.props.getSearchObject}
              searchKeyObject={searchKeyObject}
            />
          ) : (
            <div className="row mt-1">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <table className="table sml-blue-bg-header bg-sky-blue mb-1">
                      <thead className="all-head-center">
                        <tr>
                          <th width="14%">Case name</th>
                          <th width="14%">Case ID</th>
                          <th width="14%" className="text-center">
                            Document list
                          </th>
                          <th width="14%" className="text-center">
                            Cancelled on
                          </th>
                          <th width="14%" className="text-center">
                            Cancelled by
                          </th>
                          <th width="20%" className="text-start">
                            Cancelling Reason
                          </th>
                          <th width="10%" className="text-center">
                            Actions
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
                {cancelledCasesData.map((cancelledCase, index) => {
                  <div className="row">
                    <div className="col-md-12">
                      <div className="tbl-row">
                        <table className="table mb-0 sml-blue-bg-header table-striped odd-even-tbl roboto-regular">
                          <tbody className="all-body-center pt-7-pb-7-td">
                            <tr>
                              <td width="14%">{cancelledCase.name}</td>
                              <td width="14%">{cancelledCase.id}</td>
                              <td width="14%" align="center">
                                <span
                                  className="txt-light-blue font-italic"
                                  onClick={() =>
                                    this.props.handleViewDocuments(
                                      cancelledCase
                                    )
                                  }
                                >
                                  <u style={{ cursor: "pointer" }}>
                                    Document list
                                  </u>
                                </span>
                              </td>
                              <td width="14%" align="center">
                                {cancelledCase.cancellation_requested_at ? (
                                  <Moment format="DD MMM YYYY LT">
                                    {cancelledCase.cancellation_requested_at}
                                  </Moment>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td width="14%" align="center">
                                {cancelledCase.cancellation_requested_by
                                  ? cancelledCase.cancellation_requested_by
                                  : ""}
                              </td>
                              <td width="20%" className="text-start">
                                {cancelledCase.cancellation_reason
                                  ? cancelledCase.cancellation_reason
                                  : ""}
                              </td>
                              <td
                                width="10%"
                                align="center"
                                className="gray-circle-btns bg-trns-menu-blue"
                              >
                                <span
                                  className={
                                    transferClickedIndex === index
                                      ? "transfer dropdown dropleft show"
                                      : "transfer dropdown dropleft"
                                  }
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.handleTransferClick(index);
                                  }}
                                >
                                  <TransferIcon
                                    className="dropdown-toggle"
                                    id="dropdownMenuButton"
                                    title="Click here to transfer case"
                                    // data-toggle="dropdown"
                                  />
                                  <div
                                    className={
                                      transferClickedIndex === index
                                        ? "dropdown-menu border-0 transfer-box fnt-sml show"
                                        : "dropdown-menu border-0 transfer-box fnt-sml"
                                    }
                                  >
                                    <a
                                      className="dropdown-item ps-2"
                                      href="#"
                                      style={{ textAlign: "left" }}
                                    >
                                      Transfer to:
                                    </a>
                                    <div className="dropdown-divider"></div>
                                    {agentAuditorList.length > 0 &&
                                      agentAuditorList.map(
                                        (selectedAgent, agentIndex) => {
                                          return (
                                            <a
                                              className="dropdown-item"
                                              href="#"
                                              key={selectedAgent.id}
                                            >
                                              <span
                                                className="light-blue-sml-circle rounded-circle bg-purple text-white"
                                                style={{
                                                  width: "10px",
                                                  height: "10px",
                                                }}
                                              >
                                                {selectedAgent
                                                  ? selectedAgent.first_name.charAt(
                                                      0
                                                    ) +
                                                    selectedAgent.last_name.charAt(
                                                      0
                                                    )
                                                  : ""}
                                              </span>
                                              <span
                                                onClick={() =>
                                                  this.props.transferCases(
                                                    selectedAgent.id,
                                                    cancelledCase.id
                                                  )
                                                }
                                              >
                                                {selectedAgent
                                                  ? selectedAgent.first_name +
                                                    " " +
                                                    selectedAgent.last_name
                                                  : ""}
                                              </span>
                                            </a>
                                          );
                                        }
                                      )}
                                  </div>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>;
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CancelledCases;
