import React from "react";
import VerificationInput from "../verificationInput/VerificationInput";
import { GENERATE_UNIQUE_CODE } from "../../apiurl";
import api from "../../apiBaseConfig";

class UniqueCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uniqueCode: "",
      uniqueCodeID: null,
    };
  }
  componentDidMount() {
    this.generateUniqueCode();
  }

  generateUniqueCode = async () => {
    let response = await api.get(
      GENERATE_UNIQUE_CODE + `?connection=${this.props.connectionId}`
    );
    if (response.data[0]) {
      this.setState({
        uniqueCode: response.data[0].unique_code,
        uniqueCodeID: response.data[0].id,
      });
    }
  };
  handleIsChecked = async (event) => {
    let isUniqueCodeVerified = false;
    isUniqueCodeVerified = await this.verifyUniqueCode(event);
    if (isUniqueCodeVerified) {
      this.props.handleUniqueCodeChecked(event);
    }
    else this.props.handleUniqueCodeChecked(event)
  };

  verifyUniqueCode = async (event) => {
    let isUniqueCodeVerified = false;
    const { uniqueCodeID } = this.state;
    try {
      let resp = await api.patch(GENERATE_UNIQUE_CODE + `/${uniqueCodeID}/`, {
        is_unique_code_verified: event.isTick,
      });
      isUniqueCodeVerified = resp.status === 200 ? event.isTick : false;
    } catch (error) {
      console.log(error);
      isUniqueCodeVerified = false;
    }
    return isUniqueCodeVerified;
  };

  render() {
    const { isUniqueCodeChecked, nextDisabled } = this.props;
    const { uniqueCode } = this.state;

    return (
      <div className="border rounded p-4 bg-f9 mt-3">
        <h6 className="txt-light-blue dashed-title mb-3 ltr-spc-1">
          Unique Code
        </h6>
        <VerificationInput
          preInput={"Unique Code"}
          inputValueDefault={uniqueCode}
          handleIsChecked={this.handleIsChecked}
          isTick={isUniqueCodeChecked.isTick}
          isCancel={isUniqueCodeChecked.isCancel}
          readOnly={true}
        />

        {!nextDisabled && (
          <div className="success-msg d-flex align-items-center mt-2">
            <span className="tick-icon-green-sml me-1"></span>
            <span className="txt-green fnt-sml">
              <i>Unique code has been successfully verified</i>
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default UniqueCode;
