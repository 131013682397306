import React from "react";
import { Toast } from "../../../../../utils/toastutils";
import api from "../../../../../apiBaseConfig";
import {
  GET_AGENT_AUDITORS,
  GET_AGENTS,
  GET_PROCESSES
} from "../../../../../apiurl";
import _ from "lodash";
import { connect } from "react-redux";
import { PAN_CHECK, NAME_CHECK, ALPHA_NUM_CHECK, MOBILE_CHECK, EMAIL_CHECK, SYSTEM_UCIC_CHECK, NUM_CHECK } from '../../../../../constants'
import cogoToast from "cogo-toast";
let connectionConfig = {
  name: {
    name: 'Connection Name',
    key: 'name',
    isValid: false,
    value: '',
    check: NAME_CHECK,
    required: true,
    placeholder: 'Enter connection name',
    error: '',
    type: 'text'
  },
  email: {
    name: 'Connection Email',
    key: 'email',
    isValid: false,
    value: '',
    check: EMAIL_CHECK,
    required: true,
    placeholder: 'example@abc.com',
    error: '',
    type: 'text'
  },
  mobile_number: {
    name: 'Connection Mobile Number',
    key: 'mobile_number',
    isValid: false,
    value: '',
    check: MOBILE_CHECK,
    required: true,
    placeholder: '000-0000-000',
    error: '',
    type: 'text',
    maxLength: 10
  },
  participant_tag: {
    name: 'User Role',
    key: 'participant_tag',
    isValid: false,
    value: '',
    check: NAME_CHECK,
    required: true,
    placeholder: '000-0000-000',
    error: '',
    type: 'drop',
    listData: [{ label: 'Select', value: '' }, { label: 'Applicant', value: 'applicant' },
    { label: 'Co-Applicant', value: 'coapplicant' }, { label: "RE's Participant", value: 'resparticipant' }
    ]
  },
  source_system_ucic: {
    name: 'Source System UCIC',
    key: 'source_system_ucic',
    isValid: false,
    value: '',
    // check: SYSTEM_UCIC_CHECK,
    check: NUM_CHECK,
    required: true,
    placeholder: '',
    error: '',
    type: 'text',
    // maxLength: 6,
    dataType: 'int'
  },
  guarantor_coapplicant_id: {
    name: 'Guarantor Coapplicant Id',
    key: 'guarantor_coapplicant_id',
    isValid: false,
    value: '',
    check: ALPHA_NUM_CHECK,
    required: true,
    placeholder: '',
    error: '',
    type: 'text',
  }

}
class ModuleDetails extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isMultiParticipant: false,
      isHFCL: props.clientInfo.client_details.name === 'HFCL' ? true : false,
      moduleData: {},
      errorData: {},
      agentList: [],
      agentAuditorList: [],
      processChoices: [],
      isPanValid: false,
      pan_number: "",
      connectionFields: [],
      hfclFields: [
        {
          name: 'Source System',
          key: 'source_system',
          required: true,
          placeholder: 'Enter Source System',
          type: '',
          value: '',
          isValid: false,
          error: '',
          check: ALPHA_NUM_CHECK
        },
        {
          name: 'Source System Application No',
          key: 'source_system_application_no',
          required: true,
          placeholder: 'Enter Source System Application No',
          type: '',
          value: '',
          isValid: false,
          error: '',
          check: ALPHA_NUM_CHECK


        },
        {
          name: 'Line of Business',
          key: 'line_of_business',
          placeholder: 'Enter Business Name',
          required: true,
          type: '',
          value: '',
          isValid: false,
          error: '',
          check: ALPHA_NUM_CHECK


        }, {
          name: 'Lead Id',
          key: 'lead_id',
          required: true,
          placeholder: 'Enter Lead Id',
          type: '',
          value: '',
          isValid: false,
          error: '',
          check: ALPHA_NUM_CHECK
        },
        {
          name: 'Business Manager',
          key: 'business_manager_name',
          required: true,
          placeholder: 'Enter Manager Name',
          type: '',
          value: '',
          isValid: false,
          error: '',
          check: NAME_CHECK
        },
        {
          name: 'Business Manager Mobile',
          key: 'business_manager_mobile_no',
          placeholder: '000-0000-000',
          required: true,
          type: '',
          value: '',
          isValid: false,
          error: '',
          check: MOBILE_CHECK,
          maxLength: 10
        }

      ],

    };
  }

  componentDidMount() {
    this.getAgentAuditorsList();
    this.getProcessList()
    if (this.props.moduleDetails) {
      this.setErrorData();
      this.setExistingModuleData();
    } else {
      this.setErrorData();
      this.setInitialModuleData();
    }
  }

  getProcessList = async () => {
    let { data } = await api.get(GET_PROCESSES);
    this.setState({ processChoices: data });
  }

  setErrorData = () => {

    let errorData = {
      name: false,
      agent: false,
      office_address: false,
      case_type: false,
    };
    this.setState({
      errorData: errorData
    });
  };
  setExistingModuleData = () => {
    let moduleData = this.props.moduleDetails;
    let connectionFields = []

    moduleData.connection_detail.map(item => {
      let temp = []
      let fields = _.cloneDeep(connectionConfig)
      Object.keys(item).map(subItem => {
        let fItem = fields[subItem]
        fItem.value = item[subItem]
        temp.push(fItem)
      })
      connectionFields.push(temp)
    })

    this.setState({ connectionFields });

    if (this.state.isHFCL) {
      let hfclFields = this.state.hfclFields
      hfclFields.map(item => {
        item.value = moduleData[item.key]
      })
      this.setState({ hfclFields })
    }

    this.setState({ moduleData: moduleData });
  };

  getAgentAuditorsList = async () => {
    try {
      const [agentList, agentAuditorList] = await Promise.all([
        api.get(GET_AGENTS),
        api.get(GET_AGENT_AUDITORS),
      ]);
      this.setAgentAuditorsList(agentList.data, agentAuditorList.data);
    } catch (error) {
      console.log("error in fetching agent list", error);
    }
  };

  setAgentAuditorsList = (agentData, agentAuditorData) => {
    let agentAuditorListArr = [];
    let agentListArr = [];
    agentAuditorData.forEach(item => {
      item.name = item.first_name + " " + item.last_name;
      agentAuditorListArr.push(item)
    })
    agentData.forEach(item => {
      item.id = item.agent_details.id;
      item.name = item.agent_details.first_name + " " + item.agent_details.last_name;
      agentListArr.push(item)
    })
    // setting default agent value
    let moduleData = this.state.moduleData;
    if (!moduleData.agent && moduleData.case_type === 'video_kyc') {
      moduleData.agent = (agentListArr && agentListArr.length > 0) ? agentListArr[0].id : "";
    }
    if (!moduleData.agent && moduleData.case_type === "personal_discussion") {
      moduleData.agent = (agentAuditorListArr && agentAuditorListArr.length > 0) ? agentAuditorListArr[0].id : "";
    }
    this.setState({
      agentList: agentListArr,
      agentAuditorList: agentAuditorListArr,
      moduleData: moduleData
    });
  };


  setInitialModuleData = () => {
    let moduleData = {
      name: "",
      agent: "",
      office_address: "",
      admin_comment: "",
      case_type: "video_kyc"
    };
    this.addAnotherParticipant(true)

    if (this.props.clientInfo.case_module === 2) {
      moduleData.case_type = 'personal_discussion'
    }
    this.setState({
      moduleData: moduleData
    });
  };

  processSelected = (isMultiParticipant) => {
    if (isMultiParticipant) this.setModuleData("case_type", "personal_discussion");
    else {
      this.setModuleData("case_type", "video_kyc")
      let { connectionFields } = this.state
      if (connectionFields.length > 1) {
        let temp = []
        temp.push(connectionFields[0])
        this.setState({ connectionFields: temp })
      }
    }
    this.setState({ isMultiParticipant });

  };

  addAnotherParticipant = (isInitial) => {
    let connectionData = ['name', 'email', 'mobile_number']
    if (this.state.isHFCL) connectionData.push('participant_tag')
    let connectionFields = isInitial ? [] : this.state.connectionFields

    let fields = _.cloneDeep(connectionConfig)
    let temp = []
    connectionData.forEach(item => {
      temp.push(fields[item])
    })
    connectionFields.push(temp)
    this.setState({ connectionFields })
  };

  removeParticipant = (index) => {
    let connectionFields = this.state.connectionFields
    connectionFields.splice(index, 1)
    this.setState({ connectionFields })
  };

  setModuleData = (key, val) => {
    const { agentList, agentAuditorList, moduleData } = this.state;
    if (key === "case_type") {
      if (val === 'personal_discussion' && agentAuditorList.length === 0) {
        cogoToast.error('No auditor for VPD')
        return
      }
      moduleData[key] = val;
      moduleData["agent"] =
        val === "video_kyc" ? agentList.length > 0 ? agentList[0].id : '' : agentAuditorList.length > 0 ? agentAuditorList[0].id : '';
    } else {
      moduleData[key] = key === "agent" ? parseInt(val) : val;
    }
    this.setState({ moduleData: moduleData });
    if (this.props.listed) {
      this.props.updateModuleListDataExisting(moduleData, this.props.index);
    }
  };

  setError = (key, val) => {
    let errorData = this.state.errorData;
    errorData[key] = val;
    this.setState({ errorData });
    if (this.props.listed) {
      let hasError = this.checkForErrors();
      this.props.updateErrorArray(hasError, this.props.index);
    }
  };

  checkForAlphanumericAndSpecialCharacters = (key, val) => {
    if (
      !new RegExp(
        /^[ A-Za-z0-9_@./#&+-]{2,60}[ A-Za-z0-9_@./#&+-]{0,60}$/i
      ).test(val)
    ) {
      this.setError(key, true);
    } else {
      this.setError(key, false);
    }
  };

  submitModuleDetails = () => {
    let hasError = this.checkForErrors();
    if (hasError) {
      Toast.error({
        description: "Please provide valid inputs.",
        title: "Invalid inputs",
      });
    } else {
      let { pan_number_validation } = this.props.clientInfo
      let moduleData = this.state.moduleData;
      let finalCaseData = this.getFinalCaseData(moduleData);
      if (pan_number_validation) {
        if (PAN_CHECK(this.state.pan_number)) {
          finalCaseData.pan_number = this.state.pan_number
        }
        else {
          cogoToast.error('Enter a valid pan number')
          return
        }
      }
      this.props.setShowModuleList(true);
      this.props.setModuleListData(finalCaseData);
      this.props.sendAgentAuditorsList(
        this.state.agentList,
        this.state.agentAuditorList
      );
    }
  };
  getFinalCaseData = (moduleData) => {
    let finalCaseData = _.cloneDeep(moduleData);
    let { processChoices, hfclFields, connectionFields } = this.state
    let process = processChoices.find(item => item.case_type)
    finalCaseData["process"] = process.id;
    let connection_detail = []
    connectionFields.forEach(item => {
      let temp = {}
      item.forEach(field => {
        if (field.key === 'source_system_ucic')
          temp[field.key] = parseInt(field.value)
        else
          temp[field.key] = field.value
      })
      connection_detail.push(temp)
    })
    finalCaseData["connection_detail"] = connection_detail;
    if (this.state.isHFCL) {
      hfclFields.forEach(item => {
        finalCaseData[item.key] = item.value
      })
    }
    return finalCaseData;
  };

  checkForErrors = () => {
    const {
      moduleData,
      connectionFields
    } = this.state;
    let hasError = false;
    connectionFields.map(item => {
      item.map(subItem => {
        if (!subItem.isValid || (subItem.required && subItem.value.length === 0)) {
          hasError = true
          subItem.error = 'Enter valid ' + subItem.name
        }
      })
    })
    this.setState({ connectionFields })
    for (var i in moduleData) {
      if (
        moduleData[i] !== null &&
        moduleData[i].length === 0 &&
        !["admin_comment", "office_address",].includes(i)
      ) {
        hasError = true;
      }
    }
    if (this.state.isHFCL) {
      if (!hasError) {
        hasError = this.checkHFCLError()
      }
      else this.checkHFCLError()
    }
    return hasError;
  };

  clearModuleData = () => {
    this.setInitialModuleData();
    this.setErrorData();
    this.setState({
      isMultiParticipant: false,
    });
  };
  handleHfclFields = (val, index) => {
    let { hfclFields } = this.state
    hfclFields[index].value = val
    if (hfclFields[index].check(val) && val.length > 0) {
      hfclFields[index].isValid = true
      hfclFields[index].error = ''
    }
    else {
      hfclFields[index].isValid = false
      hfclFields[index].error = 'Enter valid ' + hfclFields[index].name
    }
    this.setState({ hfclFields })
  }
  checkHFCLError = () => {
    let { hfclFields } = this.state
    let hasError = false
    hfclFields.map(item => {
      if (!item.isValid) {
        hasError = true
        item.error = 'Enter valid ' + item.name
      }
    })
    this.setState({ hfclFields })
    return hasError
  }
  handleConnectionChange = (val, index, subIndex) => {
    let { connectionFields } = this.state
    connectionFields[index][subIndex].value = val
    if (connectionFields[index][subIndex].check(val) && val.length > 0) {
      connectionFields[index][subIndex].isValid = true
      connectionFields[index][subIndex].error = ''
    }
    else {
      connectionFields[index][subIndex].isValid = false
      connectionFields[index][subIndex].error = 'Enter valid ' + connectionFields[index][subIndex].name
    }
    this.setState({ connectionFields })
  }
  handleConnectionDropChange = (val, index, subIndex) => {
    let { connectionFields } = this.state
    let fields = _.cloneDeep(connectionConfig)
    if (val === '' || val === 'resparticipant') {
      connectionFields[index] = connectionFields[index].filter((item) => {
        return item.key !== 'source_system_ucic' && item.key !== 'guarantor_coapplicant_id'
      })
    }
    else {
      if (this.state.isHFCL && connectionFields[index].findIndex(item => item.key === 'source_system_ucic') === -1) {
        connectionFields[index].push(fields['source_system_ucic'])
        connectionFields[index].push(fields['guarantor_coapplicant_id'])
      }
    }
    connectionFields[index][subIndex].value = val
    if (val === '') connectionFields[index][subIndex].isValid = false
    else {
      connectionFields[index][subIndex].isValid = true
      connectionFields[index][subIndex].error = ''
    }

    this.setState({ connectionFields })
  }
  render() {
    const { clientInfo } = this.props
    let { pan_number_validation } = clientInfo
    const {
      isMultiParticipant,
      moduleData,
      errorData,
      agentList,
      agentAuditorList,
    } = this.state;
    const { primaryClassname, showButtons } = this.props;

    return (
      <React.Fragment>
        <div className={primaryClassname}>
          <form>
            <div className="row px-3 pt-3">
              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label">
                    <small>Case Name<span className="red-astric">*</span></small>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Enter Case Name"
                    value={moduleData.name}
                    maxLength={60}
                    onChange={(e) => {
                      this.setModuleData("name", e.target.value);
                      this.checkForAlphanumericAndSpecialCharacters(
                        "name",
                        e.target.value
                      );
                    }}
                  />
                  {errorData.name && (
                    <div className="error ">
                      Please enter a valid case name.
                    </div>
                  )}
                </div>
              </div>
              {agentList && agentAuditorList && (
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">
                      <small>Agent<span className="red-astric">*</span></small>
                    </label>
                    <select
                      className="form-control form-control-sm"
                      value={moduleData.agent}
                      onChange={(e) =>
                        this.setModuleData("agent", e.target.value)
                      }
                    >
                      {moduleData.case_type === "video_kyc"
                        ? agentList.map((value, key) => {
                          return (
                            <option key={key} value={value.id}>
                              {value.name}
                            </option>
                          );
                        })
                        : agentAuditorList.map((value, key) => {
                          return (
                            <option key={key} value={value.id}>
                              {value.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              )}
              <div className="col-md-4" style={{ visibility: "hidden" }}>
                <div className="form-group"></div>
              </div>
            </div>
            {pan_number_validation ? <div className="row px-3 pb-3">
              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label">
                    <small>Pan Number</small>
                  </label>
                  <input
                    className="form-control form-control-sm"
                    value={this.state.pan_number}
                    maxLength={10}
                    onChange={(e) =>
                      this.setState({ "pan_number": e.target.value })
                    }
                  />

                </div>
              </div>
            </div> : null}

            {/* HFCL only fields  */}
            {this.state.isHFCL ? <div className="row px-3 pb-3">
              {
                this.state.hfclFields.map((item, index) => {
                  return <div className="col-md-4" key={index}>
                    <div className="form-group">
                      <label className="form-label">
                        <small>{item.name}</small>
                      </label>
                      <input
                        className="form-control form-control-sm"
                        placeholder={item.placeholder}
                        name={item.key}
                        value={item.value}
                        maxLength={item.maxLength ? item.maxLength : 50}
                        onChange={e => this.handleHfclFields(e.target.value, index)}
                      />
                      <div className="error ">
                        {item.error}
                      </div>
                    </div>
                  </div>
                })
              }
            </div> : null}
            <div className="row px-3 pb-3">

              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="form-label">
                        <small>Special Instructions</small>
                      </label>
                      <textarea
                        className="form-control"
                        rows="4"
                        value={moduleData.admin_comment}
                        onChange={(e) =>
                          this.setModuleData("admin_comment", e.target.value)
                        }
                        maxLength={60}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {clientInfo.case_module === 3 ? <div className="select-process-cntr bg-white px-3 py-3">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <label className="form-label">
                    <small>Select Process</small>
                  </label>
                  <div className="select-process-box" >
                    <div className="custom-control me-4 cursor-pointer" onClick={e => this.processSelected(false)}>
                      <input
                        type="radio"
                        id="customRadioInline1"
                        name="customRadioInline1"
                        className="cursor-pointer"
                        checked={
                          moduleData.case_type === "video_kyc" ? true : false
                        }
                      />

                      <label className="form-label" htmlFor="customRadioInline1">
                        <small>VKYC</small>
                      </label>
                    </div>
                    <div
                      className="custom-control cursor-pointer"
                      title="You can add upto 8 participants for good quality"
                      onClick={e => this.processSelected(true)}
                    >
                      <input
                        type="radio"
                        id="customRadioInline2"
                        name="customRadioInline1"
                        className="cursor-pointer"
                        checked={
                          moduleData.case_type === "personal_discussion"
                            ? true
                            : false
                        }
                      />
                      <label className="form-label" htmlFor="customRadioInline2">
                        <small>VPD</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div> : null}

            {this.state.connectionFields.map((listItem, index) => {
              return <div className="row px-3 pt-3">
                {listItem.map((item, subIndex) => {
                  if (item.type === 'drop') {
                    return <div className="col-md-3" key={subIndex}>
                      <div className="form-group">
                        <label className="form-label">
                          <small>{item.name}</small>
                        </label>
                        <select
                          className="form-control form-control-sm"
                          value={item.value}
                          onChange={(e) => this.handleConnectionDropChange(e.target.value, index, subIndex)}
                        >
                          {item.listData.map(dropItem => {
                            return <option value={dropItem.value}>{dropItem.label}</option>
                          })}

                        </select>
                        <div className="error ">
                          {item.error}
                        </div>
                      </div>
                    </div>
                  }
                  return <div className="col-md-3" key={subIndex}>
                    <div className="form-group">
                      <label className="form-label">
                        <small>{item.name}<span className="red-astric">*</span></small>
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder={`Enter ${item.name}`}
                        value={item.value}
                        maxLength={item.maxLength ? item.maxLength : 50}
                        onChange={e => this.handleConnectionChange(e.target.value, index, subIndex)}
                      />
                      <div className="error">
                        {item.error}
                      </div>
                    </div>
                  </div>
                })}
                {index > 0 ? <div className="col-md-1" >
                  <div
                    className="form-group text-end"
                    style={{ marginTop: "30px" }}
                  >
                    <button
                      type="button"
                      className="btn"
                      onClick={e => this.removeParticipant(index)}
                      style={{ color: "red", borderColor: "red", lineHeight: "1.0" }}
                    >
                      Delete
                    </button>
                  </div>
                </div> : null}
              </div>
            }
            )}

            {(isMultiParticipant ||
              moduleData.case_type === "personal_discussion") && (
                <div className="add-another-participant">
                  <div className="row px-3">
                    {moduleData.case_type === "personal_discussion" && (
                      <div className="col-md-12">
                        <h6 className="mb-0">
                          <small
                            className="text-primary"
                            onClick={e => this.addAnotherParticipant()}
                            style={{ cursor: "pointer" }}
                          >
                            Add Another Participants
                          </small>
                        </h6>
                        <hr />
                      </div>
                    )}
                  </div>
                </div>
              )}
          </form>
          {showButtons && (
            <div
              className="col-md-12 text-center"
              style={{ marginBottom: "50px" }}
            >
              <button
                type="submit"
                className="btn btn-secondary my-4 btn-sm"
                onClick={this.clearModuleData}
                style={{ marginRight: "10px" }}
              >
                Clear All
              </button>
              <button
                type="submit"
                className="btn btn-primary my-4 btn-sm"
                onClick={this.submitModuleDetails}
              >
                Submit
              </button>
            </div>
          )}
        </div>

      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  clientInfo: state.login.clientInfo
});


export default connect(mapStateToProps)(ModuleDetails);