import React, { useEffect, useState } from 'react'
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Loader from '../../components/loader/Loader';

export default function CasesChart({ data }) {
  const [loading, setLoading] = useState(true)
  const [caseType, setCaseType] = useState('all')
  const [caseTime, setCaseTime] = useState('today')
  const [chartOptions, setChartOption] = useState({})
  useEffect(() => {
    updateChart('thirty_days', 'all')
  }, [])
  const updateChart = (key, type) => {

    const active = data[key]["assigned"].count;
    const completed = data[key]["completed"].count;
    const audited = data[key]["approved"].count;


    const activeData = getSeriesData(active);
    const completedData = getSeriesData(completed);
    const auditedData = getSeriesData(audited);
    const finalSeriesData = getFinalSeriesData(type, activeData, completedData, auditedData)
    const categoriesData = getSeriesData(type === 'all' ? active : data[key][type].count);
    setCaseType(type)
    setCaseTime(key)
    setOptions(finalSeriesData, categoriesData);

  };

  const getSeriesData = (keyTATData) => {
    const seriesData = [];
    for (var i in keyTATData) {
      seriesData.push(keyTATData[i]);
    }
    return seriesData;
  };

  function setOptions(finalSeriesData, categoriesData) {
    const options = {
      chart: {
        type: "line",
        height: 150,
        // width: 400,
        spacingTop: 5,
        spacingBottom: 5,
        spacingLeft: 0,
        spacingRight: 5,
      },
      title: {
        style: { display: "none" },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        line: {
          dashStyle: "ShortDash",
        },
      },
      legend: {
        enabled: false,
      },
      series: finalSeriesData,
      tooltip: {
        formatter: function () {
          return `${this.series.name} : ${this.y}`;
        },
      },
      yAxis: {
        title: {
          text: "Cases",
        },
      },
      xAxis: {
        categories: categoriesData,
        // labels:{style: {
        //   width:"auto",
        //   height:"auto",
        // }
        // },
      },
    };
    setChartOption(options)
    setLoading(false)
  };
  const getFinalSeriesData = (cases, assignedData, completedData, auditedData) => {
    let seriesArr = [];
    switch (cases) {
      case "all":
        seriesArr = [
          {
            name: "Active Cases",
            data: assignedData,
            color: "blue",
          },
          {
            name: "Completed Cases",
            data: completedData,
            color: "Yellow",
          },
          {
            name: "Audited Cases",
            data: auditedData,
            color: "green",
          }

        ];
        break;
      case "assigned":
        seriesArr = [
          {
            name: "Active Cases",
            data: assignedData,
            color: "blue",
          },
        ];
        break;
      case "completed":
        seriesArr = [
          {
            name: "Completed Cases",
            data: completedData,
            color: "yellow",
          },
        ];
        break;
      case "approved":
        seriesArr = [
          {
            name: "Audited Cases",
            data: auditedData,
            color: "green",
          },
        ];
        break;
      default:
        seriesArr = [
          {
            name: "Active Cases",
            data: assignedData,
            color: "blue",
          },
          {
            name: "Completed Cases",
            data: completedData,
            color: "yellow",
          },
          {
            name: "Audited Cases",
            data: auditedData,
            color: "green",
          }

        ];
    }
    return seriesArr;
  };
  return (
    <div className="card" >
      <div
        className="card-header d-flex justify-content-between align-items-center pt-2 pb-2 ps-3 pe-3"
        style={{ backgroundColor: "#fbfbfb" }}
      >
        <span className="text-uppercase txt-blue-dull fnt-sml">
          Cases
        </span>
        <select
          name=""
          value={caseType}
          class="form-control form-control-sm fnt-sml"
          style={{ width: "120px" }}
          onChange={(e) => updateChart(caseTime, e.target.value)}
        >
          <option
            value="all"
          >
            All Cases
          </option>
          <option value="assigned">Active Cases</option>
          <option value="completed">Customer Completed</option>
          <option value="approved">Audited</option>
        </select>
        <select
          name=""
          id=""
          className="form-control form-control-sm fnt-sml"
          style={{ width: "120px" }}
          value={caseTime}
          onChange={(e) => updateChart(e.target.value, caseType)}
        >
          <option value="today">Today</option>
          <option value="seven_days">Last 7 days</option>
          <option value="thirty_days">Last 30 days</option>
          <option value="ninety_days">Last 90 days</option>
          <option value="greater_than_ninety">
            Greater than last 90 days
          </option>
        </select>
      </div>
      <div
        className="card-body text-center fnt-sml"
        style={{
          // height: "250px",
          backgroundColor: "fbfbfb",
        }}
      >
        {loading ? (
          <Loader />
        ) : (
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        )}
      </div>
    </div>
  )
}
