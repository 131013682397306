import React, { Component } from "react";
import DRAG_ICON from "../../../../../assets/icons/drag.svg";
import SURFACE_ICON from "../../../../../assets/icons/surface.svg";
import { UPLOAD_CASES } from "../../../../../apiurl";
import api from "../../../../../apiBaseConfig";
import "../uploadCases/UploadCases.scss";
import _get from "lodash/get";
import Loader from "../../../../../components/loader/Loader";
import { Toast } from "../../../../../utils/toastutils";

export default class UploadExcel extends Component {
  state = {
    drag: false,
    selectedFile: null,
    isLoading: false,
  };
  fileInput = React.createRef();

  dropRef = React.createRef();
  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ drag: true });
    }
  };
  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ drag: false });
    }
  };
  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ drag: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      //   this.props.handleDrop(e.dataTransfer.files);
      console.log("files", e.dataTransfer.files[0]);
      this.setState({ selectedFile: e.dataTransfer.files[0] });
      this.uploadCases(e.dataTransfer.files[0]);

      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  };

  handleFileInput = (file) => {
    console.log("files", file);
    this.setState({ selectedFile: file });
    this.uploadCases(file);
  };

  componentDidMount() {
    let div = this.dropRef.current;
    div.addEventListener("dragenter", this.handleDragIn);
    div.addEventListener("dragleave", this.handleDragOut);
    div.addEventListener("dragover", this.handleDrag);
    div.addEventListener("drop", this.handleDrop);
  }
  componentWillUnmount() {
    let div = this.dropRef.current;
    div.removeEventListener("dragenter", this.handleDragIn);
    div.removeEventListener("dragleave", this.handleDragOut);
    div.removeEventListener("dragover", this.handleDrag);
    div.removeEventListener("drop", this.handleDrop);
  }

  downloadSampleDocument = () => {
    window.open(
      process.env.REACT_APP_BASE_API_URL.slice(0, -3) +
        "sample/importing_sample_data_front_end.xlsx"
    );
  };

  uploadCases = async (file) => {
    try {
      this.setState({ isLoading: true });
      var formData = new FormData();

      formData.append("file", file);
      const { data } = await api.post(UPLOAD_CASES, formData);
      this.download("response.csv", data);
      console.log("cases uploaded successfully");
      Toast.success({
        title: "Cases uploaded successfully",
      });
      this.setState({ isLoading: false });
    } catch (error) {
      console.log("error in uploading cases");
      this.setState({ isLoading: false });
      Toast.error({
        title: "error in uploading cases",
      });
    }
  };

  download(filename, text) {
    var pom = document.createElement("a");
    pom.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(text)
    );
    pom.setAttribute("download", filename);

    if (document.createEvent) {
      var event = document.createEvent("MouseEvents");
      event.initEvent("click", true, true);
      pom.dispatchEvent(event);
    } else {
      pom.click();
    }
  }
  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && (
          <Loader title="Uploading Cases. Please wait" />
        )}
        <div className="row g-0">
          <div className="col-md-10 my-3">
            <h6 className="txt-9a ltr-spc-1 poppins-bld">Upload KYC cases</h6>
            <p className="txt-666 fnt-sml poppins-medium">
              You can upload the list of all the KYC cases here. please make
              sure the file size does not exceed 50 Mb at any given time
            </p>
          </div>
          <div class="col-md-2 my-3">
            <div
              class="custom-upload-btn"
              onClick={() => this.props.setUploadExcel(false)}
            >
              {/* <input type="file" class="upload-input" /> */}
              <span>
                <i class="fa fa-cloud-upload-alt me-1"></i> Upload Module
              </span>
            </div>
          </div>
          <div className="col-md-12">
            <div className="ml-30 mt-5">
              <div className="row">
                <div className="col-md-5 m-auto">
                  <label className="custom-file-upload">
                    {
                      <input
                        type="file"
                        ref={this.fileInput}
                        onChange={(event) => {
                          this.handleFileInput(
                            _get(this.fileInput.current, "files[0]")
                          );
                        }}
                        accept={
                          "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        }
                      />
                    }
                    {
                      <div
                        className="m-0 rounded-15 border brdr-light-blue bg-fc brdr-dashed d-flex align-items-center justify-content-center flex-column"
                        style={{
                          display: "inline-block",
                          position: "relative",
                          minHeight: "220px",
                        }}
                        ref={this.dropRef}
                      >
                        {this.state.selectedFile ? (
                          <p>{this.state.selectedFile.name}</p>
                        ) : (
                          <React.Fragment>
                            {" "}
                            <img src={DRAG_ICON} alt=""></img>
                            <p className="fnt-sml txt-light-blue2 mt-3 poppins-medium">
                              Tap or drag and drop to upload files
                            </p>
                          </React.Fragment>
                        )}
                      </div>
                    }
                  </label>
                  <div onClick={() => this.downloadSampleDocument()}>
                    <img
                      src={SURFACE_ICON}
                      class="ms-3"
                      width="10"
                      alt=""
                    ></img>
                    <span class="txt-light-blue2 font-italic fnt-sml-x">
                      Template Download
                    </span>
                  </div>
                  {/* <img
          src={SURFACE_ICON}
          className="ms-3"
          width="10"
          alt=""
        ></img>
        <span className="txt-light-blue2 font-italic fnt-sml-x">
          Template Download
        </span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
