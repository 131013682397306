import React from "react";
import VerificationInput from "../verificationInput/VerificationInput";
import { CASE_HISTORY } from "../../apiurl";
import api from "../../apiBaseConfig";

class DrivingLicenseDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drivingLicenseArray: [],
      enlarge: false,
      showDLFront: true,
    };
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      drivingLicenseArray: [
        {
          preInput: "Name",
          defaultValue: this.props.defaultValue
            ? this.props.defaultValue.name
            : "",
        },
        {
          preInput: "Father's Name",
          defaultValue: this.props.defaultValue
            ? this.props.defaultValue.father_name
            : "",
        },
        {
          preInput: "Address",
          defaultValue: this.props.defaultValue
            ? this.props.defaultValue.address
            : "",
        },
        {
          preInput: "DOB",
          defaultValue: this.props.defaultValue
            ? this.props.defaultValue.dob
            : "",
        },
        {
          preInput: "DL number",
          defaultValue: this.props.defaultValue
            ? this.props.defaultValue.doc_id
            : "",
        },
      ],
    });
  }

  handleIsCheckedForDL = (value, index) => {
    let dlCheckedArray = [...this.props.dlCheckedArray];
    value.type = this.state.drivingLicenseArray[index].preInput;
    dlCheckedArray[index] = value;
    if (this.props.dlData.document_upload_type === "digilocker_upload") {
      dlCheckedArray = [{ ...value, type: "Verify Driving License" }];
    }
    this.submitComment(
      `Driving License Details - ${
        dlCheckedArray[index].type
      } - ${JSON.stringify(value)}`
    );
    this.props.handleCheckArray(dlCheckedArray);
  };

  submitComment = async (comment) => {
    const caseId = this.props.caseId;
    const userId = this.props.userId;
    const body = {
      case: caseId,
      user: userId,
      type: "Action",
      re_text: comment,
    };
    try {
      const res = await api.post(CASE_HISTORY, body);
    } catch (e) {
      console.log(e);
    }
  };

  toggleImage = () => {
    const { showDLFront } = this.state;
    this.setState({ showDLFront: !showDLFront });
  };

  render() {
    const { dlImage, nextDisabled, dlCheckedArray, dlImageBack } = this.props;
    const { drivingLicenseArray, enlarge, showDLFront } = this.state;
    return (
      <React.Fragment>
        <div className="border rounded p-3 bg-f9">
          <h6 className="txt-light-blue dashed-title mb-3 ltr-spc-1">
            Driving License Verification
          </h6>
          <div className="ms-5 me-5 p-2 text-center bg-e1 rounded mb-3">
            <img
              src={showDLFront ? dlImage : dlImageBack}
              onMouseEnter={() => this.setState({ enlarge: true })}
              onMouseLeave={() => this.setState({ enlarge: false })}
              className="img-fluid rounded"
              alt=""
            />
            {enlarge && (
              <img
                src={showDLFront ? dlImage : dlImageBack}
                className="rounded border doc-enlarge"
                alt=""
              />
            )}
            <button
              className="aadhaarVerificationCardDetails"
              onClick={this.toggleImage}
            >
              &#8249;
            </button>
            <button
              className="aadhaarVerificationCardDetails"
              onClick={this.toggleImage}
            >
              &#8250;
            </button>
          </div>

          {this.props.dlData.document_upload_type === "ocr_upload" ||
          this.props.dlData.document_upload_type === "normal_upload" ? (
            drivingLicenseArray.map((aadhaarCardInput, key) => {
              return (
                <VerificationInput
                  preInput={aadhaarCardInput.preInput}
                  inputValueDefault={aadhaarCardInput.defaultValue}
                  handleIsChecked={this.handleIsCheckedForDL}
                  isTick={dlCheckedArray ? dlCheckedArray[key].isTick : false}
                  isCancel={
                    dlCheckedArray ? dlCheckedArray[key].isCancel : false
                  }
                  inputKey={key}
                  readOnly={true}
                />
              );
            })
          ) : (
            <div className="row mt-2">
              <div className="d-flex flex-fill">
                <p className="txt-70 ms-2">Verified</p>
                <div className="col d-flex justify-content-end align-items-center">
                  <div className="custom-radio d-flex justify-content-between">
                    <input
                      type="radio"
                      style={{ appearance: "none" }}
                      defaultChecked={dlCheckedArray[0].isTick === true}
                      onChange={(e) =>
                        this.handleIsCheckedForDL(
                          { isTick: true, isCancel: false },
                          0
                        )
                      }
                      name="dob"
                      id="dobTrue"
                    />
                    <label for="dobTrue" className="me-2"></label>

                    <input
                      type="radio"
                      name="dob"
                      id="dobFalse"
                      defaultChecked={dlCheckedArray[0].isCancel === true}
                      style={{ appearance: "none" }}
                      onChange={(e) =>
                        this.handleIsCheckedForDL(
                          { isTick: false, isCancel: true },
                          0
                        )
                      }
                    />
                    <label for="dobFalse" className="cancel-icon"></label>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!nextDisabled && (
            <div className="success-msg d-flex align-items-center mt-2">
              <span className="tick-icon-green-sml me-1"></span>
              <span className="txt-green fnt-sml">
                <i>Driving License has been successfully verified</i>
              </span>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default DrivingLicenseDetails;
