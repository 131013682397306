import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { ReactComponent as AadhaarIcon } from "../../../assets/icons/aadhaar.svg";
import { ReactComponent as PanIcon } from "../../../assets/icons/pan.svg";
import { ReactComponent as DlIcon } from "../../../assets/icons/driving-license.svg";
import { ReactComponent as VoterIcon } from "../../../assets/icons/voter-id.svg";
import { ReactComponent as TickUnselected } from "../../../assets/icons/tick_unselected.svg";
import { ReactComponent as UploadIcon } from "../../../assets/icons/upload.svg";
import { Button, CircularProgress } from "@material-ui/core";
import "../uploadDocs/UploadDocs.scss";
import { useParams } from "react-router-dom";
import {
    UPLOAD,
    GET_DOCUMENTS,
} from "../../../apiurl";
import api from "../../../apiBaseConfig";
import {
    resetUserDocs,
    presetUpload,
} from "../../state/user.actions";
import _get from "lodash/get";
import Loader from "../../../components/loader/Loader";
import { useDispatch, useSelector } from 'react-redux'

function UploadDocs(props) {
    let userInfo = useSelector(state => state.userDocs.anonymousUserConnectionInfo)
    let userDocInfo = useSelector(state => state.userDocs.userDocInfo)
    const clientConfig = useSelector(state => state.login.clientInfo)
    const dispatch = useDispatch()
    const history = useHistory();
    const { userToken } = useParams();
    const [uploadLoader, setUploadLoader] = useState(null);
    const [processDoc, setProcessDoc] = useState(null);
    const [processDocMandatory, setProcessDocMandatory] = useState([])
    const [processDocNonMandatory, setProcessDocNonMandatory] = useState([])


    useEffect(() => {
        updateUserDocs()
        if (clientConfig.hasOwnProperty('documents_required') && !clientConfig.documents_required) {
            handleNext()
        }
    }, [userInfo.case_details])

    function updateUserDocs() {
        let docs = (userInfo.case_details.process_details.documents || []).filter((doc) => !doc.is_agent_uploaded);
        let processDocMandatory = docs.filter(doc => doc.is_active && doc.is_mandatory && doc.document_upload_event === "before_call")
        let processDocNonMandatory = docs.filter(doc => doc.is_active && !doc.is_mandatory && doc.document_upload_event === "before_call")
        setProcessDocMandatory(processDocMandatory)
        setProcessDocNonMandatory(processDocNonMandatory)

        setProcessDoc(docs);
        checkUploadedDoc(
            userInfo.id,
            userInfo.case_details.id,
            docs
        );
    }

    const getUploadIcon = (type) => {
        type = type.toLowerCase()
        switch (type) {
            case "pan":
                return <PanIcon />;
            case "aadhar":
                return <AadhaarIcon />;
            case "voter id":
                return <VoterIcon />;
            case "driving license":
                return <DlIcon />;
            default:
                return <UploadIcon />;
        }
    };

    const checkUploadedDoc = async (connectionId, caseId, doc) => {
        let url = `${GET_DOCUMENTS}?case=${caseId}&connection=${connectionId}`
        if (props.isPivc)
            url = `${GET_DOCUMENTS}?pivc_case=${caseId}`
        const { data } = await api.get(url);
        const uploadedDoc = data.data.reduce((doc, upload) => {
            if (upload.is_verified) {
                doc = { ...doc, [upload.document_details.id]: true };
            }
            return doc;
        }, {});
        doc.forEach((element) => {
            if (uploadedDoc[element.id]) {
                dispatch(presetUpload(`${element.doc_key.toLowerCase()}Uploaded`));
            }
        });
        setProcessDoc(doc);
    };


    const handleUpload = (type, id) => {
        if (type === "driving license") type = "driving_license"
        if (props.isCallOngoing) return
        type = type.toLowerCase()
        if (!userDocInfo[`${type}Uploaded`]) {
            switch (type) {
                case "aadhar":
                case "driving_license":
                case "pan":
                    history.push({
                        pathname: "/user/docsupload",
                        state: { type: `${type}`, id: id },
                    });
                    break;
                default:
                    history.push({
                        pathname: "/user/verify/" + type.split(" ").join(""),
                        state: { type: `${type}`, id: id },
                    });
                    break;
            }
        }

    };

    const handleNext = () => {
        let isLive = (window.location.href).includes('live')
        if (isLive) {
            history.push("../../instruction");
        }
        else {
            if (props.isPivc) {
                props.nextClick()
            }
            else
                history.push("../instruction")
        }

    };

    const readUploadedFileAsDataURL = (inputFile) => {
        const temporaryFileReader = new FileReader();
        return new Promise((resolve, reject) => {
            temporaryFileReader.onerror = () => {
                temporaryFileReader.abort();
                reject(new DOMException("Problem parsing input file."));
            };
            temporaryFileReader.onload = () => {
                resolve(temporaryFileReader.result);
            };
            temporaryFileReader.readAsDataURL(inputFile);
        });
    };

    const upload = async (event, doc) => {
        if (!event.target || !event.target.files) {
            return;
        }
        const file = event.target.files[0];
        try {
            setUploadLoader(doc.id);
            const fileContent = await readUploadedFileAsDataURL(file);
            let responseFront = await api.put(UPLOAD, {
                connection: userInfo.id,
                case: userInfo.case_details.id,
                front_upload: fileContent,
                document: doc.id,
                is_verified: true,
                corrected_ocr: {},
                extracted_ocr: {},
                document_upload_event: doc.document_upload_event,
            });
            dispatch(presetUpload(`${doc.doc_key}Uploaded`));
            updateUserDocs()
            setUploadLoader(null);
        } catch (e) {
            console.log(e);
            setUploadLoader(null);
        }
    };
    return (
        <React.Fragment>
            <div class="wrapper light-blue-customer-template">
                <section className="main-body m-auto customer-flow-section">
                    <div className="container-fluid mt-4">
                        <div className="row">
                            <div className="col-md-12">
                                <h4 className="txt-light-blue ltr-spc-1">
                                    Help us in identifying you
                                </h4>
                                <h5 className="txt-light-black ltr-spc-1 mt-3 mb-3">
                                    Please provide your identity documents
                                </h5>
                                <p className="mt-4">
                                    <i className="far fa-clock txt-light-blue"></i>{" "}
                                    <span className="ltr-spc-1 txt-light-black">
                                        It will take less than 2 minutes
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                {processDoc && (
                                    <React.Fragment>
                                        {processDocMandatory.length > 0 ? <p className="ltr-spc-1 txt-light-black"
                                            style={{
                                                margin: "10px",
                                                fontSize: "15px",
                                            }}
                                        >
                                            Mandatory
                                        </p> : null}
                                        {processDocMandatory
                                            .map((document) => (
                                                <>
                                                    {document.doc_type === 1 ? <div
                                                        onClick={(e) =>
                                                            userDocInfo[
                                                                `${document.doc_key.toLowerCase()}Uploaded`
                                                            ]
                                                                ? ""
                                                                : handleUpload(
                                                                    document.doc_key,
                                                                    document.id
                                                                )
                                                        }
                                                        className={
                                                            userDocInfo[
                                                                `${document.doc_key.toLowerCase()}Uploaded`
                                                            ]
                                                                ? "white-box p-5-15 mt-3 active"
                                                                : "white-box p-5-15 mt-3 pointer"
                                                        }
                                                    >
                                                        <div className="left float-left d-flex align-items-center">
                                                            <span className="fingerprint-icon icon-light-blue">
                                                                {getUploadIcon(document.doc_key)}
                                                            </span>
                                                            <span className="txt-light-black fnt-sml ms-3 ltr-spc-0-5 word-break-all capitalize">
                                                                {document.name}
                                                            </span>
                                                        </div>
                                                        <div className="right float-left d-flex align-items-center justify-content-end">
                                                            <span className="tick-icon icon-green mt-2">
                                                                <TickUnselected />
                                                            </span>
                                                        </div>
                                                    </div> : <React.Fragment>
                                                        <input
                                                            type="file"
                                                            hidden
                                                            onChange={(e) => upload(e, document)}
                                                            id={`customField-${document.id}`}
                                                            className="form-control"
                                                            style={{ height: "60px", left: "0" }}
                                                        // disabled={document.isUploaded}
                                                        />
                                                        <label
                                                            htmlFor={`customField-${document.id}`}
                                                            className={
                                                                userDocInfo[
                                                                    `${document.doc_key.toLowerCase()}Uploaded`
                                                                ]
                                                                    ? "white-box p-5-15 mt-3 active"
                                                                    : "white-box p-5-15 mt-3 pointer"
                                                            }
                                                        >
                                                            <div className="left float-left d-flex align-items-center">
                                                                <span className="fingerprint-icon icon-light-blue">
                                                                    {getUploadIcon(document.doc_key)}
                                                                </span>
                                                                <span className="txt-light-black fnt-sml ms-3 ltr-spc-0-5 word-break-all">
                                                                    {document.name}
                                                                </span>
                                                            </div>
                                                            <div className="right float-left d-flex align-items-center justify-content-end">
                                                                <span className="tick-icon icon-green mt-2">
                                                                    {uploadLoader === document.id ? (
                                                                        <CircularProgress
                                                                            color="secondary"
                                                                            {...props}
                                                                        />
                                                                    ) : (
                                                                        <TickUnselected />
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </label>
                                                    </React.Fragment>}

                                                </>
                                            ))}

                                        {processDocNonMandatory.length > 0 ?
                                            <React.Fragment>
                                                <p
                                                    className="ltr-spc-1 txt-light-black"
                                                    style={{
                                                        margin: "10px",
                                                        fontSize: "15px",
                                                    }}
                                                >
                                                    Upload any one of the following
                                                    documents.
                                                </p>
                                                {processDocNonMandatory
                                                    .map((document) => (
                                                        <>
                                                            {document.doc_type === 1 ? <div
                                                                onClick={(e) =>
                                                                    userDocInfo[
                                                                        `${document.doc_key.toLowerCase()}Uploaded`
                                                                    ]
                                                                        ? ""
                                                                        : handleUpload(
                                                                            document.doc_key,
                                                                            document.id
                                                                        )
                                                                }
                                                                className={
                                                                    userDocInfo[
                                                                        `${document.doc_key.toLowerCase()}Uploaded`
                                                                    ]
                                                                        ? "white-box p-5-15 mt-3 active"
                                                                        : "white-box p-5-15 mt-3 pointer"
                                                                }
                                                            >
                                                                <div className="left float-left d-flex align-items-center">
                                                                    <span className="fingerprint-icon icon-light-blue">
                                                                        {getUploadIcon(document.doc_key)}
                                                                    </span>
                                                                    <span className="txt-light-black fnt-sml ms-3 ltr-spc-0-5 word-break-all">
                                                                        {document.name}
                                                                    </span>
                                                                </div>
                                                                <div className="right float-left d-flex align-items-center justify-content-end">
                                                                    <span className="tick-icon icon-green mt-2">
                                                                        <TickUnselected />
                                                                    </span>
                                                                </div>
                                                            </div> :
                                                                <React.Fragment>
                                                                    <input
                                                                        type="file"
                                                                        hidden
                                                                        onChange={(e) => upload(e, document)}
                                                                        id={`customField-${document.id}`}
                                                                        className="form-control"
                                                                        style={{ height: "60px", left: "0" }}
                                                                    // disabled={document.isUploaded}
                                                                    />
                                                                    <label
                                                                        htmlFor={`customField-${document.id}`}
                                                                        className={
                                                                            userDocInfo[
                                                                                `${document.doc_key.toLowerCase()}Uploaded`
                                                                            ]
                                                                                ? "white-box p-5-15 mt-3 active"
                                                                                : "white-box p-5-15 mt-3 pointer"
                                                                        }
                                                                    >
                                                                        <div className="left float-left d-flex align-items-center">
                                                                            <span className="fingerprint-icon icon-light-blue">
                                                                                {getUploadIcon(document.doc_key)}
                                                                            </span>
                                                                            <span className="txt-light-black fnt-sml ms-3 ltr-spc-0-5 word-break-all">
                                                                                {document.name}
                                                                            </span>
                                                                        </div>
                                                                        <div className="right float-left d-flex align-items-center justify-content-end">
                                                                            <span className="tick-icon icon-green mt-2">
                                                                                {uploadLoader === document.id ? (
                                                                                    <CircularProgress
                                                                                        color="secondary"
                                                                                        {...props}
                                                                                    />
                                                                                ) : (
                                                                                    <TickUnselected />
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                    </label>
                                                                </React.Fragment>}

                                                        </>
                                                    ))}


                                            </React.Fragment> : null
                                        }
                                        {props.isCallOngoing &&
                                            processDoc
                                                .filter(
                                                    (doc) =>
                                                        doc.document_upload_event === "on_call"
                                                )
                                                .map((document) => (
                                                    <React.Fragment>
                                                        <input
                                                            type="file"
                                                            hidden
                                                            onChange={(e) => upload(e, document)}
                                                            id={`customField-${document.id}`}
                                                            className="form-control"
                                                            style={{ height: "60px", left: "0" }}
                                                        // disabled={document.isUploaded}
                                                        />
                                                        <label
                                                            htmlFor={`customField-${document.id}`}
                                                            className={
                                                                userDocInfo[
                                                                    `${document.doc_key.toLowerCase()}Uploaded`
                                                                ]
                                                                    ? "white-box p-5-15 mt-3 active"
                                                                    : "white-box p-5-15 mt-3 pointer"
                                                            }
                                                        >
                                                            <div className="left float-left d-flex align-items-center">
                                                                <span className="fingerprint-icon icon-light-blue">
                                                                    {getUploadIcon(document.doc_key)}
                                                                </span>
                                                                <span className="txt-light-black fnt-sml ms-3 ltr-spc-0-5 word-break-all">
                                                                    {document.name}
                                                                </span>
                                                            </div>
                                                            <div className="right float-left d-flex align-items-center justify-content-end">
                                                                <span className="tick-icon icon-green mt-2">
                                                                    {uploadLoader === document.id ? (
                                                                        <CircularProgress
                                                                            color="secondary"
                                                                            {...props}
                                                                        />
                                                                    ) : (
                                                                        <TickUnselected />
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </label>
                                                    </React.Fragment>
                                                ))}
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                        {
                            // props.userInfo.aadharUploaded &&
                            // props.userInfo.panUploaded &&
                            processDoc &&
                            processDoc
                                .filter(
                                    (doc) => {
                                        if (doc.document_upload_event === "before_call" && doc.is_mandatory)
                                            return doc
                                    }
                                )
                                .filter(
                                    (document) =>
                                        userDocInfo[
                                        `${document.doc_key.toLowerCase()}Uploaded`
                                        ]
                                ).length >= processDocMandatory.length &&
                            !props.isCallOngoing && (
                                <div className="row mrB mrT right">

                                    <div className="col-md-6 text-end">
                                        <Button
                                            variant="contained"
                                            onClick={() => handleNext()}
                                            color="secondary"
                                        >
                                            Next
                                        </Button>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </section>
            </div>

        </React.Fragment>
    );
}

export default UploadDocs;
