import React from "react";
import CompletedCases from "./CompletedCases";
import CompletedTabs from "./CompletedTabs";
class CompletedCasesContainer extends React.Component {
  changeActiveLowerTab = (activeLowerTab) => {
    this.props.updateCompletedCasesData(activeLowerTab);
  };
  render() {
    const {
      completedCasesData,
      completedCasesLowerTab,
      searchKeyObject,
      agentAuditorList,
      consolidatedAgentList,
    } = this.props;
    return (
      <div
        className="tab-pane show active"
        id="completed"
        role="tabpanel"
        aria-labelledby="completed-tab"
      >
        <div
          className="row g-0 bg-light pt-2"
          style={{ backgroundColor: "#FBFBFB" }}
        >
          <CompletedTabs
            activeLowerTab={completedCasesLowerTab}
            changeActiveLowerTab={this.changeActiveLowerTab}
          />
        </div>
        <div className="row g-0">
          <CompletedCases
            activeLowerTab={completedCasesLowerTab}
            completedCasesData={completedCasesData}
            handleViewDocuments={this.props.handleViewDocuments}
            getSearchObject={this.props.getSearchObject}
            searchKeyObject={this.props.searchKeyObject}
            handleSort={this.props.handleSort}
            agentAuditorList={agentAuditorList}
            consolidatedAgentList={consolidatedAgentList}
          />
        </div>
      </div>
    );
  }
}

export default CompletedCasesContainer;
