import React from "react";
import LiveCases from "./LiveCases";

class LiveCasesContainer extends React.Component {
    render() {
        const { dataArray } = this.props;
        return (
            <div
                className="tab-pane show active"
                id="cancelled"
                role="tabpanel"
                aria-labelledby="cancelled-tab"
            >
                <div
                    className="row g-0 bg-light pt-2"
                    style={{ backgroundColor: "FBFBFB" }}
                ></div>
                <div className="row g-0">
                    <LiveCases
                        dataArray={dataArray}
                        getSearchObject={this.props.getSearchObject}
                        searchKeyObject={this.props.searchKeyObject}
                        handleSort={this.props.handleSort}
                    />
                </div>
            </div>
        );
    }
}

export default LiveCasesContainer;
