import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import {
    CUSTOMER_CONSENT,
    SAVE_LOCATION,
    IP_SPOOFING,
} from "../../apiurl"
import Loader from "../../components/loader/Loader";
import API from '../../apiBaseConfigNew'
import cogoToast from "cogo-toast";
import Each from "../../Each";
import Diagnostic from '../../user/screens/diagnostic/Diagnostic'

//consent screen
export default function Consent(props) {
    const [loading, setLoading] = useState(false)
    const [terms, setTerms] = useState([{ checked: false, label: `I have read the terms & conditions and the <a href="../privacy" target="_blank">Privacy Notice </a>so here I provide my consent.` }])
    // const [isLivenessChecked, setIsLivenessChecked] = useState(!props.livenessCheck)
    const [isLivenessChecked, setIsLivenessChecked] = useState(false)



    const tagGeolocation = async (latitude, longitude) => {
        const response = await API.post(SAVE_LOCATION, {
            latitude: latitude,
            longitude: longitude,
        });
    };

    function submitConsent() {
        const location = {};
        setLoading(true)
        navigator.geolocation.getCurrentPosition(
            async function (position) {
                location["latitude"] = position.coords.latitude;
                location["longitude"] = position.coords.longitude;

                try {
                    await tagGeolocation(
                        position.coords.latitude,
                        position.coords.longitude
                    );
                    const res = await API.post(IP_SPOOFING, {});
                    setLoading(false)
                    if (!res || res.status !== 200) {
                        cogoToast.error('Connection is not secure.');
                        return;
                    }

                    await API.post(CUSTOMER_CONSENT, {});
                    props.nextClick()

                } catch (e) {
                    setLoading(false)
                    cogoToast.error("Location failed!");
                }

            },
            (e) => {
                setLoading(false)
                cogoToast.error("Please provide your location access to continue further");
            },
            { timeout: 10000 }
        );
    };

    function handleConsentChange(index, val) {
        let temp = [...terms]
        temp[index].checked = val
        setTerms(temp)
    }

    return (
        <>
            {loading ? <Loader full /> : null}
            {isLivenessChecked ?
                <div className="wrapper light-blue-customer-template">
                    <section className="main-body m-auto">
                        <div className="container-fluid mt-4">
                            <div className="row">
                                <div className="col-md-12">
                                    <h4 className="txt-light-blue ltr-spc-1">Customer consent</h4>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-12">
                                    <div className="border border-1 dashed-brdr brdr-light-blue rounded p-4 mb-5">
                                        <div
                                            className="border brdr-c5 p-4 rounded"
                                            style={{ maxHeght: "300px", overflowY: "auto" }}
                                        >
                                            <p className="txt-light-blue">Terms &amp; Conditions</p>
                                            <p className="fnt-sml txt-light-black">
                                                CONSENT- I hereby give my consent to the Authbridge and
                                                its service partner to process my Personal and Sensitive
                                                Personal Data (as given below) for the purpose of my
                                                On-Boarding via Video-Customer Identification Process
                                                <ul>
                                                    <li>
                                                        Access to my device’s camera, microphone, and
                                                        geolocation
                                                    </li>
                                                    <li>
                                                        Processing and capturing my National Identity document
                                                        such as PAN & Aadhaar,
                                                    </li>
                                                    <li>
                                                        Processing and capturing any other documents (as
                                                        applicable),
                                                    </li>
                                                    <li>
                                                        Recording of my video call and chat with the agent,
                                                    </li>

                                                    <li>
                                                        Matching my face with the photograph on National
                                                        Identity
                                                    </li>

                                                </ul>
                                            </p>

                                            <p className="fnt-sml txt-light-black">
                                                Non-adherence to any of the above requirements would
                                                lead to the failure of the  process. Please try
                                                to hold your device upright and still during the call.
                                                The data shared during the  process would
                                                remain completely anonymous and would not be shared with
                                                any third party.
                                            </p>

                                        </div>
                                        <div className="custom-checkbox-normal ms-3 mt-2">
                                            <Each of={terms} render={(item, index) => {
                                                return <div className="d-flex align-items-center" key={index}>
                                                    <input
                                                        checked={item.checked}
                                                        onChange={e => handleConsentChange(index, e.target.checked)}
                                                        type="checkbox"
                                                        className="checkbox-round"
                                                    />
                                                    <label dangerouslySetInnerHTML={{ __html: item.label }} className="txt-light-black fnt-sml font-weight-bold">
                                                    </label>
                                                </div>
                                            }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Button
                        variant="contained"
                        className="proceed-button mb-5"
                        disabled={!terms[0].checked}
                        color="secondary"
                        onClick={submitConsent}
                    >
                        Proceed
                    </Button>
                </div>
                : <Diagnostic setDiagnosticCheck={() => setIsLivenessChecked(true)} />
            }
        </>
    );
}
