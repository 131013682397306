import React from "react";
import "../Login.scss";
import HFCLIcon from "../../assets/icons/hfcl-icon.svg";

function LoginHeader(props) {
  return (
    <div className="headerContainer">
    <div className="countryName">
    </div>
    <div className="compoanyName">
      <div className="logoborder p-1 me-1 bg-white">
        <img style={{ width: "6em", margin: "5px" }} src={HFCLIcon} alt=""></img>
      </div>

    </div>
  </div>
  );
}

export default LoginHeader;
