import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { ReactComponent as FlipCameraIcon } from "../../assets/icons/flipcamera.svg";
import "./ChimeVideoRoomComponent.scss"
import {
  ControlBar,
  ControlBarButton,
  useToggleLocalMute,
  useLocalVideo,
  VideoTileGrid,
  Microphone,
  Camera,
  Phone,
  Share,
  useMeetingStatus,
  LocalVideo,
  useRemoteVideoTileState,
  useVideoInputs,
  useMeetingManager,
  Attachment,
  Chat,
  useAudioVideo,
  Record
} from "amazon-chime-sdk-component-library-react";
import {
  Dialog,
  DialogContent
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import UploadDocsMulti from "../../user/screens/uploadDocsMulti/UploadDocsMulti";
import UploadDocs from "../../user/screens/customer/UploadDocs"
import ChimeChat from "./ChimeChat";
import cogoToast from "cogo-toast";
let timerTest = null
let presentUsers = []
const ChimeVideoRoomComponent =({
  leaveSession,
  setCustomerJoind = (val) => { },
  isToolbarDissabled = false,
  toggleCamera = true,
  isCustomer,
  isCPV,
  reconnectsession,
  multi,
  toggleRefreshMultiDocList,
  handleCustomerLeave
},ref) =>{
  const { toggleVideo, isVideoEnabled } = useLocalVideo();
  const { muted, toggleMute } = useToggleLocalMute();
  const meetingStatus = useMeetingStatus();
  const meetingManager = useMeetingManager()
  const {devices, selectedDevice} = useVideoInputs()
  const [videoSource, setVideoSource] = useState('front')
  const [openUpload, setOpenUpload] = useState(false)
  const [customerJoind1, setCustomerJoind1] = useState(false) 
  const { tiles, size } = useRemoteVideoTileState();
  const [isChatOpen, setIsChatOpen] = useState(false)
  const [isNewMessage, setIsNewMessage] = useState(false)
  const [newMessageData ,setNewMessageData] = useState({})
  const audioVideo = useAudioVideo() 
  const [timer, setTimer] = useState(null)
  const [videoEvent, setVideoEvent] = useState(false)
  const [recordingStatus, setRecordingStatus] = useState(false)
  const [VideoTileNumbers, setVideoTileNumbers] = useState(0)

  const recordProps ={
    icon : <Record title="Recording on" />,
    onClick:()=>{},
    label:'Recording'
  }
  const microphoneButtonProps = {
    icon: muted ? <Microphone muted /> : <Microphone />,
    onClick: () => {
      if(toggleCamera)
       toggleMute()
    },
    label: "Mute",
  };

  const cameraButtonProps = {
    icon: isVideoEnabled ? <Camera /> : <Camera disabled />,

    onClick: () => {
      if (toggleCamera)
       { toggleVideo();
       audioVideo.realtimeSendDataMessage("Events",{ type: 'videoToggle',sender:'Agent'})
       sendSelfPresence()
      }
    },
    label: "Camera",
  };

  const uploadIconProps = {
    icon: <Attachment /> ,
    onClick: () => setOpenUpload(true),
    label: "Upload",
  };


  const hangUpButtonProps = {
    icon: <Phone />,
    onClick: () => {
      if(isCustomer && !multi){
        audioVideo.realtimeSendDataMessage("Events",{type:'customer_left'})
        leaveSession(meetingStatus)
      }
      else
       leaveSession(meetingStatus)
    },
    label: "End",
  };

  const FlipCamera = {
    icon: <FlipCameraIcon className="Flip-svg" />,
    onClick: () => handleCameraFlip(),
    label: 'Flip',
  };
  const ChatProps = {
    icon : <div><Chat className="chat-icon" />
          {isNewMessage && !isChatOpen && <span className="chat-notification"></span>} 
    </div>,
    onClick: () => setIsChatOpen(old=>!old),
    label: 'Chat',
  }

  async function handleCameraFlip(){
    try{
      let index = devices.findIndex(item=>!item.label.includes(videoSource))
      if(index>-1){
        let newSource = videoSource === 'front' ? 'back' :'front'
        await meetingManager.startVideoInputDevice(devices[index].deviceId)
        setVideoSource(newSource)
      }
    }
    catch (error) {
      console.log('CameraSelection failed to select camera');
    }
    
  }
 
  useEffect(() => {
    toggleVideo();
    if(isCustomer){
      let timer =  setTimeout(()=> handleReconnect(),10000)
      setTimer(timer)
    }
    return ()=>{
      if(!isCustomer)
       audioVideo.realtimeSendDataMessage("Events",{type:'videoToggle'})
    }
  }, []);

  function handleReconnect(){ 
    reconnectsession()
  }

  useEffect(() => {
    if (tiles.length > 0) {
      setCustomerJoind(true);
      setCustomerJoind1(true)
    }
    if(isCustomer && tiles.length == 0 && customerJoind1){
        setTimeout(()=>checkForUserPresence(),2000) 
    }
    if(tiles.length > 0){
      clearTimeout(timer)
      setTimer(null)
    }
    if(size >= 1 && size < VideoTileNumbers){
      sendSelfPresence()
    }
    setVideoTileNumbers(old=>size)

  }, [tiles]);

  function checkForUserPresence(){
    if(videoEvent) return
    console.log('User Presence Checked')
    leaveSession(meetingStatus)
  }

  function sendMessage(data){
   audioVideo.realtimeSendDataMessage("Chat",data)
  }

  function receiveChatData(data){
    const jsonString = Buffer.from(data.data).toString('utf8')
    data.data = JSON.parse(jsonString)
    if(!isChatOpen) setIsNewMessage(true)
    setNewMessageData(data)
  }
  useImperativeHandle(ref, (status) => ({
    sendRecordingEvent(status){
      setRecordingStatus(status)
      audioVideo.realtimeSendDataMessage("Events",{type:'recording',status})
    },
    sendMeetingIncompleteEvent(){
      audioVideo.realtimeSendDataMessage("Events",{type:'incomplete'})
    },
    
  }))

  function sendSelfPresence(){
     audioVideo.realtimeSendDataMessage("Events",{type:'check_presence',name:isCustomer ? 'customer':'agent'})
  }

  function receiveChatEvents(data){
    const jsonString = Buffer.from(data.data).toString('utf8')
    let event = JSON.parse(jsonString)
    if(event.type === "videoToggle"){
    setVideoEvent(true)
    setTimeout(()=>setVideoEvent(false),5000)}
    if(event.type === 'recording'){
      setRecordingStatus(event.status)
    }
    if(isCustomer && event.type === 'incomplete'){
      leaveSession()
    }
    if(event.type === 'check_presence' && isCustomer && multi){
      if(timerTest) clearTimeout(timerTest)
      presentUsers.push(event.name)
      timerTest = setTimeout(()=>checkForAgentPresence(),1500)
    }
    if(!isCustomer && event.type === 'customer_left'){
      cogoToast.info('Customer Left the meeting')
      handleCustomerLeave()
    }
  }

  useEffect(() => {
    if(audioVideo) {
    audioVideo.realtimeSubscribeToReceiveDataMessage("Chat",receiveChatData)
    audioVideo.realtimeSubscribeToReceiveDataMessage("Events",receiveChatEvents)
    return () => {
        audioVideo.realtimeUnsubscribeFromReceiveDataMessage("Chat") // <----- (3-2)
        audioVideo.realtimeUnsubscribeFromReceiveDataMessage("Events")
    }
  }
})

useEffect(()=>{
  if(audioVideo) {
    audioVideo.realtimeSubscribeToAttendeeIdPresence(attendeesInFrame)
    return () => {
        audioVideo.realtimeUnsubscribeToAttendeeIdPresence()
    }
  }
})

function attendeesInFrame (attendeeId, present,externalUserId, dropped,posInFrame){
  
if(posInFrame.attendeesInFrame >1){
  clearTimeout(timer)
  setTimer(null)
}
else{
  console.log('attendeee in frame check',tiles, size, customerJoind1)
  if(isCustomer && customerJoind1 && !multi && tiles.length === 0 && size === 0)
   checkForUserPresence()
}
}
function checkForAgentPresence(){
  console.log('check for Agent Presence', presentUsers)
  clearTimeout(timerTest)
  if(presentUsers.includes('agent')){
    presentUsers = []
  }
  else leaveSession(meetingStatus)
}

  return (
    <div style={{ width: "100%", height: "100%" }}>
       <Dialog
          onClose={()=>setOpenUpload(false)}
          aria-labelledby="customized-dialog-title"
          open={openUpload}
        >
          <div className="align-self-end px-4">
            <IconButton aria-label="close" onClick={() =>setOpenUpload(false)}>
              <CloseRoundedIcon />
            </IconButton>
          </div>
          <DialogContent dividers>
            {multi ? (
              <UploadDocsMulti
                isCallOngoing={true}
                toggleRefreshMultiDocList={toggleRefreshMultiDocList}
              />
            ) : (
              <UploadDocs
                isCallOngoing={true}
                toggleRefreshMultiDocList={toggleRefreshMultiDocList}
              />
            )}
          </DialogContent>
        </Dialog>
      {!isToolbarDissabled && (
        <ControlBar className="aws-chime-toolbar" showLabels layout="top" css="position:relative; z-index:10">
          {recordingStatus ? <ControlBarButton {...recordProps} /> : null}
          <ControlBarButton {...microphoneButtonProps} />
         {!isCPV ? <ControlBarButton {...cameraButtonProps} /> : null}
          {devices.length > 1 ? <ControlBarButton {...FlipCamera} /> : null }
         {isCustomer && !isCPV ? <ControlBarButton {...uploadIconProps} /> : null}
         <ControlBarButton {...ChatProps} />
          <ControlBarButton {...hangUpButtonProps} />
        </ControlBar>
      )}
      {tiles.length ? (
        <div className="chime-video-container">
          <VideoTileGrid className={size === 1 ? "remote-tile-custom" : ""} />
        </div>
      ) : (
        <LocalVideo id="1" className="local-video" nameplate="Me" />
      )}
      <ChimeChat isChatOpen = {isChatOpen} isCustomer ={ isCustomer } newMessage={newMessageData} name="Akshay"  close={e => {setIsChatOpen(old=>!old); setIsNewMessage(false)}} sendMessage={(data)=>sendMessage(data)} /> 
     
    </div>
  );
}
export default forwardRef(ChimeVideoRoomComponent)

// const FlipCameraIcon = () =>{
//   return <svg className="flipIcon" width="25"  height="25" viewBox="0 0 25 25"  xmlns="http://www.w3.org/2000/svg">
//   <mask id="mask0_558_33072" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
//   <rect x="0.216553" y="0.203857" width="24" height="24" fill="#D9D9D9"/>
//   </mask>
//   <g mask="url(#mask0_558_33072)">
//   <path d="M16.4666 10.7039L13.9666 13.2039L15.0166 14.2539L15.6666 13.6039C15.5666 14.4872 15.1916 15.2247 14.5416 15.8164C13.8916 16.408 13.1166 16.7039 12.2166 16.7039C11.9832 16.7039 11.7666 16.683 11.5666 16.6414C11.3666 16.5997 11.1666 16.5372 10.9666 16.4539L9.86655 17.5539C10.2166 17.7539 10.5874 17.9122 10.9791 18.0289C11.3707 18.1455 11.7832 18.2039 12.2166 18.2039C13.5499 18.2039 14.6874 17.7539 15.6291 16.8539C16.5707 15.9539 17.0832 14.8539 17.1666 13.5539L17.9166 14.2539L18.9666 13.2039L16.4666 10.7039ZM7.96655 15.7039L10.4666 13.2039L9.41655 12.1539L8.76655 12.8039C8.86655 11.9205 9.24155 11.183 9.89155 10.5914C10.5416 9.99969 11.3166 9.70386 12.2166 9.70386C12.4499 9.70386 12.6666 9.72469 12.8666 9.76636C13.0666 9.80802 13.2666 9.87052 13.4666 9.95386L14.5666 8.85386C14.2166 8.65386 13.8457 8.49552 13.4541 8.37886C13.0624 8.26219 12.6499 8.20386 12.2166 8.20386C10.8832 8.20386 9.74572 8.65386 8.80405 9.55386C7.86239 10.4539 7.34989 11.5539 7.26655 12.8539L6.51655 12.1539L5.46655 13.2039L7.96655 15.7039ZM4.21655 21.2039C3.66655 21.2039 3.19572 21.008 2.80405 20.6164C2.41239 20.2247 2.21655 19.7539 2.21655 19.2039V7.20386C2.21655 6.65386 2.41239 6.18302 2.80405 5.79136C3.19572 5.39969 3.66655 5.20386 4.21655 5.20386H7.36655L9.21655 3.20386H15.2166L17.0666 5.20386H20.2166C20.7666 5.20386 21.2374 5.39969 21.6291 5.79136C22.0207 6.18302 22.2166 6.65386 22.2166 7.20386V19.2039C22.2166 19.7539 22.0207 20.2247 21.6291 20.6164C21.2374 21.008 20.7666 21.2039 20.2166 21.2039H4.21655ZM20.2166 19.2039V7.20386H16.1666L14.3416 5.20386H10.0916L8.26655 7.20386H4.21655V19.2039H20.2166Z" fill="#1C1B1F"/>
//   </g>
//   </svg>
  
// }
