import React, { useState } from "react";
import Key_BLUE_ICON from "../../assets/icons/key-blue-icon.png";
import "./ChangePassword.scss";
import { CHANGE_PASSWORD } from "../../apiurl";
import api from "../../apiBaseConfig";
import { Toast } from "../../utils/toastutils";
import { useHistory } from "react-router";

export default function ChangePassword() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMismatchError, setPassWordMismatchError] = useState("");
  const history = useHistory();

  const updatePassword = async () => {
    if (newPassword !== confirmPassword) {
      setPassWordMismatchError(true);
      return;
    }

    try {
      await api.patch(CHANGE_PASSWORD, {
        old_password: oldPassword,
        new_password: newPassword,
        confirm_new_password: confirmPassword,
      });
      history.push("/login");
      Toast.success({
        title: "Password changed successfully",
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="row g-0">
      <div className="col-md-12">
        <div className="ml-30 mr-30 mt-4">
          <div className="container-fluid" style={{ padding: 0 }}>
            <div className="row mb-3 d-flex justify-content-center">
              <div className="col-md-4">
                <form action="" className="change-password-form">
                  <div className="form-group">
                    <label htmlFor="">Enter Old Password</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text bg-white">
                          <img src={Key_BLUE_ICON} alt="" />
                        </span>
                      </div>
                      <input
                        type="password"
                        className="form-control form-control-sm"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="form-group">
                    <label htmlFor="">Enter New Password</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text bg-white">
                          <img src={Key_BLUE_ICON} alt="" />
                        </span>
                      </div>
                      <input
                        type="password"
                        className="form-control form-control-sm"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                      {passwordMismatchError && (
                        <span className="text-danger">
                          New password and Confirm password do not match
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="">Confirm Password</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text bg-white">
                          <img src={Key_BLUE_ICON} alt="" />
                        </span>
                      </div>
                      <input
                        type="password"
                        className="form-control form-control-sm"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      {passwordMismatchError && (
                        <span className="text-danger">
                          New password and Confirm password do not match
                        </span>
                      )}
                    </div>
                  </div>
                  <button
                    className="btn btn-block btn-sm primary-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      updatePassword();
                    }}
                  >
                    Confirm
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
