import React, { useEffect, useState } from "react";
import { Toast } from "../../../../../utils/toastutils";
import api from "../../../../../apiBaseConfig";
import { GET_AGENTS } from "../../../../../apiurl";

export default class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchObject: {},
      agentList: [],
    };
  }

  componentDidMount() {
    this.setAgentList();
  }

  setAgentList = async () => {
    try {
      let response = await api.get(GET_AGENTS);
      let agentData = response.data;
      let agentObj = {
        name: "",
        first_name: "",
        last_name: "",
      };
      let agentListArr = [];
      for (var i in agentData) {
        if (
          agentData[i].agent_details.role === "agent" ||
          agentData[i].agent_details.role === "agent_auditor"
        ) {
          let newObj = { ...agentObj };
          newObj.name =
            agentData[i].agent_details.first_name +
            " " +
            agentData[i].agent_details.last_name;
          newObj.first_name = agentData[i].agent_details.first_name;
          newObj.last_name = agentData[i].agent_details.last_name;
          agentListArr.push(newObj);
        }
      }
      this.setState({ agentList: agentListArr });
    } catch (error) {
      console.log(error);
    }
  };
  updateSearchObject = (tag, val) => {
    let searchObject = this.state.searchObject;
    if (tag === "agent_name") {
      this.updateSearchWithAgent(val);
    } else {
      searchObject[tag] = val;
      this.setState({ searchObject: searchObject });
    }
  };

  updateSearchWithAgent = (agentName) => {
    const { agentList } = this.state;
    let searchObject = this.state.searchObject;
    for (var i in agentList) {
      if (agentList[i].name === agentName) {
        searchObject["agent_fname"] = agentList[i].first_name;
        searchObject["agent_lname"] = agentList[i].last_name;
      }
    }
    this.setState({ searchObject: searchObject });
  };
  submitSearch = (e) => {
    e.preventDefault();
    const { searchObject } = this.state;
    let hasError = true;
    for (var i in searchObject) {
      if (searchObject[i]) {
        hasError = false;
      }
    }
    if (hasError) {
      Toast.error({
        title: "Please fill at least one search criteria.",
      });
    } else {
      this.props.getSearchObject(searchObject);
    }
  };

  resetSearch = (e) => {
    e.preventDefault();
    this.setState({ searchObject: {} });
  };
  render() {
    const { searchObject, agentList } = this.state;
    const { currentActiveTab, isAuditor } = this.props;
    const datePrefix =
      currentActiveTab === 0
        ? "Assigned"
        : currentActiveTab === 1
        ? "Scheduled"
        : "Completed";
    return (
      <div className="global-search-box-cntr" style={{ position: "relative", right: "0px" }}>
        <div className="global-search-box">
          <div>
            <div className="row my-3">
              <div className="col-2">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Case Name"
                  value={searchObject.case_name ? searchObject.case_name : ""}
                  onChange={(e) =>
                    this.updateSearchObject("case_name", e.target.value)
                  }
                />
              </div>
              <div className="col-2">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Applicant Name"
                  value={
                    searchObject.applicant_name
                      ? searchObject.applicant_name
                      : ""
                  }
                  onChange={(e) =>
                    this.updateSearchObject("applicant_name", e.target.value)
                  }
                />
              </div>
              <div className="col-2">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Applicant Mobile"
                  value={
                    searchObject.applicant_number
                      ? searchObject.applicant_number
                      : ""
                  }
                  onChange={(e) =>
                    this.updateSearchObject("applicant_number", e.target.value)
                  }
                />
              </div>
              <div className="col-4">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Applicant Email"
                  value={
                    searchObject.applicant_email
                      ? searchObject.applicant_email
                      : ""
                  }
                  onChange={(e) =>
                    this.updateSearchObject("applicant_email", e.target.value)
                  }
                />
              </div>
              <div className="col-2">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Meeting ID"
                  value={searchObject.meeting_id ? searchObject.meeting_id : ""}
                  onChange={(e) =>
                    this.updateSearchObject("meeting_id", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="row my-3">
              <div className="col-2">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="SS Applicant Number"
                  value={
                    searchObject.ss_applicant_number
                      ? searchObject.ss_applicant_number
                      : ""
                  }
                  onChange={(e) =>
                    this.updateSearchObject(
                      "ss_applicant_number",
                      e.target.value
                    )
                  }
                />
              </div>
              {isAuditor && (
                <div className="col-2">
                  <select
                    className="form-control form-control-sm"
                    onChange={(e) =>
                      this.updateSearchObject("agent_name", e.target.value)
                    }
                  >
                    <option value="">Agent Name</option>
                    {agentList.map((agent, key) => {
                      return (
                        <option key={key} value={agent.name}>
                          {agent.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
              {currentActiveTab === 2 && (
                <div className="col-2">
                  <select
                    className="form-control form-control-sm"
                    onChange={(e) =>
                      this.updateSearchObject("case_status", e.target.value)
                    }
                  >
                    <option value="">Case Status</option>
                    <option value="completed">Pending with Auditor</option>
                    <option value="audited">Approved</option>
                    <option value="rejected">Rejected</option>
                  </select>
                </div>
              )}
              {/* <div className="col-2">
                <select className="form-control form-control-sm">
                  <option>Select Process</option>
                </select>
              </div>
              <div className="col-2">
                <select className="form-control form-control-sm">
                  <option>Select Document</option>
                </select>
              </div> */}
              <div className="col-4">
                <div className="row scheduled-date">
                  <div className="col-4">
                    <label className="heading">
                      <small>{datePrefix} Date:</small>
                    </label>
                  </div>
                  <div className="col-4" style={{ marginLeft: "-25px" }}>
                    <input
                      type="date"
                      className="form-control form-control-sm"
                      placeholder="From:"
                      value={
                        searchObject.date_from ? searchObject.date_from : ""
                      }
                      onChange={(e) =>
                        this.updateSearchObject("date_from", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-4">
                    <input
                      type="date"
                      className="form-control form-control-sm"
                      placeholder="To:"
                      value={searchObject.date_to ? searchObject.date_to : ""}
                      onChange={(e) =>
                        this.updateSearchObject("date_to", e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col text-end">
                <button
                  className="btn btn-secondary btn-sm"
                  style={{ marginRight: "10px" }}
                  onClick={(e) => this.resetSearch(e)}
                >
                  Reset
                </button>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={(e) => this.submitSearch(e)}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
