import React from "react";
import RecordingIcon from "../../../../../assets/icons/recording-icon.png";
import DocumentIcon from "../../../../../assets/icons/document-icon.png";
import Moment from "react-moment";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Link } from "react-router-dom";
import API from '../../../../../apiBaseConfigNew'
import cogoToast from "cogo-toast";
import Loader from "../../../../../components/loader/Loader";
import { connect } from "react-redux"
class AuditorCompletedCases extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }
  handleVideoRecording = (index, id, type) => {
    this.setState({ loading: true })
    API.post('/case/regenerate_recording/', {
      case: id,
      recording_status: type
    })
      .then(res => {
        this.setState({ loading: false })
        if (res.data.detail) {
          cogoToast.success(res.data.detail)
        }
      }
      )
      .catch(err => {
        this.setState({ loading: false })
        cogoToast.error(err.response.data.error)
      })
  }
  render() {
    const { activeUpperTab, casesDataArray, searchKeyObject, clientInfo } = this.props;
    const { has_partners } = clientInfo
    // const route = activeUpperTab === 0 ? "report" : "pdreport";
    const route = "report"

    return (
      <div className="tab-pane caseContainer show active" id="completed">
        {this.state.loading ? <Loader /> : null}
        <div className="row mt-2">
          <div className="col-md-12">
            <table className="table sml-blue-header mb-0 text-center">
              <thead>
                <tr>
                  {!has_partners ? <th width="3%"></th> : null}
                  {/* <th width="5%">Photo</th> */}
                  <th width="8%">Meeting Id</th>
                  <th width="16%">
                    {activeUpperTab === 1 ? "Client name" : "Customer Name"}
                  </th>
                  {has_partners ? <th width="7%">Partner</th> : null}

                  <th width="9%">Process</th>
                  <th width="7%">Agent ID</th>
                  <th width="15%">Assigned Date</th>
                  <th width="15%%">Recording and Documents</th>
                  <th width="10%" style={{ textAlign: "center" }}>
                    Action
                  </th>
                  <th width="13%">Feedback</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
        {Object.keys(searchKeyObject).length !== 0 &&
          casesDataArray.length === 0 && (
            <div style={{ textAlign: "center", marginTop: "50px" }}>
              <h4>No case found</h4>
            </div>
          )}
        {casesDataArray.length > 0 &&
          casesDataArray.map((selectedCase, index) => (
            <div className="row" key={index}>
              <div className="col-md-12">
                <div className="tbl-row">
                  <table className="table mb-0 sml-blue-header text-center">
                    <tbody>
                      <tr>
                        {!has_partners ? <th width="3%"></th> : null}
                        {/* <td width="5%">
                          <span className="user-blue-circle rounded-circle">
                            {selectedCase.name
                              ? selectedCase.name.charAt(0)
                              : ""}
                          </span>
                        </td> */}
                        <td width="8%">{selectedCase.id}</td>
                        <td width="16%">{selectedCase.name}</td>
                        {has_partners ? <td width="7%">{selectedCase?.partner_name}</td> : null}

                        <td width="9%">
                          {selectedCase.process_details
                            ? selectedCase.process_details.name
                            : ""}
                        </td>
                        <td width="7%">
                          {selectedCase.agent ? selectedCase.agent : ""}
                        </td>
                        <td width="15%">
                          <span className="txt-light-blue font-italic">
                            <Moment format="DD MMM YYYY LT">
                              {selectedCase.completed_at}
                            </Moment>
                          </span>
                        </td>
                        <td width="15%" className="gray-circle-btns">
                          <span
                          // style={{display: activeUpperTab === 1 ?
                          //     'none' : ''
                          //     }}
                          >
                            <img
                              src={RecordingIcon}
                              onClick={() =>
                                this.props.handleWatchVideo(selectedCase.id)
                              }
                            />
                          </span>
                          <span
                          // style={{display: activeUpperTab === 1 ?
                          //           'none' : ''
                          //           }}
                          >
                            <img
                              src={DocumentIcon}
                              onClick={() =>
                                this.props.handleViewDocuments(selectedCase)
                              }
                            />
                          </span>
                          <span>
                            <Link
                              style={{ textDecoration: "none" }}
                              to={`/${route}/` + selectedCase.id}
                              params={{ testvalue: selectedCase.id }}
                              target="_blank"
                            >
                              <GetAppIcon src={DocumentIcon} />
                            </Link>
                          </span>
                        </td>
                        <td
                          width="10%"
                          align="center"
                          className={selectedCase.selected ? "show" : ""}
                        >
                          {selectedCase.approved ||
                            selectedCase.rejected ||
                            selectedCase.reassigned ? (
                            <span
                              style={{
                                color: selectedCase.approved ? "green" : "red",
                              }}
                            >
                              {selectedCase.approved
                                ? "Approved"
                                : selectedCase.rejected
                                  ? "Rejected"
                                  : "Reassigned"}
                            </span>
                          ) : (
                            <span
                              className={
                                !selectedCase.approved &&
                                  !selectedCase.rejected &&
                                  !selectedCase.reassigned
                                  ? "status none d-inline-flex align-items-center dropdown dropdown-toggle dropleft"
                                  : selectedCase.approved ||
                                    selectedCase.reassigned
                                    ? "status none d-inline-flex align-items-center dropdown dropdown-toggle dropleft complete "
                                    : "status none d-inline-flex align-items-center dropdown dropdown-toggle dropleft danger "
                              }
                              // data-toggle="dropdown"
                              onClick={() => this.props.handleOptions(index)}
                              disabled={true}
                            >
                              <span
                                className="default-text"
                                style={{ cursor: "pointer" }}
                              >
                                {!selectedCase.approved &&
                                  !selectedCase.rejected &&
                                  !selectedCase.reassigned
                                  ? "Select"
                                  : selectedCase.approved
                                    ? "Approved"
                                    : selectedCase.rejected
                                      ? "Rejected"
                                      : "Reassigned"}
                              </span>{" "}
                              <i className="cstm-arrow-down ms-2"></i>
                              <div
                                className={
                                  selectedCase.selected
                                    ? "dropdown-menu border-0 transfer-box fnt-sml show"
                                    : "dropdown-menu border-0 transfer-box fnt-sml"
                                }
                              >
                                {(activeUpperTab === 1 ||
                                  (selectedCase.connections.length &&
                                    !selectedCase.connections[0].facematch_threshold_failed)) && (
                                    <a
                                      className="dropdown-item"
                                      data-val="Approved"
                                      onClick={() =>
                                        this.props.handleApprove(
                                          index,
                                          selectedCase.id
                                        )
                                      }
                                    >
                                      <span>Approve</span>
                                    </a>
                                  )}
                                <a
                                  className="dropdown-item"
                                  data-val="Rejected"
                                  onClick={() =>
                                    this.props.handleCommentBeforeAction(
                                      index,
                                      selectedCase.id,
                                      "rejected"
                                    )
                                  }
                                >
                                  <span>Reject</span>
                                </a>
                                {clientInfo?.client_details?.name !== 'CoinDCX' ? <a
                                  className="dropdown-item"
                                  data-val="Reassigned"
                                  onClick={() =>
                                    this.props.handleCommentBeforeAction(
                                      index,
                                      selectedCase.id,
                                      "reassigned"
                                    )
                                  }
                                >
                                  <span>Reassign</span>
                                </a> : null}
                                <a
                                  className="dropdown-item"
                                  data-val="Reassigned"
                                  onClick={() =>
                                    this.handleVideoRecording(
                                      index,
                                      selectedCase.id,
                                      "incomplete"
                                    )
                                  }
                                >
                                  <span>Video Incomplete</span>
                                </a>
                                <a
                                  className="dropdown-item"
                                  data-val="Reassigned"
                                  onClick={() =>
                                    this.handleVideoRecording(
                                      index,
                                      selectedCase.id,
                                      "corrupted"
                                    )
                                  }
                                >
                                  <span>Regenrate Video</span>
                                </a>
                              </div>
                            </span>
                          )}
                        </td>
                        <td width="13%" className="text-break">
                          <div className="font-italic">
                            {selectedCase.case_history.filter(
                              (history) => history.type === "comment"
                            ).length !== 0
                              ? selectedCase.case_history.filter(
                                (history) => history.type === "comment"
                              )[
                                selectedCase.case_history.filter(
                                  (history) => history.type === "comment"
                                ).length - 1
                              ].re_text
                              : ""}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  clientInfo: state.login.clientInfo
});

export default connect(mapStateToProps)(AuditorCompletedCases);
